edsApp.classes.controllers.AppController = class
{
    /* This class is a superclass. There is no inheritance. */

    /* Do setup specific to this class. */
	constructor(containerView) {
		//Declare instance variables.
	    this._containerView = containerView;
	    this._keyController = null;
	    var appController = this;
	
	    //Hookup view events to their respective "listeners" or "targets".
	
	    /* Here we listen for keydown events and notify the key controller if necessary */
	    $('html').on("keydown", function(e) {
	
	        if (appController._keyController)
	            appController._keyController.didPressKey(e.which);
	    });
	
	    /* Here we listen for popstate events, which occur whenever the user preses the back button. We reinstate the
	     controller for the desired page, or return to the home controller if the controller is invalid. If the history
	     API is not supported, this event is never fired; instead, the whole page is reloaded again.
	     */
	    $(window).on("popstate", function () {
	
	        var allURLParameters = edsApp.utilities.getAllURLParameters();
	
	        var destinationController = allURLParameters["controller"];
	
	        //See if the controller passed actually exists. If not, redirect them to home.
	        if (!_.has(edsApp.controllers, destinationController)) {
	            var error = new Error();
	            error.name = edsApp.model.errors.cannotBecomeKeyController;
	            error.message = "Controller '" + destinationController + "' cannot become key in AppController–it does not exist!";
	            throw error;
	        }

	        //If it's the same controller, then we don't re-push the controller. This allows for element id navigation (#go_here)
            if (appController._keyController && appController._keyController.name == destinationController)
                return;
	
	        //Push the controller, with the arguments specified in the query string (minus the controller argument).
	        if (_.has(allURLParameters, "controller"))
	            delete allURLParameters["controller"];
	
	        appController.pushController(edsApp.controllers[destinationController], false, allURLParameters);
	    });
		
		/* Here we listen for click events on data-eds-link elements. We prevent the browser from following the link
	    if we find a suitable controller to push in the link */
		$('body').on("click", "a[data-eds-link]", function (e) {
			
			var allURLParameters = edsApp.utilities.getAllURLParameters($(this).attr("href"));
			
		    //See if the controller passed actually exists.
		    if (_.has(allURLParameters, "controller") && _.has(edsApp.controllers, allURLParameters["controller"])) {
				e.preventDefault();
		        var destinationController = edsApp.controllers[allURLParameters["controller"]];
				delete allURLParameters["controller"];
				appController.pushController(destinationController, true, allURLParameters);
		    }
		});
	}

    //PUBLIC METHODS.

    /*This method "pushes", or makes newController the key controller. It starts by asking newController whether it can
     become the key controller by calling canBecomeKeyController() (redirects to error page if false). If so, it will
     invoke willResignKeyController() on the key controller (if any). Next, it will
     then invoke willBecomeKeyController() on newController, passing it argumentsForNewController so that newController
     can decide what data to display. After calling willBecomeKeyController(), this method will then load
     newController's view in the application's container and detach the view of the previous controller that was key.
     It will also change the browser's window title to the localized name of the controller. recordHistory is a boolean
     that tells this method whether or not this "push" should be recorded in the browser's history using the HTML5
     history API. If the history API is not supported, this method will either redirect or open a new URL with the new
     controller depending on the value of recordHistory.
     */
     pushController(newController, recordHistory, argumentsForNewController={}) {

        //If the controller doesn't have a view (probably hasn't downloaded yet), don't bother continuing.
        if (!newController.getView())
            return;
			
	    var appController = this;

        //If the new controller cannot become the key controller with the given arguments, we redirect to the error page.
        if (!newController.canBecomeKeyController(argumentsForNewController)) {
            var error = new Error();
            error.name = edsApp.model.errors.cannotBecomeKeyController;
            error.message = "Controller '" + newController.name + "' cannot become key in AppController. Arguments given: " + JSON.stringify(argumentsForNewController);
            throw error;
        }

        if (window.history && window.history.pushState) {

            edsApp.utilities.fadeTransition(appController._containerView, function () {

                if (appController._keyController) {
                    appController._keyController.getView().detach();
                    appController._keyController.willResignKeyController();
                }
                else {
                    //We need to remove the welcome screen from the DOM.
                    appController._containerView.empty();
                }

                var url = edsApp.utilities.createRelativeURLForController(newController.name, argumentsForNewController);

                if (recordHistory)
                    window.history.pushState({}, appController.getTitleForController(newController), url);
                else
                    window.history.replaceState({}, appController.getTitleForController(newController), url);

                newController.willBecomeKeyController(argumentsForNewController);
                newController.getView().appendTo(appController._containerView);
                window.scrollTo(0, 0);
                document.title = appController.getTitleForController(newController);
                appController._keyController = newController;
            });

        } else {

            if (appController._keyController) {
                appController._keyController.getView().detach();
                appController._keyController.willResignKeyController();

                var url = "?controller=" + newController.name;

                if (!_.isEmpty(argumentsForNewController)) {
                    url = url + "&" + $.param(argumentsForNewController);
                }

                if (recordHistory)
                    window.location.href = url;
                else
                    window.location.replace(url);
            }
            else {
                //We need to remove the welcome screen from the DOM.
                appController._containerView.empty();

                newController.willBecomeKeyController(argumentsForNewController);
                newController.getView().appendTo(appController._containerView);
                window.scrollTo(0, 0);
                document.title = appController.getTitleForController(newController);
                appController._keyController = newController;
            }
        }
    }

    /* This method is called on subclasses if implemented. It should return an appropriate string representing the title that should be displayed on the browser's title bar. */
    getTitleForController(controller) {

        return controller.name;
    };

    /* This method returns a bool stating whether the controller passed is currently the key controller. Note that this method will return false if the controller in question is nested
     * inside another ContainerViewController that is key. */
    isKeyController(controller) {
        return this._keyController && this._keyController.name == controller.name;
    }
 };
