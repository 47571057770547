edsApp.classes.controllers.custom.NewUserCreateController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "new_user_create";
        this.performingRequest = false;
        this.totp = null;
        this.otpTimestamp = null;
        this.textCodeTimestamp = null;
        this.phoneCodeTimestamp = null;
        this.qrcode = null;
        this.providerToken = null;
        this.providerId = null;
        this.providerName = null;
        this.redirectControllerName = null;
        this.redirectControllerArgs = null;
        this.modalView = null;
        this.helpLink = "create_account";
        this.magicLinkToken = null;
        this.iti = null;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        var newUserCreateController = this;

        //Add buttons for all foreign providers.
        _.each(edsApp.model.custom.foreignProviderNames.slice().reverse(), function(providerName) {

            // 2021-10-19: facebook isn't working currently
            if (providerName === 'facebook') {
                return;
            }

            var linkAccountButtonDomObject = $('<div class="col-sm-6 col-sm-offset-3 providerSignup" data-mca-provider="' + providerName + '">' +
                            '<a class="' + edsApp.utilities.custom.getButtonStyleForProvider2(providerName) + '" role="button" style="width: 100%;">' +
                            edsApp.utilities.custom.getSvgIconForProvider(providerName) +
                            _.escape(edsApp.model.getLocalizedString("continue_with") + " " + edsApp.model.getLocalizedString(providerName)) + '</a>' +
                            // _.escape(edsApp.model.getLocalizedString(providerName)) + '</a>' +
                        '</div>');

            newUserCreateController._view.getChildView("button_container").prepend(linkAccountButtonDomObject);
        });

        //Hookup view events to their respective "listeners" or "targets".
        this._view.getChildView("button_container").find("[data-mca-provider]").on("click", function (e) {
            e.preventDefault();

            edsApp.utilities.custom.loginWithForeignProvider($(this).attr("data-mca-provider"), undefined, undefined, function (success, providerName, providerToken, providerId, providerEmail, userName, userImageURL) {

                if (success) {

                    newUserCreateController.performingRequest = true;
                    newUserCreateController._view.getChildView("create_button").hide();
                    newUserCreateController._view.getChildView("spinner").show();

                    //Create a new user.
                    var newUserArgs = {
                        email: providerEmail,
                        override_email_verification: true,
                        name: userName,
                        password: undefined,
                        phone: "",
                        languageId: newUserCreateController._view.getChildView("language").val(),
                        countryId: newUserCreateController._view.getChildView("country").val(),
                        providerToken: providerToken,
                        providerId: providerId,
                        providerName: providerName,
                        twoFactorPolicy: "off"
                    };

                    edsApp.model.postDataToURL("user", newUserArgs, function (success, data) {
                        if (success) {
                            //See if the user already exists.
                            if (data.user) {
                                // either this is a new account or there's
                                // already an account that uses this foreign
                                // account; either way we're now logged in!
                                edsApp.utilities.custom.processSuccessfulLogin(
                                    true,
                                    newUserArgs.email,
                                    newUserArgs.providerName,
                                    data,
                                    newUserCreateController.redirectControllerName,
                                    false,
                                    JSON.parse(newUserCreateController.redirectControllerArgs)
                                );
                            } else {
                                // account exists but not using the foreign provider that was just used to sign up
                                // TODO: not DRY
                                //Redirect them to the login controller.
                                var loginCtrlerArgs = {
                                    redirectControllerName: newUserCreateController.redirectControllerName,
                                    redirectControllerArgs: newUserCreateController.redirectControllerArgs,
                                    forgivenessToken: data.guidance.forgiveness_token
                                };

                                var hasForeignAccounts = data.guidance.foreign_account_provider_info.length > 0;
                                var providerNames = _.map(data.guidance.foreign_account_provider_info, function (x) {
                                    return edsApp.model.getLocalizedString(x.name);
                                });
                                var localizedGuidance;

                                if (hasForeignAccounts && data.guidance.has_password) {
                                    localizedGuidance = edsApp.model.getLocalizedString("existing_account_guidance_use_password_foreign_account", {
                                        invalid_provider: edsApp.model.getLocalizedString(newUserArgs.providerName),
                                        valid_provider: providerNames.join("/")
                                    });
                                } else if (hasForeignAccounts) {
                                    localizedGuidance = edsApp.model.getLocalizedString("existing_account_guidance_use_foreign_account", {
                                        invalid_provider: edsApp.model.getLocalizedString(newUserArgs.providerName),
                                        valid_provider: providerNames.join("/")
                                    });
                                } else {
                                    localizedGuidance = edsApp.model.getLocalizedString("existing_account");
                                }

                                // configure login controller to show user the right way to log in
                                // TODO: what about relying on the login controller to do this?

                                loginCtrlerArgs.providerNames = [];

                                _.each(data.guidance.foreign_account_provider_info, function (info) {

                                    if (_.contains(edsApp.model.custom.foreignProviderNames, info.name))
                                        loginCtrlerArgs.providerNames.push(info.name);
                                });

                                if (loginCtrlerArgs.providerNames.length < 1) {
                                    loginCtrlerArgs.email = newUserArgs.email;
                                }

                                loginCtrlerArgs.infoMessage = localizedGuidance;

                                edsApp.controllers.app.pushController(edsApp.controllers.login, false, loginCtrlerArgs);
                            }
                        } else {
                            newUserCreateController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                            newUserCreateController._view.getChildView("alert").slideDown();
                        }
    
                        newUserCreateController._view.getChildView("create_button").show();
                        newUserCreateController._view.getChildView("spinner").hide();
                        newUserCreateController.performingRequest = false;
                    });
                }
            });
        });

        this._view.getChildView("phone").popover();
        this._view.getChildView("two_factor_popover").popover();
        this._view.getChildView("linked_account_popover").popover();
        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        this.modalView = new edsApp.classes.views.ModalView(this._view);
        this.modalView.setTitle(edsApp.model.getLocalizedString("invalid_email"));
        this.modalView.hideProgressBar(false);

        //Load all languages and countries to the select form control
        var selectDomObject = this._view.getChildView("language");
        edsApp.utilities.appendSortedLanguageIdsToSelectDomObject(selectDomObject);
        var countrySelectDomObject = this._view.getChildView("country");
        edsApp.utilities.appendSortedCountryIdsToSelectDomObject(countrySelectDomObject);

        //Hookup view events to their respective "listeners" or "targets".
        // var newUserCreateController = this;

        this._view.getChildView("password").on("blur focus", function (e) {
            newUserCreateController._view.getChildView("progress_bar_container").slideToggle();
        });

        this._view.getChildView("password").on("change keyup paste focus", function (e) {

            var strength = edsApp.utilities.custom.passwordStrength($(this).val());
            var progressBarDomObject = newUserCreateController._view.getChildView("progress_bar");
            var progressBarTextDomObject = newUserCreateController._view.getChildView("progress_bar_text");

            progressBarDomObject.attr("aria-valuenow", strength);
            progressBarDomObject.css("width", strength + "%");
            progressBarDomObject.removeClass("progress-bar-danger").removeClass("progress-bar-warning").removeClass("progress-bar-success");

            if (strength < 25) {
                progressBarDomObject.addClass("progress-bar-danger");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("weak"));
            } else if (strength < 50) {
                progressBarDomObject.addClass("progress-bar-warning");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("fair"));
            } else {
                progressBarDomObject.addClass("progress-bar-success");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("strong"));
            }
        });

        this._view.getChildView("unmask").on("click", function (e) {
            e.preventDefault();
            // if we need to support IE, see https://gist.github.com/bminer/3559343
            var pass = newUserCreateController._view.getChildView("password");
            if (pass.prop("type") === "password") {
                pass.prop("type", "text");
                newUserCreateController._view.getChildView("unmask").html('<i class="glyphicon glyphicon-eye-close form-control-feedback" style="pointer-events: auto !important;"></i>');
            } else {
                pass.prop("type", "password");
                newUserCreateController._view.getChildView("unmask").html('<i class="glyphicon glyphicon-eye-open form-control-feedback" style="pointer-events: auto !important;"></i>');
            }
        });

        // TODO: remove properly (WWW-1608)
        // this._view.getChildView("email").on("change", function (e) {

        //     newUserCreateController.updateQRCodeUI($(this).val());
        //     newUserCreateController.styleCheckboxes();
        //     newUserCreateController.checkCheckboxes();
        // });

        this._view.getChildView("add_phone_number").on("click", function (e) {

            e.preventDefault();
            newUserCreateController._view.getChildView("phone").focus();
        });

        // TODO: remove properly (WWW-1608)
        // this._view.getChildView("phone").on("change keyup paste focus", function (e) {

        //     newUserCreateController.styleCheckboxes();
        //     newUserCreateController.checkCheckboxes();
        // });

        this._view.getChildView("two_factor_policy").on("click", "input", function (e) {

            //Show or hide the two factor modes accordingly.
            var $clickedRadio = $(this);

            if ($clickedRadio.attr("data-mca-mode") == "off") {
                newUserCreateController._view.getChildView("two_factor_methods").slideUp();

                newUserCreateController._view.getChildView("two_factor_methods").find("input:checkbox").prop("checked", false);
                newUserCreateController.showOrHideTOTPList(false);
                newUserCreateController.showOrHideCodeContainers(false);
            } else {
                newUserCreateController.checkCheckboxes();
                newUserCreateController._view.getChildView("two_factor_methods").slideDown();
            }
        });

        this._view.getChildView("two_factor_methods").on("click", "input:checkbox", function (e) {

            //Keep at least one checkbox checked at all times.
            newUserCreateController.checkCheckboxes();
        });

        this._view.getChildView("two_factor_checkbox").on("change", function (e) {
            newUserCreateController._view.getChildView("two_factor_container").slideToggle();

            if (!$(this).is(":checked")) {

                newUserCreateController._view.getChildView("two_factor_methods").find("input:checkbox").prop("checked", false);
                newUserCreateController.showOrHideTOTPList(false);
                newUserCreateController.showOrHideCodeContainers(false);
            } else {
                newUserCreateController.checkCheckboxes();
            }
        });

        this._view.getChildView("totp_code").on("keyup", function (e) {

            //Update the otp timestamp.
            newUserCreateController.otpTimestamp = new Date().getTime() / 1000.0;
        });

        this._view.getChildView("text_code").on("keyup", function (e) {

            //Update the text code timestamp.
            newUserCreateController.textCodeTimestamp = new Date().getTime() / 1000.0;
        });

        this._view.getChildView("phone_code").on("keyup", function (e) {

            //Update the phone code timestamp.
            newUserCreateController.phoneCodeTimestamp = new Date().getTime() / 1000.0;
        });

        this._view.getChildView("form").on("submit", function (e) {


            var createUser = function createUser(overrideInvalidEmail, email) {

                if (!newUserCreateController.formValidator.getFormStatus() || newUserCreateController.performingRequest)
                    return;

                newUserCreateController.performingRequest = true;
                newUserCreateController._view.getChildView("create_button").hide();
                newUserCreateController._view.getChildView("spinner").show();

                //Create a new user.
                var newUserArgs = {
                    email: email,
                    override_email_verification: overrideInvalidEmail,
                    name: newUserCreateController._view.getChildView("name").val(),
                    password: newUserCreateController._view.getChildView("password").val() ? newUserCreateController._view.getChildView("password").val() : undefined,
                    phone: newUserCreateController.iti.getNumber(), // e.164
                    phoneCountryId: newUserCreateController.iti.getSelectedCountryData().iso2,
                    languageId: newUserCreateController._view.getChildView("language").val(),
                    countryId: newUserCreateController._view.getChildView("country").val(),
                    providerToken: null,
                    providerId: null,
                    providerName: null,
                    twoFactorPolicy: "off",
                    magicLinkToken: newUserCreateController.magicLinkToken,
                    gmt_offset_in_seconds: new Date().getTimezoneOffset() * (-60),
                    tz: Intl.DateTimeFormat().resolvedOptions().timeZone // ie11: undefined
                };

                if (newUserArgs.twoFactorPolicy != "off") {

                    newUserArgs.twoFactorByEmail = newUserCreateController._view.getChildView("two_factor_by_email").is(":checked");

                    if (newUserCreateController._view.getChildView("two_factor_by_text").is(":checked")) {
                        newUserArgs.textCode = edsApp.utilities.custom.sanitizeTotpCode(newUserCreateController._view.getChildView("text_code").val());
                        newUserArgs.textCodeTimestamp = newUserCreateController.textCodeTimestamp;
                    }

                    if (newUserCreateController._view.getChildView("two_factor_by_phone").is(":checked")) {
                        newUserArgs.phoneCode = edsApp.utilities.custom.sanitizeTotpCode(newUserCreateController._view.getChildView("phone_code").val());
                        newUserArgs.phoneCodeTimestamp = newUserCreateController.phoneCodeTimestamp;
                    }

                    if (newUserCreateController._view.getChildView("two_factor_by_totp").is(":checked")) {
                        newUserArgs.otpSecret = newUserCreateController.totp.secret;
                        newUserArgs.otpCode = edsApp.utilities.custom.sanitizeTotpCode(newUserCreateController._view.getChildView("totp_code").val());
                        newUserArgs.otpTimestamp = newUserCreateController.otpTimestamp;
                    } else {
                        newUserArgs.otpSecret = null;
                    }
                }

                edsApp.model.fetchScriptAsync("https://www.recaptcha.net/recaptcha/enterprise.js", {render: edsApp.model.custom.recaptchaSiteKey}).then(() =>
                {
                    grecaptcha.enterprise.ready(function() {
                        grecaptcha.enterprise.execute(edsApp.model.custom.recaptchaSiteKey, {action: 'signup'}).then(function(token) {
                            newUserArgs["recaptchaToken"] = token;
                            edsApp.model.postDataToURL("user", newUserArgs, function (success, data) {
    
                                if (success) {
    
                                    //See if the user already exists.
                                    if (!data.user) {
    
                                        if (data.guidance.email_verification_results) {
                                            //Show the modal view with the appropriate suggestions.
                                            newUserCreateController.modalView.removeAllButtons();
                                            newUserCreateController.modalView.removeCustomView();
    
                                            newUserCreateController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("edit_email"), ["btn-danger"], false, function(e) {
    
                                                e.preventDefault();
                                                newUserCreateController.modalView.hide();
                                                newUserCreateController._view.getChildView("email").focus();
                                            });
    
                                            var results = data.guidance.email_verification_results;
                                            var message;
    
                                            if (results.did_you_mean) {
                                                message = "invalid_email_did_you_mean";
    
                                                newUserCreateController.modalView.setAlternateButton(edsApp.model.getLocalizedString("use_email", {email: results.did_you_mean}), ["btn-default"], false, function(e) {
    
                                                    e.preventDefault();
                                                    newUserCreateController.modalView.hide();
                                                    createUser(false, results.did_you_mean);
                                                });
    
                                            } else if (!results.smtp_check) {
                                                message = "invalid_email_smtp";
                                            } else if (results.role) {
                                                message = "invalid_email_role";
                                            }
    
                                            var $modalCustomView = $("<div class='edsAppHorizontalSpaced'></div>");
                                            $modalCustomView.append($("<p></p>").text(edsApp.model.getLocalizedString(message, {email: newUserArgs.email, suggestion: results.did_you_mean})));
    
                                            newUserCreateController.modalView.setAlternateButton(edsApp.model.getLocalizedString("invalid_email_continue", {email:newUserArgs.email}), ["btn-default"], false, function(e) {
                                                e.preventDefault();
                                                newUserCreateController.modalView.hide();
                                                createUser(true, newUserArgs.email);
                                            });
    
                                            newUserCreateController.modalView.setCustomView($modalCustomView);
                                            newUserCreateController.modalView.show();
    
                                        } else if (data.guidance.magic_link_token) {
                                            newUserCreateController._view.getChildView("heading").text(edsApp.model.getLocalizedString("magic_link_check_email"));
                                            newUserCreateController.magicLinkToken = data.guidance.magic_link_token
                                            newUserCreateController._view.getChildView("magic_link_message").html(edsApp.model.getLocalizedString("magic_link_message", {email: "<strong>" + newUserArgs.email + "</strong>"}));
                                            
                                            // replace form with a spinner while we wait
                                            newUserCreateController._view.getChildView("button_container").hide();
                                            newUserCreateController._view.getChildView("separator_container").hide();
                                            newUserCreateController._view.getChildView("form").hide();
                                            newUserCreateController._view.getChildView("magic_link_container").show();
    
                                            // poll
                                            var checkMagicLink = function checkMagicLinkRecursiveFn() {
                                                edsApp.model.getJSONDataForURL("magic-link", {token: newUserCreateController.magicLinkToken}, 0, function(success, data) {
                                                    if (success && data === true) {
                                                        createUser(true, newUserArgs.email);
                                                    } else if (!newUserCreateController.magicLinkToken) {
                                                        // do nothing - user has probably navigated away
                                                    } else if (!success && !_.isUndefined(data) && data.error === "error_invalid_magic_link") {
                                                        // show warning and go back to the start
                                                        newUserCreateController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data))
                                                        newUserCreateController.magicLinkToken = null;
                                                    } else {
                                                        edsApp.utilities.invokeFunctionWithDelay(5000, checkMagicLink);
                                                    }
                                                });
                                            }
                                            edsApp.utilities.invokeFunctionWithDelay(5000, checkMagicLink);
    
                                        } else {
                                            //Redirect them to the login controller.
                                            var loginCtrlerArgs = {
                                                redirectControllerName: newUserCreateController.redirectControllerName,
                                                redirectControllerArgs: newUserCreateController.redirectControllerArgs,
                                                forgivenessToken: data.guidance.forgiveness_token
                                            };
    
                                            var hasForeignAccounts = data.guidance.foreign_account_provider_info.length > 0;
                                            var providerNames = _.map(data.guidance.foreign_account_provider_info, function (x) {
                                                return edsApp.model.getLocalizedString(x.name);
                                            });
                                            var localizedGuidance;
    
                                            if (hasForeignAccounts && data.guidance.has_password) {
                                                localizedGuidance = edsApp.model.getLocalizedString("existing_account_guidance_use_password_foreign_account", {
                                                    invalid_provider: edsApp.model.getLocalizedString(newUserArgs.providerName),
                                                    valid_provider: providerNames.join("/")
                                                });
                                            } else if (hasForeignAccounts) {
                                                localizedGuidance = edsApp.model.getLocalizedString("existing_account_guidance_use_foreign_account", {
                                                    invalid_provider: edsApp.model.getLocalizedString(newUserArgs.providerName),
                                                    valid_provider: providerNames.join("/")
                                                });
                                            } else if (data.guidance.has_password) {
                                                localizedGuidance = edsApp.model.getLocalizedString("existing_account_guidance_use_password", {invalid_provider: edsApp.model.getLocalizedString(newUserArgs.providerName)});
                                            }
    
                                            if (data.guidance.has_password || _.find(data.guidance.foreign_account_provider_info, function (x) {
                                                return !_.contains(edsApp.model.custom.foreignProviderNames, x.name);
                                            })) {
                                                loginCtrlerArgs.email = newUserArgs.email;
                                            }
    
                                            loginCtrlerArgs.providerNames = [];
    
                                            _.each(data.guidance.foreign_account_provider_info, function (info) {
    
                                                if (_.contains(edsApp.model.custom.foreignProviderNames, info.name))
                                                    loginCtrlerArgs.providerNames.push(info.name);
                                            });
    
                                            loginCtrlerArgs.infoMessage = localizedGuidance;
    
                                            edsApp.controllers.app.pushController(edsApp.controllers.login, false, loginCtrlerArgs);
                                        }
    
    
                                    } else {
                                        //Great, the user was created. We should be logged in.
                                        edsApp.utilities.custom.processSuccessfulLogin(
                                            true,
                                            newUserArgs.email,
                                            newUserArgs.providerName,
                                            data,
                                            newUserCreateController.redirectControllerName,
                                            false,
                                            JSON.parse(newUserCreateController.redirectControllerArgs)
                                        );
                                    }
    
                                } else {
    
                                    newUserCreateController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                                    newUserCreateController._view.getChildView("alert").slideDown();
                                }
    
                                newUserCreateController._view.getChildView("create_button").show();
                                newUserCreateController._view.getChildView("spinner").hide();
                                newUserCreateController.performingRequest = false;
                            });
                        });
                    });
                });
            };

            createUser(newUserCreateController.passwordless, newUserCreateController._view.getChildView("email").val());
        });
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        //This is used for redirecting the user to the original link upon account creation.
        this.redirectControllerName = a.redirectControllerName || edsApp.controllers.home.name;
        this.redirectControllerArgs = a.redirectControllerArgs || JSON.stringify({});

        // reset after magic link
        this._view.getChildView("heading").text(edsApp.model.getLocalizedString("new_user"));
        this._view.getChildView("button_container").show();
        this._view.getChildView("separator_container").show();
        this._view.getChildView("form").show();

        this._view.getChildView("alert").hide();
        this._view.getChildView("info_alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("create_button").show();
        
        this._view.getChildView("magic_link_container").hide();
        this._view.getChildView("locale_container").hide();

        this._view.getChildView("two_factor_checkbox_container").hide();
        this._view.getChildView("two_factor_container").hide();
        this._view.getChildView("two_factor_checkbox").prop("checked", false);
        this._view.getChildView("totp_code").attr("data-eds-validator", "true");
        this._view.getChildView("off").prop("checked", true);
        this._view.getChildView("two_factor_methods").hide();
        this.styleCheckboxes();

        //Set the default language and country.
        this._view.getChildView("language").find("option").prop("selected", false);
        this._view.getChildView("country").find("option").prop("selected", false);
        edsApp.utilities.appendLanguageIdToSelectDomObject(this._view.getChildView("language"), edsApp.model.custom.languageId);
        this._view.getChildView("country").find("option[value='" + edsApp.model.custom.countryId + "']").prop("selected", true);

        //Set default values if specified.
        if (a.name)
            this._view.getChildView("name").val(a.name);
        else if (a.email)
            this._view.getChildView("name").val(a.email.split("@")[0]);

        if (a.email) {
            this._view.getChildView("email").val(a.email);

            //Update the qr code based on the email.
            this.updateQRCodeUI(a.email);
        }

        // if (a.phone)
        //     this._view.getChildView("phone").val(a.phone);

        var tel = this._view.getChildView("phone").get(0);
        this.iti = window.intlTelInput(tel, {
            utilsScript: "/js/iti-utils.js",
            initialCountry: this.getValidCountryIdForTelInput(edsApp.model.custom.countryId)
        });

        // reset provider info
        // TODO: clean up
        this.providerToken = null;
        this.providerName = null;
        this.providerId = null;

        // passwordless signup is now the default
        this.passwordless = !a.password;
        if (this.passwordless) {
            this._view.getChildView("password_container").hide();
            this._view.getChildView("password").attr("data-eds-validator", "true");

            // tell the home controller that this is a new user so we can show a success message and
            // let them know how to set a password
            if (this.redirectControllerName == edsApp.controllers.home.name) {
                var args = JSON.parse(this.redirectControllerArgs);
                args["new_user"] = true;
                this.redirectControllerArgs = JSON.stringify(args);
            }
        } else {
            this._view.getChildView("password_container").show();
            this._view.getChildView("password").attr("data-eds-validator", "edsApp.utilities.custom.passwordStrength($('#new_user_create_password').val()) > 20");
        }

        //Reset the form.
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("progress_bar_container").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this.formValidator.reloadFormStatus();
        this._view.getChildView("create_button").prop("disabled", !this.formValidator.getFormStatus());
    };

    willResignKeyController() {

        //For security reasons, we clear these fields.
        this._view.getChildView("password").val("");
        this._view.getChildView("confirm_password").val("");
        this.magicLinkToken = null;
        this.iti.destroy();
        this.iti = null;

        //Call the superclass.
        super.willResignKeyController();
    };

    getValidCountryIdForTelInput(countryId) {
        var countries = window.intlTelInputGlobals.getCountryData().map(x => x.iso2);
        return countries.includes(countryId) ? countryId : "us";
    }

    updateQRCodeUI(email) {

        var newUserCreateController = this;

        //Load the QR Code library.
        edsApp.model.getScriptForURL("/js/min/qrcode.min.js", null, function (success) {

            if (success) {

                //Request a TOTP.
                edsApp.model.postDataToURL("totp", {"email": email}, function (success, data) {

                    if (success) {
                        newUserCreateController.totp = data;

                        //Render the TOTP as a QR code.
                        if (newUserCreateController.qrcode) {
                            newUserCreateController.qrcode.clear();
                            newUserCreateController.qrcode.makeCode(data.uri);
                        } else {
                            newUserCreateController.qrcode = new QRCode("new_user_create_qr_code", {
                                text: data.uri,
                                width: 192,
                                height: 192
                            });
                        }

                        //Update the base32 encoded secret for manual entry by adding spaces every 4 chars.
                        var b32secret = "";

                        for (var i = 0, len = data.b32secret.length; i < len; i++) {

                            if (i > 0 && i % 4 == 0)
                                b32secret += " ";

                            b32secret += data.b32secret[i];
                        }

                        newUserCreateController._view.getChildView("totp_secret").text(b32secret);

                        //Clear the currently typed code, if any.
                        newUserCreateController._view.getChildView("totp_code").val("");
                    }
                });
            }
        });
    }

    showOrHideCodeContainers(animated) {
        if (this._view.getChildView("two_factor_by_text").is(":checked")) {

            if (this._view.getChildView("text_code_container").is(":hidden")) {
                //Send a code.
                this.sendTextCode();
            }

            if (animated)
                this._view.getChildView("text_code_container").slideDown();
            else
                this._view.getChildView("text_code_container").show();

            this._view.getChildView("text_code").attr("data-eds-validator", "!isNaN(edsApp.utilities.custom.sanitizeTotpCode($('#new_user_create_text_code').val()))");
        } else {

            if (animated)
                this._view.getChildView("text_code_container").slideUp();
            else
                this._view.getChildView("text_code_container").hide();

            this._view.getChildView("text_code").attr("data-eds-validator", "true");
        }

        if (this._view.getChildView("two_factor_by_phone").is(":checked")) {

            if (this._view.getChildView("phone_code_container").is(":hidden")) {
                //Send a code.
                this.sendPhoneCode();
            }

            if (animated)
                this._view.getChildView("phone_code_container").slideDown();
            else
                this._view.getChildView("phone_code_container").show();

            this._view.getChildView("phone_code").attr("data-eds-validator", "!isNaN(edsApp.utilities.custom.sanitizeTotpCode($('#new_user_create_phone_code').val()))");
        } else {

            if (animated)
                this._view.getChildView("phone_code_container").slideUp();
            else
                this._view.getChildView("phone_code_container").hide();

            this._view.getChildView("phone_code").attr("data-eds-validator", "true");
        }

        this.formValidator.reloadFormStatus();
        this._view.getChildView("create_button").prop("disabled", !this.formValidator.getFormStatus());
    }

    showOrHideTOTPList(animated) {
        if (this._view.getChildView("two_factor_by_totp").is(":checked")) {

            if (animated)
                this._view.getChildView("totp_list").slideDown();
            else
                this._view.getChildView("totp_list").show();

            this._view.getChildView("totp_code").attr("data-eds-validator", "!isNaN(edsApp.utilities.custom.sanitizeTotpCode($('#new_user_create_totp_code').val()))");
        } else {

            if (animated)
                this._view.getChildView("totp_list").slideUp();
            else
                this._view.getChildView("totp_list").hide();

            this._view.getChildView("totp_code").attr("data-eds-validator", "true");
        }

        this.formValidator.reloadFormStatus();
        this._view.getChildView("create_button").prop("disabled", !this.formValidator.getFormStatus());
    }

    styleCheckboxes() {

        var email = this._view.getChildView("email").val();
        var phoneNumber = this._view.getChildView("phone").val();

        //Enable, disable two factor method checkboxes as needed.
        var emailContainer = this._view.getChildView("two_factor_by_email_container");
        var textContainer = this._view.getChildView("two_factor_by_text_container");
        var phoneContainer = this._view.getChildView("two_factor_by_phone_container");
        var emailCheckbox = emailContainer.getChildView("two_factor_by_email");
        var emailCheckboxText = emailContainer.getChildView("two_factor_by_email_text");
        var textCheckbox = textContainer.getChildView("two_factor_by_text");
        var textCheckboxText = textContainer.getChildView("two_factor_by_text_text");
        var phoneCheckbox = phoneContainer.getChildView("two_factor_by_phone");
        var phoneCheckboxText = phoneContainer.getChildView("two_factor_by_phone_text");

        //Remove all disabled classes for starters.
        emailContainer.removeClass("edsAppLightGrayColor").removeClass("disabled");
        textContainer.removeClass("edsAppLightGrayColor").removeClass("disabled");
        phoneContainer.removeClass("edsAppLightGrayColor").removeClass("disabled");
        emailCheckbox.prop("disabled", false);
        textCheckbox.prop("disabled", false);
        phoneCheckbox.prop("disabled", false);

        if (email) {
            emailCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_email", {email: email}));
        } else {
            emailContainer.addClass("edsAppLightGrayColor").addClass("disabled");
            emailCheckbox.prop("disabled", true);
            emailCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_email", {email: "???"}));
        }

        if (phoneNumber) {
            textCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_text", {number: phoneNumber}));
            phoneCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_phone", {number: phoneNumber}));
            this._view.getChildView("add_phone_number").text(edsApp.model.getLocalizedString("edit_phone_number"));

            var textTitle = edsApp.model.getLocalizedString("input_text_code", {number: phoneNumber});
            textContainer.getChildView("text_code_title").text(textTitle);
            textContainer.getChildView("text_code").attr("aria-label", textTitle);

            var phoneTitle = edsApp.model.getLocalizedString("input_phone_code", {number: phoneNumber});
            phoneContainer.getChildView("phone_code_title").text(phoneTitle);
            phoneContainer.getChildView("phone_code").attr("aria-label", phoneTitle);

        } else {
            textContainer.addClass("edsAppLightGrayColor").addClass("disabled");
            phoneContainer.addClass("edsAppLightGrayColor").addClass("disabled");
            textCheckbox.prop("disabled", true);
            phoneCheckbox.prop("disabled", true);
            textCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_text", {number: "???"}));
            phoneCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_phone", {number: "???"}));
            this._view.getChildView("add_phone_number").text(edsApp.model.getLocalizedString("add_phone_number"));
        }
    }

    checkCheckboxes() {

        var email = this._view.getChildView("email").val();
        var phoneNumber = this._view.getChildView("phone").val();

        var emailContainer = this._view.getChildView("two_factor_by_email_container");
        var textContainer = this._view.getChildView("two_factor_by_text_container");
        var phoneContainer = this._view.getChildView("two_factor_by_phone_container");
        var totpContainer = this._view.getChildView("two_factor_by_totp_container");
        var emailCheckbox = emailContainer.getChildView("two_factor_by_email");
        var textCheckbox = textContainer.getChildView("two_factor_by_text");
        var phoneCheckbox = phoneContainer.getChildView("two_factor_by_phone");
        var totpCheckbox = totpContainer.getChildView("two_factor_by_totp");

        if (!email) {
            emailCheckbox.prop("checked", false);
        }

        if (!phoneNumber) {
            textCheckbox.prop("checked", false);
            phoneCheckbox.prop("checked", false);
        }

        //Make sure there is at least one checkbox checked.
        if (!emailCheckbox.is(":checked") && !textCheckbox.is(":checked") && !phoneCheckbox.is(":checked") && !totpCheckbox.is(":checked")) {

            if (email) {
                emailCheckbox.prop("checked", true);
            } else if (phoneNumber) {
                textCheckbox.prop("checked", true);
            }  else if (this._view.getChildView("two_factor_checkbox").is(":checked")) {
                totpCheckbox.prop("checked", true);
            }
        }

        this.showOrHideTOTPList(true);
        this.showOrHideCodeContainers(true);
    }

    sendTextCode() {
        // TODO: remove properly (WWW-1608)
        // this.sendCode("text_code");
    }

    sendPhoneCode() {
        // TODO: remove properly (WWW-1608)
        // this.sendCode("phone_code");
    }

    sendCode(url) {
        var codeArgs = {
            "phone": this._view.getChildView("phone").val(),
            "countryId": this._view.getChildView("country").val()
        };

        var newUserCreateController = this;

        edsApp.model.postDataToURL(url, codeArgs, function (success, data) {

            if (!success) {

                newUserCreateController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                newUserCreateController._view.getChildView("alert").slideDown();
            }
        });
    }

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("create_button").prop("disabled", !formValidator.getFormStatus());
    }
};
