edsApp.classes.controllers.custom.HomeController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "home";
        this.requiresLogin = true;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        if (a.successMessage) {
            this._view.getChildView("success_alert_message").text(a.successMessage);
            this._view.getChildView("success_alert").show();
        } else if (a.new_user) {
            var sam = this._view.getChildView("success_alert_message");
            sam.text(edsApp.model.getLocalizedString("new_user_success") + " ");
            // add password link to new user success alert
            var $a = $("<a>").attr("href", "?controller=change_password")
                             .attr("class", "alert-link").attr("data-eds-link", true)
                             .text(edsApp.model.getLocalizedString("new_user_success_password"));
            sam.append($a);
            this._view.getChildView("success_alert").show();
        } else {
            this._view.getChildView("success_alert").hide();
        }
    };
};
