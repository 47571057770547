edsApp.classes.controllers.custom.MemberInvitesController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        //Define members.
        this.name = "member_invites";
        this.requiresLogin = true;
        this.groupId = null;
        this.helpLink = "invite_group_members";

        //Table related members.
        this.selectAllCheckboxes = false;
        this.rowsPerPage = 20;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        var memberInviteController = this;

        this._view.getChildView("select_all").on("click", function(e) {

            e.preventDefault();
            memberInviteController.selectAllCheckboxes = true;
            memberInviteController.beginFetching();
        });

        this._view.getChildView("deselect_all").on("click", function(e) {

            e.preventDefault();
            memberInviteController.selectAllCheckboxes = false;
            memberInviteController.beginFetching();
        });


        this._view.getChildView("remove").on("click", function (e) {

            e.preventDefault();

            var invitesToDelete = _.where(memberInviteController.tableData, {_checked: true});

            if (invitesToDelete.length == 0)
                return;

            var inviteIds = _.map(invitesToDelete, function (x){ return x.id; });

            memberInviteController._view.getChildView("dropdown_button").hide();
            memberInviteController._view.getChildView("spinner").show();
            memberInviteController._view.getChildView("table").hide();
            memberInviteController._view.getChildView("alert").slideUp();


            edsApp.model.putDataToURL("invite", {group_id: memberInviteController.groupId, ids: inviteIds}, function (success, data) {

                if (success) {

                    edsApp.model.clearJSONDataCacheForURLBeginningWith("invite");
                    memberInviteController.beginFetching();

                } else {

                    memberInviteController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    memberInviteController._view.getChildView("alert").slideDown();
                }

                memberInviteController._view.getChildView("table").show();
                memberInviteController._view.getChildView("dropdown_button").show();
                memberInviteController._view.getChildView("spinner").hide();
            });
        });

        this._view.getChildView("invite_members").on("click", function (e) {
            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.new_member_invites, true, {group_id: memberInviteController.groupId});
        });

        //Setup the tableView.
        var columnNames = ["", edsApp.model.getLocalizedString("email"), edsApp.model.getLocalizedString("invited_by"), edsApp.model.getLocalizedString("expiration_date")];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveColumns(true);
        this.tableView.setAllowsInteractiveRows(false);
    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        return (_.has(a, "group_id"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.groupId = a.group_id.toString();

        this.selectAllCheckboxes = false;
        this._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("loading"));
        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("dropdown_button").hide();

        var memberInviteController = this;
        edsApp.model.getJSONDataForURL("groups", {group_id: this.groupId}, 100, function (success, group) {

            if (success) {
                memberInviteController._view.getChildView("subtitle").text(group.name);
            } else {
                memberInviteController._view.getChildView("subtitle").html($('<span class="glyphicon glyphicon-question-sign"></span>'));
                memberInviteController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(group));
                memberInviteController._view.getChildView("alert").slideDown();
            }
        });

        this.beginFetching();
    };

    //Table data methods as required by SingleTableViewController
    fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        var memberInviteController = this;

        //We ignore incremental loading and load all members at once.
        edsApp.model.getJSONDataForURL("invite", {group_id: this.groupId}, 100, function (success, data) {

            if (success) {
                data = _.each(data, function (x){
                   x._checked =  memberInviteController.selectAllCheckboxes;
                });
            }

            fetchCallbackFn(success, data);
        });
    };

    sortTableData(columnNumber, sortDescending, data, sortCallbackFn) {
        var predicateFn = null;
        var sortedData = null;
        var sortComplexDataMap = {}; //Used for mapping columns with the data used for sorting them in complex sorting.

        if (columnNumber == 0) {

            predicateFn = function (anInvite) {
                return anInvite._checked;
            };

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);


        } else if (columnNumber == 1) {

            predicateFn = function (anInvite) {
                if (anInvite.email)
                    return anInvite.email.toLowerCase();
                else
                    return "";
            };

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);

        } else if (columnNumber == 2) {

            predicateFn = function (anInvite) {
                if (sortComplexDataMap[anInvite.referrer])
                    return sortComplexDataMap[anInvite.referrer].toLowerCase();
                else
                    return "";
            };

            //Request all the data needed to sort the data.
            edsApp.model.getJSONDataForURL("members", {group_id: this.groupId}, 100, function (success, members) {

                if (!success) {
                    sortCallbackFn(false, null);
                    return;
                }

                _.each(members, function (aMember) {
                    sortComplexDataMap[aMember.sub] = aMember.name;
                });

                sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
                sortCallbackFn(true, sortedData);
            });

            //Prepare the data map.
            _.each(data, function (aMember) {

                if (aMember.role == "member") {
                    sortComplexDataMap[aMember.sub] = 0;
                } else if (aMember.role == "admin") {
                    sortComplexDataMap[aMember.sub] = 1;
                } else if (aMember.role == "owner") {
                    sortComplexDataMap[aMember.sub] = 2;
                }
            });

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);

        } else if (columnNumber == 3) {

            predicateFn = function (anInvite) {
                if (anInvite.expirationDate)
                    return anInvite.expirationDate;
                else
                    return "";
            };

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);

        } else {

            sortCallbackFn(false, null);

        }
    };

    getTableViewMessage(data) {
        return edsApp.model.getLocalizedString("invites_total", {number: data.length});
    };

    didFinishDownloadingTableData(data) {

        if (data && data.length > 0)
            this._view.getChildView("dropdown_button").show();
    };

    //Table delegate functions.
    dataForCell(tableView, row, column, callbackFunction) {

        var data = this.tableData[row];

        if (column == 0) {

            var checkboxDomObject = $("<input type='checkbox'/>");
            checkboxDomObject.prop("checked", data._checked);
            callbackFunction(checkboxDomObject, "jQueryObject", true);

        } else if (column == 1) {
            callbackFunction(data.email, "text", false);
        } else if (column == 2) {
            edsApp.model.getJSONDataForURL("members", {group_id: this.groupId}, 100, function (success, members) {
                if (success) {
                    var member = _.findWhere(members, {sub: data.referrer});
                    callbackFunction(member ? member.name : data.referrer, "text", true);
                }
                else {
                    callbackFunction(null, "text", false);
                }
            });
        }else if (column == 3) {
            callbackFunction(new Date(data.expirationDate).toLocaleString(), "text", false);
        } else {
            callbackFunction(null, "text", false);
        }
    };

    didSelectCell(tableView, row, column, cellDomObject) {

        if (column == 0) {

            var newCheckboxStatus = !this.tableData[row]._checked;
            this.tableData[row]._checked = newCheckboxStatus;
            var checkbox = cellDomObject.find("input");

            //We have to do the checking / unchecking in a different event loop due to preventDefault().
            edsApp.utilities.invokeFunctionWithDelay(1, function() { checkbox.prop('checked', newCheckboxStatus); });
        } else if (column == 2) {
            edsApp.controllers.app.pushController(edsApp.controllers.member_detail, true, {member_id: this.tableData[row].referrer, group_id: this.groupId});
        }
    };

    didSelectColumnHeader(tableView, columnNumber) {
        //Determine which way to order.
        if (this.sortedColumn == columnNumber) {
            this.sortDescending = !this.sortDescending;
        } else {
            this.sortedColumn = columnNumber;
            this.sortDescending = true;
        }

        this.beginSorting(columnNumber, this.sortDescending);
    };
};