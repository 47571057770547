edsApp.utilities.custom.redirectToErrorPage = function (userMessage, logMessage) {

    userMessage = userMessage || edsApp.model.getLocalizedString("generic_error");

    //We need to account for the fact that there may not be any localized strings yet.
    if (userMessage == "generic_error")
        userMessage = "";

    logMessage = logMessage || "Null";

    window.location = "/error?" + $.param({userMessage: userMessage, logMessage: logMessage});
};


edsApp.utilities.custom.reportError = function (errorEvent) {

    //Only report errors that were thrown from our code.
    if (!errorEvent.filename.includes("mca"))
        return; 

    var jsonDict = {
        msg: errorEvent.message,
        ln: errorEvent.lineno,
        col: errorEvent.colno,
        src: errorEvent.filename
    }

    if (errorEvent.error && errorEvent.error.stack)
        jsonDict.stack = errorEvent.error.stack.substr(0, 1800);
    
    var options = {
        edsOmitAjaxPath: true,
        method: "POST",
        body: JSON.stringify(jsonDict)
    }

    edsApp.model.fetchAsync("/error-report", options);
};


edsApp.utilities.custom.redirect = function (url, data, useFragment) {

    if (data)
        url = url + (useFragment ? "#" : "?") + $.param(data);

    window.location.replace(url);
};


edsApp.utilities.custom.getXSRFValue = function() {
    var name = "xsrf_session" + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return undefined;
};


edsApp.utilities.custom.passwordStrength = function (password) {
    //Returns a score from 0 up to 100

    if (_.isUndefined(password))
        return 0;

    var difficulty = 1.0; //Can be any number from 1 to infinity.
    var entropy = {}; //Detects how many distinct characters exist.

    _.each(password, function (aChar) {

        entropy[aChar] = true;
    });

    var entropyCount = Object.keys(entropy).length;

    var result = Math.pow(2, password.length);
    result = Math.pow(result, (1/difficulty) * (1 - Math.pow((entropyCount + 9)/10, -1)) );

    return Math.min(result, 100);
};


edsApp.utilities.custom.sanitizeTotpCode = function (string) {

    if (_.isUndefined(string))
        return NaN;

    var digitsOnlyString = "";

    for (var i = 0, len = string.length; i < len; i++) {

        if (_.contains(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"], string[i]))
            digitsOnlyString += string[i];
    }

    return digitsOnlyString.length == 6 ? parseInt(digitsOnlyString) : NaN;
};


edsApp.utilities.custom.compressImageToDataURL = function(element, width, height, maxWidth, maxHeight, format) {
    //Returns a string in base 64 encoding representing a compressed image of type 'format' of the HTML element.
    var canvas = document.createElement('canvas');

    //Adjust the size of the canvas.
    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(element, 0, 0, width, height);
    return canvas.toDataURL(format);
};


edsApp.utilities.custom.setupSecureCallback = function() {
    //Secure callbacks allow other websites in different domains to smoothly integrate with the system.
    //For example, a group might need to be created during a certain flow of an external website. Secure callbacks
    //allow smoother integration between both sites by using HTML5's postMessage to securely communicate with each other.
    //Any controller can setup a secure callback. They will receive a postFn(requiredScopes, result, callbackFn)
    //That can be used to post the result back to the external site. As soon as the post is acknowledged by the external
    //site, the window will either close OR callbackFn will be called with a 'success' value set to false.

    var tokenSuccess = undefined;
    var tokenInfo = null;
    var origin = null;

    var requiredScopesRef = undefined;
    var callbackFnRef = undefined;
    var resultRef = undefined;

    var processPostFn = function () {

        //We need tom make sure postFn has been called.
        if (_.isUndefined(requiredScopesRef) || _.isUndefined(callbackFnRef))
            return;

        //If the token passed was invalid or the opening window has been closed, we return false.
        if (tokenSuccess === false || !window.opener) {
            callbackFnRef(false);
            return;
        }

        //If we still haven't gotten the token information, we return.
        if (_.isUndefined(tokenSuccess))
            return;

        //We make sure the token is good for the operation we are trying to do.
        if (_.difference(requiredScopesRef, tokenInfo.scope.split(" ")).length > 0) {
            callbackFnRef(false);
            return;
        }

        //We post the result to the opening window, which will close this window upon receiving the message.
        //Note that we only post the message to the origin that originally requested it for security reasons.
        window.opener.postMessage({result: resultRef, finished: true}, origin);

        //This should never get called because the window will be closed by then.
        edsApp.utilities.invokeFunctionWithDelay(5000, function () {
            callbackFnRef(false);
        });
    };

    var postFn = function (requiredScopes, result, callbackFn) {
        //callbackFn args: success.
        callbackFnRef = callbackFn;
        requiredScopesRef = requiredScopes;
        resultRef = result;

        processPostFn();
    };

    window.addEventListener("message", function (e) {

        if (e.data.token && e.source === window.opener) {

            origin = e.origin;

            //Verify the oauth token.
            edsApp.model.getJSONDataForURL("oauth2/tokeninfo", {}, 1, function (success, data) {

                if (success) {
                    tokenSuccess = true;
                    tokenInfo = data;
                } else {
                    tokenSuccess = false;
                }

                processPostFn();

            }, false, {Authorization: "Bearer " + e.data.token});
        }

    }, false);

    if (window.opener) {
        window.opener.postMessage({loaded: true}, "*");
    }

    return _.once(postFn);
};


edsApp.utilities.custom.processSuccessfulLogin = function (storeLoginInfo, email, providerName, loginData, redirectControllerName, redirectRecordHistory, redirectControllerArgs) {

    //Save or delete the user's email address from local storage.
    if (storeLoginInfo) {

        //Update the login_provider_name in local storage. We only update the actual provider if it is public.
        edsApp.model.setLocalStorageValueForKey(providerName && _.contains(edsApp.model.custom.foreignProviderNames, providerName) ? providerName : "mcneel", "login_provider_name");
        edsApp.model.setLocalStorageValueForKey(email, "user_email");
    }
    else {
        edsApp.model.removeLocalStorageValueForKey("user_email");
        edsApp.model.removeLocalStorageValueForKey("login_provider_name");
    }

    edsApp.model.ajaxHeaders = {"Mca-XSRF": edsApp.utilities.custom.getXSRFValue, "Mca-sub": loginData.user.id};
    edsApp.model.custom.user = loginData.user;
    edsApp.model.custom.sessionInfo = loginData.session_info;
    edsApp.controllers.app.refreshNavigationBar();

    //This statement allows certain scenarios to bypass consent in the auth controller.
    if (redirectControllerName == edsApp.controllers.auth.name)
        edsApp.controllers.auth.justLoggedIn = true;

    //We're done. let's redirect the user to where they wanted to go.
    edsApp.controllers.app.pushController(edsApp.controllers[redirectControllerName], redirectRecordHistory, redirectControllerArgs);
};


edsApp.utilities.custom.generateForeignProviderRedirectURL = function () {
    return window.location.protocol + "//" + window.location.host + edsApp.model.custom.foreignProviderRedirectPath;
};


edsApp.utilities.custom.generateAuthURLForOIDCForeignAccount = function (endpointURL, clientId, state, nonce, customValues, callbackFn) {
    //Callback fn parameters: (bool)success, authURL/error);

    edsApp.model.getJSONDataForURL(endpointURL + ".well-known/openid-configuration", {}, 2629800, function (success, discoveryDoc) {

        if (success) {

            var values = {
                response_type: "id_token",
                client_id: clientId,
                redirect_uri: edsApp.utilities.custom.generateForeignProviderRedirectURL(),
                scope: "openid email profile",
                state: state,
                nonce: nonce
            };

            if (discoveryDoc.claims_parameter_supported !== false) {
                values.claims = JSON.stringify({id_token: { sub: {essential: true}, email: {essential: true}, email_verified: null, picture: null, name: null}});
            }

            var qs = $.param(_.extend(values, customValues));

            callbackFn(true, discoveryDoc.authorization_endpoint + "?" + qs)
        } else {
            callbackFn(false, discoveryDoc ? discoveryDoc : "OIDC discovery error");
        }

    }, true, {});
};


edsApp.utilities.custom.decodeOIDCAuthResult = function (providerName, authResult, nonce, callbackFn) {

    //Callback fn parameters: (bool)success, providerName/error, providerToken, userId, userEmail, userName, userImageURL);
    edsApp.model.getScriptForURL("/js/min/jwt-decode.min.js", {}, function (success) {
        if (!success) {
            callbackFn(false, "jwt-decode");
            return;
        }

        var payload = jwt_decode(authResult.id_token);

        //azure_ad hack.
        if (!payload.email) {
            if (payload.upn && payload.upn.indexOf("@") > 0)
                payload.email = payload.upn;
            else if (payload.unique_name && payload.unique_name.indexOf("@") > 0)
                payload.email = payload.unique_name;
        }

        var nonceMatches = nonce === payload.nonce;
        var providerNameOrError = nonceMatches ? providerName : edsApp.model.getLocalizedString("error_invalid_foreign_id_token");

        callbackFn(nonceMatches, providerNameOrError, authResult.id_token, payload.sub, payload.email, payload.name, payload.picture);
    })
};


edsApp.utilities.custom.loginWithForeignProvider = function (providerName, providerId, domain, callbackFn) {

    //Callback fn parameters: (bool)success, providerName/error, providerToken, userId, userEmail, userName, userImageURL);

    //Open a window.
    var childWindow = window.open("about:blank", "_blank", "width=480,height=640");

    if (childWindow == null)
        callbackFn(false, edsApp.model.getLocalizedString("error_foreign_provider_window_blocked"));

    var c = _.isUndefined(window.crypto) ? window.msCrypto : window.crypto;
    var state = edsApp.utilities.arrayBufferToBase64String(c.getRandomValues(new Uint8Array(24)));
    var nonce = edsApp.utilities.arrayBufferToBase64String(c.getRandomValues(new Uint8Array(24)));

    edsApp.utilities.custom.generateAuthURL(providerName, providerId, domain, state, nonce, function (authURLSuccess, authURL) {

        if (authURLSuccess) {
            childWindow.location.href = authURL;

            //Now monitor the child window until it either closes or it has the information we want.
            var interval = setInterval(function () {

                if (childWindow.closed) {
                    callbackFn(false, edsApp.model.getLocalizedString("error_foreign_provider_window_closed"));
                    clearInterval(interval);
                } else {
                    try {

                        if (childWindow.authResult) {

                            var authResult = edsApp.utilities.getAllURLParameters(childWindow.authResult);

                            if (authResult.error || authResult.state != state) {
                                childWindow.close();
                                clearInterval(interval);
                                
                                var errorOptions = {
                                    edsOmitAjaxPath: true,
                                    method: "POST",
                                    body: JSON.stringify({error: authResult.error, state: state, returnedState: authResult.state, id: providerId, name: providerName})
                                };
                            
                                edsApp.model.fetchAsync("/error-report", errorOptions);

                                callbackFn(false, authResult.error ? authResult.error : "state mismatch");
                            } else {
                                childWindow.close();
                                clearInterval(interval);
                                edsApp.utilities.custom.decodeProviderToken(providerName, authResult, nonce, callbackFn);
                            }
                        }

                    } catch (e) {}
                }

            }, 500);

        } else {
            childWindow.close();
            callbackFn(false, authURL ? authURL.error : "auth url error");
        }
    });
};


edsApp.utilities.custom.decodeProviderToken = function (providerName, authResult, nonce, callbackFn) {

    //Callback fn parameters: (bool)success, providerName, providerToken, userId, userEmail, userName, userImageURL);
    if (providerName == "google") {

        edsApp.utilities.custom.decodeOIDCAuthResult(providerName, authResult, nonce, callbackFn);

    } else if (providerName == "facebook") {
        //We'll make a call to Facebook's graph api to get answers to all of the above.
        edsApp.model.getJSONDataForURL(edsApp.model.custom.facebookGraphApiURL, {access_token: authResult.access_token, fields: "id,email,name,picture"}, 100, function (success, apiResponse) {

            if (apiResponse && !apiResponse.error) {
                callbackFn(true, providerName, authResult.access_token, apiResponse.id, apiResponse.email, apiResponse.name, apiResponse.picture ? apiResponse.picture.data.url : undefined);
            } else {
                callbackFn(false, apiResponse.error);
            }

        }, true, {});
    } else if (providerName == "onelogin") {

        edsApp.utilities.custom.decodeOIDCAuthResult(providerName, authResult, nonce, callbackFn);
    } else if (providerName == "azure_ad") {

        edsApp.utilities.custom.decodeOIDCAuthResult(providerName, authResult, nonce, callbackFn);
    } else if (providerName == "okta") {

        edsApp.utilities.custom.decodeOIDCAuthResult(providerName, authResult, nonce, callbackFn);
    } else if (providerName == "custom") {

        edsApp.utilities.custom.decodeOIDCAuthResult(providerName, authResult, nonce, callbackFn);
    }
};


edsApp.utilities.custom.generateAuthURL = function (providerName, providerId, domain, state, nonce, callbackFn) {
    //Callback fn parameters: (bool)success, authURL/error);
    if (providerName == "google") {

        var customValues = {prompt: "select_account"};

        if (providerId) {
            customValues = {login_hint: providerId};
        }

        edsApp.utilities.custom.generateAuthURLForOIDCForeignAccount("https://accounts.google.com/", edsApp.model.custom.googleAuth2ClientId, state, nonce, customValues, callbackFn);

    } else if (providerName == "facebook") {

        var values = {
                response_type: "token",
                client_id: edsApp.model.custom.facebookAuth2ClientId,
                display:"popup",
                redirect_uri: edsApp.utilities.custom.generateForeignProviderRedirectURL(),
                state: state
        };

        var qs = $.param(values);

        callbackFn(true, "https://www.facebook.com/v2.10/dialog/oauth?" + qs)

    } else if (providerName == "onelogin") {

        edsApp.utilities.custom.generateAuthURLForOIDCForeignAccount(domain.loginProviderInfo.endpointURL, domain.loginProviderInfo.clientId, state, nonce, {}, callbackFn);
    } else if (providerName == "azure_ad") {

        edsApp.utilities.custom.generateAuthURLForOIDCForeignAccount(domain.loginProviderInfo.endpointURL, domain.loginProviderInfo.clientId, state, nonce, {"prompt": "select_account"}, callbackFn);
    } else if (providerName == "okta") {

        edsApp.utilities.custom.generateAuthURLForOIDCForeignAccount(domain.loginProviderInfo.endpointURL, domain.loginProviderInfo.clientId, state, nonce, {}, callbackFn);
    } else if (providerName == "custom") {

        edsApp.utilities.custom.generateAuthURLForOIDCForeignAccount(domain.loginProviderInfo.endpointURL, domain.loginProviderInfo.clientId, state, nonce, {}, callbackFn);
    }
};


edsApp.utilities.custom.getImageURLForProvider = function (providerName) {

    if (providerName == "google") {
        return "/media/google_plus.png";
    } else if (providerName == "facebook") {
        return "/media/facebook.png";
    } else if (providerName == "onelogin") {
        return "/media/onelogin.png";
    } else if (providerName == "azure_ad") {
        return "/media/azure_ad.png";
    } else if (providerName == "okta") {
        return "/media/okta.png";
    }else if (providerName == "custom") {
        return "/media/custom.png";
    } else {
        return "";
    }
};

edsApp.utilities.custom.getSvgIconForProvider = function (providerName) {
    if (providerName == "google") {
        return '<svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">    <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>    <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>    <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>    <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>  </g></svg>';
    } else if (providerName == "facebook") {
        return '<svg xmlns="http://www.w3.org/2000/svg" width="1365.12" height="1365.12" viewBox="0 0 14222 14222"><circle cx="7111" cy="7112" r="7111" fill="#1977f3"/><path d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z" fill="#fff"/></svg>';
    } else {
        return "";
    }
}


edsApp.utilities.custom.getLabelStyleForProvider = function (providerName) {

    if (providerName == "google") {
        return "label-google";
    } else if (providerName == "facebook") {
        return "label-facebook";
    } else {
        return "label-info";
    }
};

edsApp.utilities.custom.getButtonStyleForProvider = function (providerName) {

    if (providerName == "google") {
        return "btn btn-danger";
    } else if (providerName == "facebook") {
        return "btn btn-primary";
    } else {
        return "btn btn-info";
    }
};

// new-style buttons used in signup form
edsApp.utilities.custom.getButtonStyleForProvider2 = function (providerName) {

    if (providerName == "google") {
        return "btn btn-google";
    } else if (providerName == "facebook") {
        return "btn btn-facebook";
    } else {
        return "btn btn-default";
    }
};

edsApp.utilities.custom.isUserInDomain = function (user) {
    // Checks to see if the given user is *already* part of a domain linked group.

    if (user.foreignAccounts.length != 1) {
        return false;
    }
    
    var groups = user.memberGroups.concat(user.adminGroups).concat(user.ownerGroups);
    var email = user.email ? user.email : user.emails[0];
    var domain = email.split("@")[1];

    for (let group of groups) {
        for (let domainDict of group.domains) {
            if (domainDict.domain === domain && domainDict.loginProviderName === user.foreignAccounts[0].providerName) {
                return true;
            }
        }
    }

    return false;
}

