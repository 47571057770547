edsApp.classes.controllers.custom.ForgotPasswordController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();
        /* Do setup specific to this class. */

        //Define members.
        this.name = "forgot_password";
        this.performingRequest = false;
        this.redirectControllerArgs = null;
        this.redirectControllerName = null;
        this.helpLink = "recover_account";
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Hookup view events to their respective "listeners" or "targets".
        var forgotPasswordController = this;
        this._view.getChildView("form").on("submit", function (e) {
            if (!forgotPasswordController.formValidator.getFormStatus() || forgotPasswordController.performingRequest)
                return;

            forgotPasswordController.performingRequest = true;
            forgotPasswordController._view.getChildView("continue").hide();
            forgotPasswordController._view.getChildView("email_container").hide();
            forgotPasswordController._view.getChildView("subtitle").hide();
            forgotPasswordController._view.getChildView("spinner").show();

            var postArgs = {email: forgotPasswordController._view.getChildView("email").val()};

            edsApp.model.postDataToURL("login", postArgs, function (success, data) {

                if (success) {

                    //Set the options title and the next steps.
                    var $title = forgotPasswordController._view.getChildView("options_title");
                    var $nextSteps = forgotPasswordController._view.getChildView("next_steps_list");
                    $title.removeClass();
                    $title.addClass("alert");
                    $nextSteps.empty();

                    if (data.guidance.account_exists) {

                        if (data.guidance.verify_email) {
                            $title.addClass("alert-warning").text(edsApp.model.getLocalizedString("email_verification", {email: data.guidance.verify_email}));
                            $nextSteps.append($('<li></li>').text(edsApp.model.getLocalizedString("email_verification_message")));

                            var $verifyEmailA = $("<a data-eds-link></a>").text(edsApp.model.getLocalizedString("didnt_get_email")).attr("href", edsApp.utilities.createRelativeURLForController(
                                edsApp.controllers.verify_email.name,
                                {redirectControllerName: forgotPasswordController.redirectControllerName,
                                 redirectControllerArgs: forgotPasswordController.redirectControllerArgs,
                                 email: postArgs.email}
                            ));

                            $nextSteps.append($('<li></li>').append($verifyEmailA));
                        } else {
                            $title.addClass("alert-success").text(edsApp.model.getLocalizedString("reset_password_login_methods"));

                            _.each(data.guidance.foreign_account_provider_info, function(aProvider) {
                                var link = edsApp.utilities.createRelativeURLForController(
                                    edsApp.controllers.login.name,
                                    {
                                        providerNames:[aProvider.name],
                                        redirectControllerName: forgotPasswordController.redirectControllerName,
                                        redirectControllerArgs: forgotPasswordController.redirectControllerArgs
                                    }
                                );

                                var $a = $('<a data-eds-link></a>').attr("href", link).text(edsApp.model.getLocalizedString("sign_in_with_provider", {provider: edsApp.model.getLocalizedString(aProvider.name)}));
                                $nextSteps.append($('<li></li>').append($a));
                            });

                            if (data.guidance.magic_link_eligible) {
                                var magicLink = edsApp.utilities.createRelativeURLForController(
                                    edsApp.controllers.login.name,
                                    {
                                        email:postArgs.email,
                                        redirectControllerName: forgotPasswordController.redirectControllerName,
                                        redirectControllerArgs: forgotPasswordController.redirectControllerArgs
                                    }
                                );

                                var $magicLink = $('<a data-eds-link></a>').attr("href", magicLink).text(edsApp.model.getLocalizedString("magic_link_recovery_message", {email: postArgs.email}));
                                $nextSteps.append($('<li></li>').append($magicLink));
                            }

                            if (data.guidance.has_password) {
                                var link = edsApp.utilities.createRelativeURLForController(
                                    edsApp.controllers.login.name,
                                    {
                                        email:postArgs.email,
                                        redirectControllerName: forgotPasswordController.redirectControllerName,
                                        redirectControllerArgs: forgotPasswordController.redirectControllerArgs
                                    }
                                );


                                var $a = $('<a data-eds-link></a>').attr("href", link).text(edsApp.model.getLocalizedString("sign_in_with_password"));
                                $nextSteps.append($('<li></li>').append($a));

                                var $resetA = $('<a href="#"></a>').text(edsApp.model.getLocalizedString("reset_password"));

                                $resetA.on("click", function (e) {
                                    e.preventDefault();

                                    var $resetByText = forgotPasswordController._view.getChildView("reset_by_text");
                                    var $resetByPhone = forgotPasswordController._view.getChildView("reset_by_phone");

                                    $resetByText.empty().text("").removeClass();
                                    $resetByPhone.empty().text("").removeClass();

                                    var number = data.guidance.phone ? data.guidance.phone : "???";
                                    var resetByTextTitle = edsApp.model.getLocalizedString("reset_password_by_text", {number: number});
                                    var resetByPhoneTitle = edsApp.model.getLocalizedString("reset_password_by_phone", {number: number});

                                    if (data.guidance.phone) {
                                        var $resetByTextLink = $('<a href="#" data-mca-mode="text">' + _.escape(resetByTextTitle) + '</a>');
                                        var $resetByPhoneLink = $('<a href="#" data-mca-mode="phone">' + _.escape(resetByPhoneTitle) + '</a>');

                                        $resetByText.append($resetByTextLink);
                                        $resetByPhone.append($resetByPhoneLink);
                                    } else {
                                        $resetByText.addClass("edsAppLightGrayColor").text(resetByTextTitle);
                                        $resetByPhone.addClass("edsAppLightGrayColor").text(resetByPhoneTitle);
                                    }

                                    forgotPasswordController._view.getChildView("password_options_container").slideDown();
                                });

                                $nextSteps.append($('<li></li>').append($resetA));
                            }
                        }

                    } else {
                        $title.addClass("alert-danger").text(edsApp.model.getLocalizedString("non_existing_account", {email: postArgs.email}));

                        var linkLogin = "?" + $.param({
                            controller: "login",
                            redirectControllerName: forgotPasswordController.redirectControllerName,
                            redirectControllerArgs: forgotPasswordController.redirectControllerArgs
                        });

                        var linkNewUser = "?" + $.param({
                            controller: "new_user",
                            email: postArgs.email,
                            redirectControllerName: forgotPasswordController.redirectControllerName,
                            redirectControllerArgs: forgotPasswordController.redirectControllerArgs
                        });

                        $nextSteps.append($('<li></li>').append($('<a data-eds-link></a>').attr("href", linkLogin).text(edsApp.model.getLocalizedString("use_different_account"))));
                        $nextSteps.append($('<li></li>').append($('<a data-eds-link></a>').attr("href", linkNewUser).text(edsApp.model.getLocalizedString("create_account"))));
                    }

                    forgotPasswordController._view.getChildView("options_container").show();

                } else {

                    forgotPasswordController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    forgotPasswordController._view.getChildView("alert").slideDown();
                    forgotPasswordController._view.getChildView("continue").show();
                    forgotPasswordController._view.getChildView("email_container").show();
                    forgotPasswordController._view.getChildView("subtitle").show();
                }

                forgotPasswordController._view.getChildView("spinner").hide();
                forgotPasswordController.performingRequest = false;
            });
        });

        this._view.getChildView("password_options_container").on("click", "a", function(e) {
            e.preventDefault();

            if (forgotPasswordController.performingRequest)
                return;

            forgotPasswordController.performingRequest = true;
            forgotPasswordController._view.getChildView("spinner").show();
            var method = $(this).attr("data-mca-mode");
            var postArgs = {email: forgotPasswordController._view.getChildView("email").val(), method: method};
            

            edsApp.model.fetchScriptAsync("https://www.recaptcha.net/recaptcha/enterprise.js", {render: edsApp.model.custom.recaptchaSiteKey}).then(() =>
            {
                grecaptcha.enterprise.ready(function() {
                    grecaptcha.enterprise.execute(edsApp.model.custom.recaptchaSiteKey, {action: 'forgot_password'}).then(function(token) {
                        postArgs["recaptchaToken"] = token;
                        edsApp.model.postDataToURL("forgot-password", postArgs, function (success, data) {
    
                            if (success) {
    
                                if (method == "email") {
                                    var loginControllerArgs = {redirectControllerName: forgotPasswordController.redirectControllerName,
                                                            redirectControllerArgs: forgotPasswordController.redirectControllerArgs,
                                                            successMessage: edsApp.model.getLocalizedString("forgot_password_success_message", {email: postArgs.email})};
    
                                    edsApp.controllers.app.pushController(edsApp.controllers.login, true, loginControllerArgs);
    
                                } else {
    
                                    //Send them over to the reset password controller.
                                    var resetPasswordControllerArgs = {redirectControllerName: forgotPasswordController.redirectControllerName,
                                                                    redirectControllerArgs: forgotPasswordController.redirectControllerArgs,
                                                                    email: postArgs.email,
                                                                    method: method};
    
                                    edsApp.controllers.app.pushController(edsApp.controllers.reset_password, true, resetPasswordControllerArgs);
    
                                }
    
                            } else {
    
                                forgotPasswordController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                                forgotPasswordController._view.getChildView("alert").slideDown();
                            }
    
                            forgotPasswordController._view.getChildView("spinner").hide();
                            forgotPasswordController.performingRequest = false;
                        });
                    });
                });
            });
            

        });
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.redirectControllerName = a.redirectControllerName ?? "home";
        this.redirectControllerArgs = a.redirectControllerArgs ?? "{}";

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("continue").show();
        this._view.getChildView("email_container").show();
        this._view.getChildView("subtitle").show();

        //Reset the recovery options.
        this._view.getChildView("options_container").hide();
        this._view.getChildView("password_options_container").hide();

        //Reset the form.
        this._view.getChildView("email").val(a.email ? a.email : "");
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("continue").prop("disabled", !this.formValidator.getFormStatus());
    };

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("continue").prop("disabled", !formValidator.getFormStatus());
    }
};
