edsApp.classes.controllers.custom.ResetPasswordController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "reset_password";
        this.token = null;
        this.email = null;
        this.performingRequest = false;
        this.redirectControllerArgs = null;
        this.redirectControllerName = null;
        this.helpLink = "recover_account";
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Hookup view events to their respective "listeners" or "targets".
        var resetPasswordController = this;
        this._view.getChildView("password").on("blur focus", function (e) {
            resetPasswordController._view.getChildView("progress_bar_container").slideToggle();
        });

        this._view.getChildView("password").on("change keyup paste focus", function (e) {

            var strength = edsApp.utilities.custom.passwordStrength($(this).val());
            var progressBarDomObject = resetPasswordController._view.getChildView("progress_bar");
            var progressBarTextDomObject = resetPasswordController._view.getChildView("progress_bar_text");

            progressBarDomObject.attr("aria-valuenow", strength);
            progressBarDomObject.css("width", strength + "%");
            progressBarDomObject.removeClass("progress-bar-danger").removeClass("progress-bar-warning").removeClass("progress-bar-success");

            if (strength < 25) {
                progressBarDomObject.addClass("progress-bar-danger");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("weak"));
            } else if (strength < 50) {
                progressBarDomObject.addClass("progress-bar-warning");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("fair"));
            } else {
                progressBarDomObject.addClass("progress-bar-success");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("strong"));
            }
        });

        this._view.getChildView("form").on("submit", function (e) {
            if (!resetPasswordController.formValidator.getFormStatus() || resetPasswordController.performingRequest)
                return;

            resetPasswordController.performingRequest = true;
            resetPasswordController._view.getChildView("save").hide();
            resetPasswordController._view.getChildView("spinner").show();

            var code = resetPasswordController._view.getChildView("code").val();

            var putArgs = {token:resetPasswordController.token,
                           code: code ? edsApp.utilities.custom.sanitizeTotpCode(code) : undefined,
                           email: resetPasswordController.email,
                           password: resetPasswordController._view.getChildView("password").val()};

            edsApp.model.putDataToURL("forgot-password", putArgs, function (success, data) {

                if (success) {

                    var loginControllerArgs = {redirectControllerName: resetPasswordController.redirectControllerName,
                                               redirectControllerArgs: resetPasswordController.redirectControllerArgs,
                                               successMessage: edsApp.model.getLocalizedString("reset_password_success_message")};

                    edsApp.controllers.app.pushController(edsApp.controllers.login, true, loginControllerArgs);

                } else {

                    resetPasswordController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    resetPasswordController._view.getChildView("alert").slideDown();
                    resetPasswordController._view.getChildView("save").show();
                    resetPasswordController._view.getChildView("spinner").hide();
                }

                resetPasswordController.performingRequest = false;
            });
        });
    };

    canBecomeKeyController(a) {
        //Note we are overriding the superclass implementation.
        return (_.has(a, "token") && _.has(a, "expirationDate") && new Date(a.expirationDate).getTime() > new Date().getTime()) ||
            (_.has(a, "method") && (a.method == "phone" || a.method == "text") && _.has(a, "email"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.redirectControllerName = a.redirectControllerName ? a.redirectControllerName : "home";
        this.redirectControllerArgs = a.redirectControllerArgs ? a.redirectControllerArgs : "{}";
        this.token = a.token;
        this.email = a.email;

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("save").show();

        if (this.token) {
            this._view.getChildView("code").attr("data-eds-validator", "true");
            this._view.getChildView("reset_password_code_container").hide();
        } else {
            this._view.getChildView("code").attr("data-eds-validator", "!isNaN(edsApp.utilities.custom.sanitizeTotpCode($('#reset_password_code').val()))");
            this._view.getChildView("reset_password_code_container").show();
        }

        //Reset the form.
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("progress_bar_container").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this.formValidator.reloadFormStatus();
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    };

    willResignKeyController() {

        //For security reasons, we clear these fields.
        this._view.getChildView("password").val("");
        this._view.getChildView("confirm_password").val("");
        this._view.getChildView("code").val("");

        //Call the superclass.
        super.willResignKeyController();
    };

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }
};
