edsApp.classes.controllers.custom.PermissionsController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "permissions";
        this.requiresLogin = true;
        this.helpLink = "permissions";

        //Table related members.
        this.rowsPerPage = 10;
        this.tokens = [];
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        //None for this handler ^_^

        //Setup the tableView.
        var columnNames = [edsApp.model.getLocalizedString("app_name")];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveColumns(true);
        this.tableView.setAllowsInteractiveRows(true);
        this.tableView.setClassesForColumnHeader(0, ["text-center"]);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.beginFetching();
    };

    //Table data methods as required by SingleTableViewController
    fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        var permissionsController = this;

        //We ignore incremental loading and load all tokens at once.
        edsApp.model.getJSONDataForURL("user/tokens", null, 0, function (success, data) {

            if (success) {
                permissionsController.tokens = _.groupBy(data, "clientId");
                data = _.keys(permissionsController.tokens);
            }

            fetchCallbackFn(success, data);
        });
    };

    sortTableData(columnNumber, sortDescending, data, sortCallbackFn) {

        var predicateFn = null;
        var sortedData = null;
        var sortComplexDataProgress = 0;
        var sortComplexDataMap = {}; //Used for mapping columns with the data used for sorting them in complex sorting.

        if (columnNumber == 0) {

            predicateFn = function (clientId) {
                if (sortComplexDataMap[clientId])
                    return sortComplexDataMap[clientId].toLowerCase();
                else
                    return "";
            };

            //Request all the data needed to sort the data.
            _.each(data, function(clientId) {

                edsApp.model.getJSONDataForURL("clients", {client_id: clientId}, 100, function (success, client) {

                    if (!success) {
                        sortCallbackFn(false, null);
                        return;
                    }

                    if (client)
                        sortComplexDataMap[clientId] = client.name;

                    //If this is the last mapped data item we need, we go ahead and sort the data array.
                    if (++sortComplexDataProgress == data.length) {
                        sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
                        sortCallbackFn(true, sortedData);
                    }
                });
            });

        } else {
            sortCallbackFn(false, null);
        }
    };

    getTableViewMessage(data) {
        if (data.length > 0)
            return edsApp.model.getLocalizedString("permissions_table_message");
        else
            return edsApp.model.getLocalizedString("no_permissions_granted");
    };

    //Table delegate functions.
    dataForCell(tableView, row, column, callbackFunction) {

        var clientId = this.tableData[row];

        if (column == 0) {

            edsApp.model.getJSONDataForURL("clients", {client_id: clientId}, 100, function (success, client) {

                callbackFunction( success ? client.name : null, "text", true, ["text-center"]);
            });

        } else {
            callbackFunction(null, "text", false);
        }
    };

    popoverDataForRow(tableView, row, callbackFn) {

        var clientId = this.tableData[row];
        var scope = [];

        _.each(this.tokens[clientId], function (aToken) {
            scope = _.union(aToken.scope, scope);
        });

        var listDomObject = $('<ul class="edsAppGrayColor"></ul>');

        _.each(scope, function (aGrant) {
            listDomObject.append($('<li></li>').text(edsApp.model.getLocalizedString("grant_" + aGrant)));
        });

        callbackFn(listDomObject, "jQueryObject", edsApp.model.getLocalizedString("this_app_can"));
    };

    didSelectCell(tableView, row, column, cellDomObject) {

        if (column == 0) {
            edsApp.controllers.app.pushController(edsApp.controllers.permissions_detail, true, {client_id: this.tableData[row]});
        }
    };

    didSelectColumnHeader(tableView, columnNumber) {
        //Determine which way to order.
        if (this.sortedColumn == columnNumber) {
            this.sortDescending = !this.sortDescending;
        } else {
            this.sortedColumn = columnNumber;
            this.sortDescending = true;
        }

        this.beginSorting(columnNumber, this.sortDescending);
    };
};
