edsApp.classes.controllers.custom.NewEmailController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "new_email";
        this.performingRequest = false;
        this.requiresLogin = true;
        this.helpLink = "emails";
    }


    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this._view.getChildView("make_primary_popover").popover();
        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Hookup view events to their respective "listeners" or "targets".
        var newEmailController = this;
        this._view.getChildView("form").on("submit", function (e) {
            if (!newEmailController.formValidator.getFormStatus() || newEmailController.performingRequest)
                return;

            newEmailController.performingRequest = true;
            newEmailController._view.getChildView("save").hide();
            newEmailController._view.getChildView("spinner").show();

            var postArgs = {email: newEmailController._view.getChildView("email").val(),
                            make_primary: newEmailController._view.getChildView("make_primary").is(':checked')};

            edsApp.model.postDataToURL("new-email", postArgs, function (success, data) {

                if (success) {

                    edsApp.controllers.app.pushController(edsApp.controllers.new_email_confirm, false, {email: postArgs.email});

                } else {

                    // newEmailController._view.getChildView("info_alert").slideUp();
                    newEmailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    newEmailController._view.getChildView("alert").slideDown();
                    newEmailController._view.getChildView("save").show();
                    newEmailController._view.getChildView("spinner").hide();
                }

                newEmailController.performingRequest = false;
            });
        });
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this._view.getChildView("alert").hide();
        // this._view.getChildView("info_alert").show();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("save").show();

        //Reset the form.
        this._view.getChildView("email").val(a.email ? a.email : "");
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    };

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }
};
