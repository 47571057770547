edsApp.classes.controllers.custom.PaymentDetailController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "payment_detail";
        this.requiresLogin = true;
        this.formValidator = null;
        this.helpLink = "payment";
        this.groupId = null;
        this.states = null;
        this.paymentTokenData = null;
        this.subscription = null;
        this.stateTextFieldDomObject = $('<input id="payment_detail_state" data-eds-view="state" name="state" class="form-control validated-input" type="text" placeholder="' + _.escape(edsApp.model.getLocalizedString("state_placeholder")) + '">');
        this.stateSelectFieldDomObject = $('<select id="payment_detail_state" data-eds-view="state" class="form-control"></select>');
        this.secureCallback = null;
    }

    //Define Functions.
    setViewHTML(html) {

        var paymentDetailController = this;

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);
        this.modalView = new edsApp.classes.views.ModalView(this._view);

        //Load all countries to the select form control
        var countrySelectDomObject = this._view.getChildView("country");
        edsApp.utilities.appendSortedCountryIdsToSelectDomObject(countrySelectDomObject);

        //Setup months and years.
        var $monthSelect = this._view.getChildView("cc_month");
        var $option = $("<option></option>");

        $monthSelect.append($option.clone().val(1).text(1).prop("selected", true));

        for (var i=2; i <= 12; i++) {
            $monthSelect.append($option.clone().val(i).text(i));
        }

        var $yearSelect = this._view.getChildView("cc_year");
        var currentYear = new Date().getFullYear();

        $yearSelect.append($option.clone().val(currentYear).text(currentYear).prop("selected", true));

        for (var i=currentYear + 1; i <= currentYear + 10; i++) {
            $yearSelect.append($option.clone().val(i).text(i));
        }

        this._view.getChildView("country").on("change", function(e) {
            paymentDetailController.updateStateField();
        });

        this._view.getChildView("cc_number").on("keyup change", function () {
            paymentDetailController.updateCreditCardIcon();
        });

        this._view.getChildView("edit").on("click", function () {

            paymentDetailController._view.getChildView("form").find("input,select").prop("disabled", false);
            paymentDetailController.stateSelectFieldDomObject.prop("disabled", false);
            paymentDetailController.stateTextFieldDomObject.prop("disabled", false);
            paymentDetailController._view.getChildView("save").show();
            paymentDetailController._view.getChildView("edit").hide();
            paymentDetailController._view.getChildView("remove").hide();

            paymentDetailController._view.getChildView("cc_number").val("").focus();
        });

        this._view.getChildView("remove").on("click", function () {

            paymentDetailController.modalView.removeAllButtons();
            paymentDetailController.modalView.setTitle(edsApp.model.getLocalizedString("please_wait"));
            paymentDetailController.modalView.setMessage("");
            paymentDetailController.modalView.showProgressBar(["progress-bar progress-bar-primary progress-bar-striped active"]);
            paymentDetailController.modalView.show();

            var entity;
            var user = edsApp.model.custom.user;

            if (paymentDetailController.groupId)
                entity = paymentDetailController.groupId + "-|-Group";
            else
                entity = user.id + "-|-User";

            var requestArgs = {entity: entity, userId: user.id, subscriptionId: paymentDetailController.subscription.subscriptionId};

            edsApp.model.deleteDataAtURL("payment", requestArgs, function (success, data) {
                if (success) {
                    paymentDetailController.modalView.hide();
                    edsApp.model.clearJSONDataCacheForURLBeginningWith("payment");
                    edsApp.controllers.app.pushController(edsApp.controllers.payment, true, {group_id: paymentDetailController.groupId});

                } else {
                    //Show the appropriate error message and a way to fix it.
                    paymentDetailController.displayPaymentError(data);
                }
            });
        });

        //Hookup view events to their respective "listeners" or "targets".
        this._view.getChildView("form").on("submit", function (e) {

            if (!paymentDetailController.formValidator.getFormStatus())
                return;

            var user = edsApp.model.custom.user;
            var entity;

            if (paymentDetailController.groupId)
                entity = paymentDetailController.groupId + "-|-Group";
            else
                entity = user.id + "-|-User";


            var requestArgs = {
                entity: entity,
                userId: user.id,
                accountNumber: paymentDetailController._view.getChildView("cc_number").val(),
                expirationMonth: paymentDetailController._view.getChildView("cc_month").val(),
                expirationYear: paymentDetailController._view.getChildView("cc_year").val(),
                ccv: paymentDetailController._view.getChildView("cc_cvc").val(),
                firstName: paymentDetailController._view.getChildView("first_name").val(),
                lastName: paymentDetailController._view.getChildView("last_name").val(),
                street1: paymentDetailController._view.getChildView("address").val(),
                city: paymentDetailController._view.getChildView("city").val(),
                state: paymentDetailController._view.getChildView("state").val(),
                postalCode: paymentDetailController._view.getChildView("zip").val(),
                country: paymentDetailController._view.getChildView("country").val().toUpperCase(),
                phoneNumber: paymentDetailController._view.getChildView("phone").val(),
                email: user.emails[0]
            };

            var requestFn;

            //We need to decide whether we are going to PUT or POST.
            if (paymentDetailController.subscription) {
                requestArgs.subscriptionId = paymentDetailController.subscription.subscriptionId;
                requestFn = edsApp.model.putDataToURL;
            } else {
                requestFn = edsApp.model.postDataToURL;
            }

            paymentDetailController.modalView.removeAllButtons();
            paymentDetailController.modalView.setTitle(edsApp.model.getLocalizedString("please_wait"));
            paymentDetailController.modalView.setMessage("");
            paymentDetailController.modalView.showProgressBar(["progress-bar progress-bar-primary progress-bar-striped active"]);
            paymentDetailController.modalView.show();

            requestFn("payment", requestArgs, function (success, data) {

                if (success) {

                    var onSuccessFn = function () {
                        paymentDetailController.modalView.hide();
                        edsApp.model.clearJSONDataCacheForURLBeginningWith("payment");
                        edsApp.controllers.app.pushController(edsApp.controllers.payment, true, {group_id: paymentDetailController.groupId});
                    };

                    if (paymentDetailController.secureCallback) {
                        paymentDetailController.secureCallback(["groups"], {}, function () {
                            onSuccessFn();
                        });
                    } else {
                        onSuccessFn();
                    }

                } else {
                    //Show the appropriate error message and a way to fix it.
                    paymentDetailController.displayPaymentError(data);
                }
            });
        });
    };

    displayPaymentError(payment_error) {

        var paymentDetailController = this;

        //Reset the modal view.
        paymentDetailController.modalView.removeAllButtons();
        paymentDetailController.modalView.setTitle(edsApp.model.getLocalizedString("error_title"));
        paymentDetailController.modalView.hideProgressBar();

        if (payment_error && payment_error.message) {

            //See if there is a CYBS code we can extract.
            if (payment_error.code && payment_error.code.indexOf("CYBS") === 0) {

                if (_.contains(["CYBS_200", "CYBS_450", "CYBS_451", "CYBS_452", "CYBS_453", "CYBS_454", "CYBS_455", "CYBS_456", "CYBS_458", "CYBS_459", "CYBS_460"], payment_error.code)) {

                    paymentDetailController.modalView.setMessage(payment_error.message);

                    paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("fix"), ["btn-danger"], false, function () {
                       paymentDetailController.modalView.hide();
                       paymentDetailController._view.getChildView("address").focus();
                    });

                } else if (_.contains(["CYBS_200", "CYBS_205", "CYBS_208", "CYBS_231", "CYBS_232", "CYBS_240"], payment_error.code)) {

                    paymentDetailController.modalView.setMessage(payment_error.message);

                    paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("fix"), ["btn-danger"], false, function () {
                       paymentDetailController.modalView.hide();
                       paymentDetailController._view.getChildView("cc_number").focus();
                    });

                } else if (_.contains(["CYBS_209", "CYBS_211", "CYBS_230"], payment_error.code)) {

                    paymentDetailController.modalView.setMessage(payment_error.message);

                    paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("fix"), ["btn-danger"], false, function () {
                       paymentDetailController.modalView.hide();
                       paymentDetailController._view.getChildView("cc_cvc").focus();
                    });

                } else if (_.contains(["CYBS_202"], payment_error.code)) {

                    paymentDetailController.modalView.setMessage(payment_error.message);

                    paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("fix"), ["btn-danger"], false, function () {
                       paymentDetailController.modalView.hide();
                       paymentDetailController._view.getChildView("cc_month").focus();
                    });

                } else if (_.contains(["CYBS_457"], payment_error.code)) {

                    paymentDetailController.modalView.setMessage(payment_error.message);

                    paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("fix"), ["btn-danger"], false, function () {
                       paymentDetailController.modalView.hide();
                       paymentDetailController._view.getChildView("zip").focus();
                    });

                } else {
                    //Display just the message and a cancel button.
                    paymentDetailController.modalView.setMessage(payment_error.message);

                    paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("cancel"), ["btn-danger"], false, function () {
                       paymentDetailController.modalView.hide();
                    });
                }

            } else {
                //Display just the message and a cancel button.
                paymentDetailController.modalView.setMessage(payment_error.message);

                paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("cancel"), ["btn-danger"], false, function () {
                   paymentDetailController.modalView.hide();
                });
            }

        } else {
            //Display a generic message.
            paymentDetailController.modalView.setMessage(edsApp.model.getLocalizedString("error"));

            paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("cancel"), ["btn-danger"], false, function () {
               paymentDetailController.modalView.hide();
            });
        }
    }

    updateStateField() {
        var paymentDetailController = this;
        var currentCountry = this._view.getChildView("country").val();
        var $stateContainer = this._view.getChildView("state_container");

        if (_.has(this.states, currentCountry)) {

            this.stateSelectFieldDomObject.empty();
            var $option = $("<option></option>");
            _.each(this.states[currentCountry], function (value, key) {
                paymentDetailController.stateSelectFieldDomObject.append($option.clone().text(value).val(key));
            });

            this.stateTextFieldDomObject.detach();

            if (_.has(this.states[currentCountry], this.stateTextFieldDomObject.val().toUpperCase()))
            {
                this.stateSelectFieldDomObject.val(this.stateTextFieldDomObject.val());
            }

            $stateContainer.append(this.stateSelectFieldDomObject);

        } else {
            this.stateSelectFieldDomObject.detach();
            $stateContainer.append(this.stateTextFieldDomObject);
        }
    }

    updateCreditCardIcon() {
        var ccNumber = this._view.getChildView("cc_number").val();
        var $icon = this._view.getChildView("cc_icon");

        var visaVendor = ajcc.getVisaCardVendor("/media/credit_cards/visa.png");
        var masterCardVendor = ajcc.getMasterCardCardVendor("/media/credit_cards/master_card.png");
        var amexVendor = ajcc.getAmexCardVendor("/media/credit_cards/amex.png");

        if (visaVendor.validator(ccNumber)) {
            $icon.attr("src", visaVendor.iconURL).attr("alt", "Visa");
        } else if (masterCardVendor.validator(ccNumber)) {
            $icon.attr("src", masterCardVendor.iconURL).attr("alt", "Master Card");
        } else if (amexVendor.validator(ccNumber)) {
            $icon.attr("src", amexVendor.iconURL).attr("alt", "American Express");
        } else {
            $icon.attr("src", "/media/credit_cards/generic_card.png").attr("alt", "");
        }
    }

    updateFields() {

        this._view.getChildView("first_name").val(this.subscription.firstName);
        this._view.getChildView("last_name").val(this.subscription.lastName);
        this._view.getChildView("address").val(this.subscription.street1);
        this._view.getChildView("city").val(this.subscription.city);
        this._view.getChildView("zip").val(this.subscription.postalCode);
        this._view.getChildView("country").val(this.subscription.country.toLowerCase());
        this._view.getChildView("phone").val(this.subscription.phoneNumber);

        this.updateStateField();

        this._view.getChildView("state").val(this.subscription.state);

        var ccNumber = this.subscription.cardFirstTwo + " ";

        for (var i=0; i < 10; i++) {
            ccNumber += "\u00B7 "; //Middle dot.
        }

        ccNumber += this.subscription.cardLastFour;

        this._view.getChildView("cc_number").val(ccNumber);

        this.updateCreditCardIcon();

        this._view.getChildView("cvc").val("");
        this._view.getChildView("cc_month").val(parseInt(this.subscription.cardExpirationMonth));
        this._view.getChildView("cc_year").val(parseInt(this.subscription.cardExpirationYear));
    }

    willBecomeKeyController(a) {

        var paymentDetailController = this;

        //Call the superclass.
        super.willBecomeKeyController(a);

        if (a.group_id)
            this.groupId = a.group_id.toString();
        else
            this.groupId = null;

        var user = edsApp.model.custom.user;

        this._view.getChildView("spinner").show();
        this._view.getChildView("form").hide();
        this._view.getChildView("save").hide();
        this._view.getChildView("edit").hide();
        this.modalView.hide();

        this.paymentTokenData = null;
        this.subscription = null;

        //Empty all fields.
        paymentDetailController._view.getChildView("form").find("input").val("");

        //Set the default country.
        this._view.getChildView("language").find("option").prop("selected", false);
        this._view.getChildView("country").find("option").prop("selected", false);
        this._view.getChildView("country").find("option[value='" + user.countryId + "']").prop("selected", true);

        //Set the default phone number
        this._view.getChildView("phone").val(user.phoneNumber ? user.phoneNumber : "");

        var nFunctions = 3;
        var error = null;

        var initializePage = _.after(nFunctions, function () {

            paymentDetailController._view.getChildView("spinner").hide();
            paymentDetailController.modalView.removeAllButtons();
            paymentDetailController.modalView.hideProgressBar(false);

            if (error) {

                var errorString;
                if (error.description)
                    errorString = error.description;
                if (error.message)
                    errorString = error.message;
                else
                    errorString = edsApp.model.getLocalizedString("error");

                paymentDetailController.modalView.setTitle(edsApp.model.getLocalizedString("error_title"), false);
                paymentDetailController.modalView.setMessage(errorString, false);

                paymentDetailController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("retry"), ["btn-danger"], false, function(e) {

                    e.preventDefault();
                    edsApp.controllers.app.pushController(paymentDetailController, false, {group_id: a.group_id});
                });

                paymentDetailController.modalView.show();

            } else {

                paymentDetailController._view.getChildView("form").show();

                //Load the states.
                paymentDetailController.updateStateField();

                //Load payment token, if any.

                paymentDetailController.subscription = _.findWhere(paymentDetailController.paymentTokenData.data.subscriptions, {"subscriptionId": a.subscription_id});

                if (paymentDetailController.subscription) {
                    paymentDetailController.updateFields();

                    paymentDetailController._view.getChildView("form").find("input,select").prop("disabled", true);
                    paymentDetailController.stateSelectFieldDomObject.prop("disabled", true);
                    paymentDetailController.stateTextFieldDomObject.prop("disabled", true);
                    paymentDetailController._view.getChildView("save").hide();
                    paymentDetailController._view.getChildView("edit").hide(); //Editing is disabled for now.
                    paymentDetailController._view.getChildView("remove").show();

                } else {
                    paymentDetailController._view.getChildView("form").find("input,select").prop("disabled", false);
                    paymentDetailController.stateSelectFieldDomObject.prop("disabled", false);
                    paymentDetailController.stateTextFieldDomObject.prop("disabled", false);
                    paymentDetailController._view.getChildView("save").show();
                    paymentDetailController._view.getChildView("edit").hide();
                    paymentDetailController._view.getChildView("remove").hide();
                }
            }
        });


        //Load all the states.
        edsApp.model.getJSONDataForURL("/media/states.json", {}, 86400, function (success, data) {

            if (success) {

                paymentDetailController.states = data;

            } else {
                error = data || true;
            }

            initializePage();

        }, true, {});

        //Load the ajcc.js script for displaying the credit card.
        edsApp.model.getScriptForURL("/js/ajcc.js", {}, function (ajccSuccess, script) {

            if (!ajccSuccess) {
                error = script || true;
            }

            initializePage();
        });


        //Get the payment token summary.
        var requestArgs = {};

        if (a.group_id)
            requestArgs.entity = a.group_id + "-|-Group";
        else
            requestArgs.entity = user.id + "-|-User";

        edsApp.model.getJSONDataForURL("payment", requestArgs, 100, function (success, data) {

            if (!success)
                error = data || true;

            paymentDetailController.paymentTokenData = data;

            initializePage();
        });

        //Setup secure callbacks.
        if (a.secureCallback)
            this.secureCallback = edsApp.utilities.custom.setupSecureCallback();
        else
            this.secureCallback = null;

        //Reset the rest of the form.
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    };

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }
};