edsApp.classes.controllers.custom.NewDomainController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "new_domain";
        this.requiresLogin = true;
        this.formValidator = null;
        this.filename = null;
        this.domain = null;
        this.groupId = null;
        this.helpLink = "link_domain";
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.modalView = new edsApp.classes.views.ModalView(this._view);
        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Add all foreign providers to the select element.
        var $option = $("<option></option>");
        var $select = this._view.getChildView("login_provider");

        _.each(edsApp.model.custom.foreignProviderNames, function (aProvider) {

            $select.append($option.clone().val(aProvider).text(edsApp.model.getLocalizedString(aProvider)));
        });

        _.each(edsApp.model.custom.privateForeignProviders, function (value, key) {

            $select.append($option.clone().val(key).text(edsApp.model.getLocalizedString(key)));
        });

        this._view.getChildView("redirect_uri").text(edsApp.utilities.custom.generateForeignProviderRedirectURL());

        //Hookup view events to their respective "listeners" or "targets".
        var newDomainController = this;

        this._view.getChildView("checkbox").on("change", function (e) {

            //Show the first step.
            if ($(this).prop("checked"))
                newDomainController._view.getChildView("form").slideDown();
            else
                newDomainController._view.getChildView("form").slideUp();
        });

        this._view.getChildView("verification_types").on("change", "input:radio", function (e) {

            newDomainController._view.getChildView("verification_types").find("[data-mca-verification]").slideUp();
            var name = newDomainController._view.getChildView("verification_types").find("input:radio:checked").val();
            newDomainController._view.getChildView("verification_types").find("[data-mca-verification='" + name + "']").slideDown();
        });

        this._view.getChildView("login_provider").on("change", function (e) {
            newDomainController.setupProviderInfoUI();
        });

        this._view.getChildView("domain").on("change keyup paste focus", function (e) {

            var domain = $(this).val() ? $(this).val() : $(this).attr("placeholder");
            var url = "http://" + encodeURIComponent(domain) + "/" + newDomainController.filename;
            newDomainController._view.getChildView("txt_file_url").text(url);
            newDomainController._view.getChildView("dns_record_step_4").text(edsApp.model.getLocalizedString("dns_record_step_4", {domain: domain}));
        });

        this._view.getChildView("unlink").on("click", async function (e) {

            e.preventDefault();
            var domain = newDomainController._view.getChildView("domain").val();

            newDomainController._view.getChildView("unlink").hide();
            newDomainController.modalView.removeAllButtons();
            newDomainController.modalView.setTitle(edsApp.model.getLocalizedString("unlink_domain"));
            newDomainController.modalView.setMessage(edsApp.model.getLocalizedString("unlinking_domain", {domain: domain}));
            newDomainController.modalView.showProgressBar(["progress-bar progress-bar-primary progress-bar-striped active"]);
            newDomainController.modalView.show();
    
            try {
                var response = await edsApp.model.fetchAsync("domain", {
                    method: "DELETE",
                    edsUrlParameters: {domain_id: domain}
                });
    
                if (!response.ok) {
                    var error = await response.json();
                    throw new Error(edsApp.model.getLocalizedError(error));
                }

                var domainExists = true;

                //We check periodically if the domain still exists.
                do {
                    await edsApp.utilities.delayAsync(5000);
            
                    try {
                        var response = await edsApp.model.fetchAsync("domain", {
                            edsUrlParameters: {group_id: newDomainController.groupId},
                            edsDataCacheTime: 0 //Note no caching.
                        }); 

                        var existingDomains = await response.json();
            
                        if (!response.ok) {
                            throw new Error(edsApp.model.getLocalizedError(existingDomains));
                        }

                        domainExists = !!_.findWhere(existingDomains, {domain: domain});
                        edsApp.model.clearJSONDataCacheForURLBeginningWith("domain");
                        
                    } catch (e) {
                        //We don't prevent transient error from stopping the loop.
                        console.log("Error deleting domain:");
                        console.error(e);
                    }
                    
                } while (domainExists);

                newDomainController.modalView.hide();
                var message = edsApp.model.getLocalizedString("unlinking_domain_success_message", {domain: domain});
                edsApp.controllers.app.pushController(edsApp.controllers.group_detail, true, {successMessage: message, group_id: newDomainController.groupId});

            } catch (e) {
                newDomainController.modalView.hideProgressBar();
                newDomainController.modalView.setTitle(edsApp.model.getLocalizedString("error_generic"));
                newDomainController.modalView.setMessage(e.message);

                newDomainController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("cancel"), ["btn-default"], false, function () {
                    newDomainController._view.getChildView("unlink").show();
                    newDomainController.modalView.hide();
                });
            }
        });

        this._view.getChildView("form").on("submit", async function (e) {

            e.preventDefault();

            if (!newDomainController.formValidator.getFormStatus())
                return;

            newDomainController._view.getChildView("save").hide();
            newDomainController.modalView.removeAllButtons();
            newDomainController.modalView.setTitle(edsApp.model.getLocalizedString("link_new_domain"));
            newDomainController.modalView.setMessage(edsApp.model.getLocalizedString("linking_domain", {domain: newDomainController._view.getChildView("domain").val()}));
            newDomainController.modalView.showProgressBar(["progress-bar progress-bar-primary progress-bar-striped active"]);
            newDomainController.modalView.show();

            var networkArgs = {domain: newDomainController._view.getChildView("domain").val(),
                               group_id: newDomainController.groupId,
                               loginProviderInfo: null,
                               verification: newDomainController._view.getChildView("verification_types").find("input:radio:checked").val(),
                               loginProviderName: newDomainController._view.getChildView("login_provider").val()};

            if (!_.contains(edsApp.model.custom.foreignProviderNames, networkArgs.loginProviderName)) {
                networkArgs.loginProviderInfo = {
                    clientId: newDomainController._view.getChildView("client_id").val(),
                    endpointURL: newDomainController._view.getChildView("endpoint_url").val()
                }
            }

            try
            {
                var response = await edsApp.model.fetchAsync("domain", {
                    method: "POST",
                    body: JSON.stringify(networkArgs)
                });

                var domain = await response.json(); 

                if (!response.ok) {
                    throw new Error(edsApp.model.getLocalizedError(domain));
                }

                edsApp.model.clearJSONDataCacheForURLBeginningWith("domain");
                newDomainController.modalView.hide();
                var message = edsApp.model.getLocalizedString("new_domain_success", {domain: domain.domain, group: domain.group.name});
                edsApp.controllers.app.pushController(edsApp.controllers.group_detail, true, {successMessage: message, group_id: newDomainController.groupId});
                

            } catch (e) {
                newDomainController.modalView.hideProgressBar();
                newDomainController.modalView.setTitle(edsApp.model.getLocalizedString("error_generic"));
                newDomainController.modalView.setMessage(e.message);

                newDomainController.modalView.setPrimaryButton(edsApp.model.getLocalizedString("cancel"), ["btn-default"], false, function () {
                    newDomainController._view.getChildView("save").show();
                    newDomainController.modalView.hide();
                });
            }
        });
    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        var user = edsApp.model.custom.user;
        return (a.group_id && _.findWhere(user.memberGroups.concat(user.adminGroups).concat(user.ownerGroups), {id: a.group_id.toString()}));
    };

    async willBecomeKeyController (a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.groupId = a.group_id.toString();

        this.modalView.hide();
        this._view.getChildView("alert").hide();
        this._view.getChildView("save").show();
        this._view.getChildView("unlink").hide();
        this._view.getChildView("form").hide();
        this._view.getChildView("verification_types").find("[data-mca-verification]").hide();

        var user = edsApp.model.custom.user;
        var group = _.findWhere(user.memberGroups.concat(user.adminGroups).concat(user.ownerGroups), {id: a.group_id.toString()});

        this._view.getChildView("new_domain_message").text(edsApp.model.getLocalizedString("new_domain_message", {group: group.name}));
        this._view.getChildView("checkbox_label").text(edsApp.model.getLocalizedString("new_domain_checkbox_label", {group: group.name}));
        this._view.getChildView("checkbox_container").hide();

        this._view.getChildView("checkbox").prop("checked", false);
        this._view.getChildView("checkbox").prop("disabled", false);
        this._view.getChildView("login_provider").prop("disabled", false);
        this._view.getChildView("verification_types").find("input:radio").prop("checked", false);
        this._view.getChildView("verification_types").find("input:radio").prop("disabled", false);
        this._view.getChildView("endpoint_url").prop("readonly", false);
        this._view.getChildView("client_id").prop("readonly", false);

        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");

        this._view.getChildView("domain").val("");
        var existingDomains = [];
        var domainVerificationResult = {};

        var responsePromise = edsApp.model.fetchAsync("domain", {
            edsUrlParameters: {group_id: a.group_id}, 
            edsDataCacheTime: 100 
        });

        var domainVerificationPromise = edsApp.model.fetchAsync("domain-verification", {
            method: "POST",
            body: JSON.stringify({group_id: a.group_id})
        });

        try {
            var response = await responsePromise;
            var domainVerificationResponse = await domainVerificationPromise;
            existingDomains = await response.json();
            domainVerificationResult = await domainVerificationResponse.json();

            if (!response.ok) {
                throw new Error(edsApp.model.getLocalizedError(existingDomains));
            } else if (!domainVerificationResponse.ok) {
                throw new Error(edsApp.model.getLocalizedError(domainVerificationResult));
            }
            
        } catch (e) {
            this._view.getChildView("alert_message").text(e.message);
            this._view.getChildView("alert").slideDown();
        }

        var domain = a.domain ? _.findWhere(existingDomains, {domain: a.domain.toLowerCase()}) : undefined;
        this.domain = domain ? domain.domain : null;
        this.filename = domainVerificationResult.filename;

        if (domain) {
            this._view.getChildView("checkbox").prop("checked", true);
            this._view.getChildView("checkbox").prop("disabled", true);
            this._view.getChildView("domain").val(domain.domain).prop("readonly", true);
            this._view.getChildView("verification_types").find("input:radio").prop("disabled", true);
            this._view.getChildView("login_provider").prop("disabled", true);
            this._view.getChildView("login_provider").val(domain.loginProviderName);
            this._view.getChildView("unlink").show();
            this._view.getChildView("save").hide();

            if (domain.loginProviderInfo) {
                var templateURL = edsApp.model.custom.privateForeignProviders[domain.loginProviderName];
                var prefixSuffix = this.getPrefixSuffixForTemplateURL(templateURL);
                var endpointURL = domain.loginProviderInfo.endpointURL.substring(prefixSuffix.prefix.length);
                endpointURL = endpointURL.substring(0, endpointURL.length - prefixSuffix.suffix.length);

                this._view.getChildView("endpoint_url").val(endpointURL);
                this._view.getChildView("endpoint_url").prop("readonly", true);
                this._view.getChildView("client_id").val(domain.loginProviderInfo.clientId);
                this._view.getChildView("client_id").prop("readonly", true);

            }

            this._view.getChildView("save").hide();
            this._view.getChildView("form").slideDown();
        }

        var url = "http://" + encodeURIComponent(domain ? domain.domain : this._view.getChildView("domain").attr("placeholder")) + "/" + domainVerificationResult.filename;
        this._view.getChildView("checkbox_container").show();
        this._view.getChildView("txt_file_url").text(url);
        this._view.getChildView("dns_record_step_4").text(edsApp.model.getLocalizedString("dns_record_step_4", {domain: domain ? domain.domain : this._view.getChildView("domain").attr("placeholder")}));
        this._view.getChildView("dns_verification_record").text(domainVerificationResult.dnsVerificationRecord);
        this._view.getChildView("download_file").text(edsApp.model.getLocalizedString("download_file", {name: domainVerificationResult.filename}));
        this._view.getChildView("download_file").attr("download", domainVerificationResult.filename);
        this._view.getChildView("download_file").attr("href", "data:text/plain;base64," + window.btoa(domainVerificationResult.token));
        this.setupProviderInfoUI();
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    }

    setupProviderInfoUI() {
        var chosenProviderName = this._view.getChildView("login_provider").val();

        var $clientId = this._view.getChildView("client_id");
        var $endpointURL = this._view.getChildView("endpoint_url");

        if (_.contains(edsApp.model.custom.foreignProviderNames, chosenProviderName)) {
            $clientId.prop("required", false);
            $endpointURL.prop("required", false);
            this._view.getChildView("provider_info").slideUp();
        } else {
            $clientId.prop("required", true);
            $endpointURL.prop("required", true);

            //Setup the endpoint url input group.
            var templateURL = edsApp.model.custom.privateForeignProviders[chosenProviderName];
            var result = this.getPrefixSuffixForTemplateURL(templateURL);

            this._view.getChildView("endpoint_prefix").text(result.prefix);
            this._view.getChildView("endpoint_suffix").text(result.suffix);

            //Update the help link button.
            this._view.getChildView("domain_help").attr("href", "/help#link_domain_" + chosenProviderName);

            this._view.getChildView("provider_info").slideDown();
        }

        this.formValidator.reloadFormStatus();
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    }

    getPrefixSuffixForTemplateURL(templateURL) {
        var startIndex = templateURL.indexOf("{0}");
        var endIndex = startIndex + 3;
        var prefix = templateURL.substring(0, startIndex);
        var suffix = templateURL.substring(endIndex);

        return {prefix: prefix, suffix: suffix};
    }

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }
};
