edsApp.classes.controllers.custom.TwoFactorSettingsController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        //Define members.
        this.name = "two_factor_settings";
        this.requiresLogin = true;
        this.performingRequest = false;
        this.totp = null;
        this.otpTimestamp = null;
        this.textCodeTimestamp = null;
        this.phoneCodeTimestamp = null;
        this.qrcode = null;
        this.helpLink = "two_factor_auth";
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Hookup view events to their respective "listeners" or "targets".
        var twoFactorSettingsController = this;
        this._view.getChildView("totp_code").on("keyup", function (e) {
            //Update the otp timestamp.
            twoFactorSettingsController.otpTimestamp = new Date().getTime() / 1000.0;
        });

        this._view.getChildView("text_code").on("keyup", function (e) {

            //Update the text code timestamp.
            twoFactorSettingsController.textCodeTimestamp = new Date().getTime() / 1000.0;
        });

        this._view.getChildView("phone_code").on("keyup", function (e) {

            //Update the phone code timestamp.
            twoFactorSettingsController.phoneCodeTimestamp = new Date().getTime() / 1000.0;
        });

        this._view.getChildView("two_factor_policy").on("click", "input", function (e) {

            //Show or hide the two factor modes accordingly.
            var $clickedRadio = $(this);

            if ($clickedRadio.attr("data-mca-mode") == "off") {
                twoFactorSettingsController._view.getChildView("two_factor_methods").slideUp();

                twoFactorSettingsController._view.getChildView("two_factor_methods").find("input:checkbox").prop("checked", false);
                twoFactorSettingsController.showOrHideTOTPList(false);
                twoFactorSettingsController.showOrHideCodeContainers(false);
            } else {
                twoFactorSettingsController.checkCheckboxes();
                twoFactorSettingsController._view.getChildView("two_factor_methods").slideDown();
            }
        });

        this._view.getChildView("two_factor_methods").on("click", "input:checkbox", function (e) {

            //Keep at least one checkbox checked at all times.
            twoFactorSettingsController.checkCheckboxes();
        });

        this._view.getChildView("save").on("click", function (e) {
            if (!twoFactorSettingsController.formValidator.getFormStatus() || twoFactorSettingsController.performingRequest)
                return;

            twoFactorSettingsController.performingRequest = true;
            twoFactorSettingsController._view.getChildView("save").hide();
            twoFactorSettingsController._view.getChildView("spinner").show();

            var user = edsApp.model.custom.user;
            var putArgs = {twoFactorPolicy: twoFactorSettingsController._view.getChildView("two_factor_policy").find("input:checked").attr("data-mca-mode")};


            if (putArgs.twoFactorPolicy != "off") {

                putArgs.twoFactorByEmail = twoFactorSettingsController._view.getChildView("two_factor_by_email").is(":checked");

                if (twoFactorSettingsController._view.getChildView("two_factor_by_text").is(":checked")) {
                    if (!user.twoFactorByText) {
                        putArgs.textCode = edsApp.utilities.custom.sanitizeTotpCode(twoFactorSettingsController._view.getChildView("text_code").val());
                        putArgs.textCodeTimestamp = twoFactorSettingsController.textCodeTimestamp;
                    }

                } else {
                    putArgs.textCode = null;
                }

                if (twoFactorSettingsController._view.getChildView("two_factor_by_phone").is(":checked")) {

                    if (!user.twoFactorByPhone) {
                        putArgs.phoneCode = edsApp.utilities.custom.sanitizeTotpCode(twoFactorSettingsController._view.getChildView("phone_code").val());
                        putArgs.phoneCodeTimestamp = twoFactorSettingsController.phoneCodeTimestamp;
                    }

                } else {
                    putArgs.phoneCode = null;
                }

                if (twoFactorSettingsController._view.getChildView("two_factor_by_totp").is(":checked")) {

                    if (!user.twoFactorByTOTP) {
                        putArgs.otpSecret = twoFactorSettingsController.totp.secret;
                        putArgs.otpCode = edsApp.utilities.custom.sanitizeTotpCode(twoFactorSettingsController._view.getChildView("totp_code").val());
                        putArgs.otpTimestamp = twoFactorSettingsController.otpTimestamp;
                    }

                } else {
                    putArgs.otpSecret = null;
                }
            }

            edsApp.model.putDataToURL("user", putArgs, function (success, data) {

                if (success) {
                    edsApp.model.custom.user = data;
                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("two_factor_success")});
                } else {

                    twoFactorSettingsController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    twoFactorSettingsController._view.getChildView("alert").slideDown();
                    twoFactorSettingsController._view.getChildView("save").show();
                    twoFactorSettingsController._view.getChildView("spinner").hide();
                }

                twoFactorSettingsController.performingRequest = false;
            });
        });
    };

    willBecomeKeyController(a) {

        var twoFactorSettingsController = this;

        //Call the superclass.
        super.willBecomeKeyController(a);

        var user = edsApp.model.custom.user;
        var twoFactorPolicy = user.twoFactorPolicy;

        //Load the QR Code library and generate a QR code.
        this.updateQRCodeUI(user.emails[0]);

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("save").show();


        this.styleCheckboxes();

        if (twoFactorPolicy == "off") {

            this._view.getChildView("off").prop("checked", true);
            this._view.getChildView("two_factor_methods").hide();

            this._view.getChildView("two_factor_methods").find("input:checkbox").prop("checked", false);
            this.showOrHideTOTPList(false);
            this.showOrHideCodeContainers(false);
        } else {

            if (twoFactorPolicy == "once") {
                this._view.getChildView("once").prop("checked", true);
            } else {
                this._view.getChildView("always").prop("checked", true);
            }

            this._view.getChildView("two_factor_by_email").prop("checked", user.twoFactorByEmail);
            this._view.getChildView("two_factor_by_text").prop("checked", user.twoFactorByText);
            this._view.getChildView("two_factor_by_phone").prop("checked", user.twoFactorByPhone);
            this._view.getChildView("two_factor_by_totp").prop("checked", user.twoFactorByTOTP);

            this.checkCheckboxes();
            this._view.getChildView("two_factor_methods").show();
        }

        //Reset the form.
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this.formValidator.reloadFormStatus();
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    };

    updateQRCodeUI(email) {

        var twoFactorSettingsController = this;

        //Load the QR Code library.
        edsApp.model.getScriptForURL("/js/min/qrcode.min.js", null, function (success) {

            if (success) {

                //Request a TOTP.
                edsApp.model.postDataToURL("totp", {"email": email}, function (success, data) {

                    if (success) {
                        twoFactorSettingsController.totp = data;

                        //Render the TOTP as a QR code.
                        if (twoFactorSettingsController.qrcode) {
                            twoFactorSettingsController.qrcode.clear();
                            twoFactorSettingsController.qrcode.makeCode(data.uri);
                        } else {
                            twoFactorSettingsController.qrcode = new QRCode("two_factor_settings_qr_code", {
                                text: data.uri,
                                width: 192,
                                height: 192
                            });
                        }

                        //Update the base32 encoded secret for manual entry by adding spaces every 4 chars.
                        var b32secret = "";

                        for (var i = 0, len = data.b32secret.length; i < len; i++) {

                            if (i > 0 && i % 4 == 0)
                                b32secret += " ";

                            b32secret += data.b32secret[i];
                        }

                        twoFactorSettingsController._view.getChildView("totp_secret").text(b32secret);

                        //Clear the currently typed code, if any.
                        twoFactorSettingsController._view.getChildView("totp_code").val("");
                    }
                });
            }
        });
    }

    showOrHideCodeContainers(animated) {

        var user = edsApp.model.custom.user;

        if (this._view.getChildView("two_factor_by_text").is(":checked") && !user.twoFactorByText) {

            //Only show the input field if it is not already enabled.
            if (this._view.getChildView("text_code_container").is(":hidden")) {
                //Send a code.
                this.sendTextCode();
            }

            if (animated)
                this._view.getChildView("text_code_container").slideDown();
            else
                this._view.getChildView("text_code_container").show();

            this._view.getChildView("text_code").attr("data-eds-validator", "!isNaN(edsApp.utilities.custom.sanitizeTotpCode($('#two_factor_settings_text_code').val()))");
        } else {

            if (animated)
                this._view.getChildView("text_code_container").slideUp();
            else
                this._view.getChildView("text_code_container").hide();

            this._view.getChildView("text_code").attr("data-eds-validator", "true");
        }

        if (this._view.getChildView("two_factor_by_phone").is(":checked") && !user.twoFactorByPhone) {

            //Only show the input field if it is not already enabled.

            if (this._view.getChildView("phone_code_container").is(":hidden")) {
                //Send a code.
                this.sendPhoneCode();
            }

            if (animated)
                this._view.getChildView("phone_code_container").slideDown();
            else
                this._view.getChildView("phone_code_container").show();

            this._view.getChildView("phone_code").attr("data-eds-validator", "!isNaN(edsApp.utilities.custom.sanitizeTotpCode($('#two_factor_settings_phone_code').val()))");
        } else {

            if (animated)
                this._view.getChildView("phone_code_container").slideUp();
            else
                this._view.getChildView("phone_code_container").hide();

            this._view.getChildView("phone_code").attr("data-eds-validator", "true");
        }

        this.formValidator.reloadFormStatus();
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    }

    showOrHideTOTPList(animated) {

        var user = edsApp.model.custom.user;

        if (this._view.getChildView("two_factor_by_totp").is(":checked") && !user.twoFactorByTOTP) {

            //Only show the list if it is not already enabled.

            if (animated)
                this._view.getChildView("totp_list").slideDown();
            else
                this._view.getChildView("totp_list").show();

            this._view.getChildView("totp_code").attr("data-eds-validator", "!isNaN(edsApp.utilities.custom.sanitizeTotpCode($('#two_factor_settings_totp_code').val()))");
        } else {

            if (animated)
                this._view.getChildView("totp_list").slideUp();
            else
                this._view.getChildView("totp_list").hide();

            this._view.getChildView("totp_code").attr("data-eds-validator", "true");
        }

        this.formValidator.reloadFormStatus();
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    }

    styleCheckboxes() {

        var user = edsApp.model.custom.user;
        var email = user.emails[0];
        var phoneNumber = user.phoneNumber;

        //Enable, disable two factor method checkboxes as needed.
        var emailContainer = this._view.getChildView("two_factor_by_email_container");
        var textContainer = this._view.getChildView("two_factor_by_text_container");
        var phoneContainer = this._view.getChildView("two_factor_by_phone_container");
        var emailCheckbox = emailContainer.getChildView("two_factor_by_email");
        var emailCheckboxText = emailContainer.getChildView("two_factor_by_email_text");
        var textCheckbox = textContainer.getChildView("two_factor_by_text");
        var textCheckboxText = textContainer.getChildView("two_factor_by_text_text");
        var phoneCheckbox = phoneContainer.getChildView("two_factor_by_phone");
        var phoneCheckboxText = phoneContainer.getChildView("two_factor_by_phone_text");

        //Remove all disabled classes for starters.
        emailContainer.removeClass("edsAppLightGrayColor").removeClass("disabled");
        textContainer.removeClass("edsAppLightGrayColor").removeClass("disabled");
        phoneContainer.removeClass("edsAppLightGrayColor").removeClass("disabled");
        emailCheckbox.prop("disabled", false);
        textCheckbox.prop("disabled", false);
        phoneCheckbox.prop("disabled", false);

        if (email) {
            emailCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_email", {email: email}));
        } else {
            emailContainer.addClass("edsAppLightGrayColor").addClass("disabled");
            emailCheckbox.prop("disabled", true);
            emailCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_email", {email: "???"}));
        }

        if (phoneNumber) {
            textCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_text", {number: phoneNumber}));
            phoneCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_phone", {number: phoneNumber}));
            this._view.getChildView("add_phone_number").text(edsApp.model.getLocalizedString("edit_phone_number"));

            var textTitle = edsApp.model.getLocalizedString("input_text_code", {number: phoneNumber});
            textContainer.getChildView("text_code_title").text(textTitle);
            textContainer.getChildView("text_code").attr("aria-label", textTitle);

            var phoneTitle = edsApp.model.getLocalizedString("input_phone_code", {number: phoneNumber});
            phoneContainer.getChildView("phone_code_title").text(phoneTitle);
            phoneContainer.getChildView("phone_code").attr("aria-label", phoneTitle);

        } else {
            textContainer.addClass("edsAppLightGrayColor").addClass("disabled");
            phoneContainer.addClass("edsAppLightGrayColor").addClass("disabled");
            textCheckbox.prop("disabled", true);
            phoneCheckbox.prop("disabled", true);
            textCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_text", {number: "???"}));
            phoneCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_phone", {number: "???"}));
            this._view.getChildView("add_phone_number").text(edsApp.model.getLocalizedString("add_phone_number"));
        }
    }

    checkCheckboxes() {

        var user = edsApp.model.custom.user;
        var email = user.emails[0];
        var phoneNumber = user.phoneNumber;

        var emailContainer = this._view.getChildView("two_factor_by_email_container");
        var textContainer = this._view.getChildView("two_factor_by_text_container");
        var phoneContainer = this._view.getChildView("two_factor_by_phone_container");
        var totpContainer = this._view.getChildView("two_factor_by_totp_container");
        var emailCheckbox = emailContainer.getChildView("two_factor_by_email");
        var textCheckbox = textContainer.getChildView("two_factor_by_text");
        var phoneCheckbox = phoneContainer.getChildView("two_factor_by_phone");
        var totpCheckbox = totpContainer.getChildView("two_factor_by_totp");

        if (!email) {
            emailCheckbox.prop("checked", false);
        }

        if (!phoneNumber) {
            textCheckbox.prop("checked", false);
            phoneCheckbox.prop("checked", false);
        }

        //Make sure there is at least one checkbox checked.
        if (!emailCheckbox.is(":checked") && !textCheckbox.is(":checked") && !phoneCheckbox.is(":checked") && !totpCheckbox.is(":checked")) {

            if (email) {
                emailCheckbox.prop("checked", true);
            } else if (phoneNumber) {
                textCheckbox.prop("checked", true);
            }  else if (this._view.getChildView("two_factor_checkbox").is(":checked")) {
                totpCheckbox.prop("checked", true);
            }
        }

        this.showOrHideTOTPList(true);
        this.showOrHideCodeContainers(true);
    }

    styleCheckboxesOLD() {
        //Enable, disable two factor method checkboxes as needed.
        var user = edsApp.model.custom.user;

        var textContainer = this._view.getChildView("two_factor_by_text_container");
        var phoneContainer = this._view.getChildView("two_factor_by_phone_container");
        var textCheckbox = textContainer.getChildView("two_factor_by_text");
        var textCheckboxText = textContainer.getChildView("two_factor_by_text_text");
        var phoneCheckbox = phoneContainer.getChildView("two_factor_by_phone");
        var phoneCheckboxText = phoneContainer.getChildView("two_factor_by_phone_text");

        //Remove all disabled classes for starters.
        textContainer.removeClass("edsAppLightGrayColor").removeClass("disabled");
        phoneContainer.removeClass("edsAppLightGrayColor").removeClass("disabled");
        textCheckbox.prop("disabled", false);
        phoneCheckbox.prop("disabled", false);

        if (user.phoneNumber) {
            textCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_text", {number: user.phoneNumber}));
            phoneCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_phone", {number: user.phoneNumber}));
            this._view.getChildView("add_phone_number").text(edsApp.model.getLocalizedString("edit_phone_number"));
        } else {
            textContainer.addClass("edsAppLightGrayColor").addClass("disabled");
            phoneContainer.addClass("edsAppLightGrayColor").addClass("disabled");
            textCheckbox.prop("disabled", true);
            phoneCheckbox.prop("disabled", true);
            textCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_text", {number: "???"}));
            phoneCheckboxText.text(edsApp.model.getLocalizedString("two_factor_by_phone", {number: "???"}));
            this._view.getChildView("add_phone_number").text(edsApp.model.getLocalizedString("add_phone_number"));
        }
    }

    sendTextCode() {
        this.sendCode("text_code");
    }

    sendPhoneCode() {
        this.sendCode("phone_code");
    }

    sendCode(url) {

        var action = url; // 'text_code' or 'phone_code'
        var user = edsApp.model.custom.user;
        var phoneNumber = user.phoneNumber;
        var countryId = user.countryId;

        var codeArgs = {
            "phone": phoneNumber,
            "countryId": countryId
        };

        var newUserCreateController = this;

        // perform recaptcha assessment
        edsApp.model.fetchScriptAsync("https://www.recaptcha.net/recaptcha/enterprise.js", {render: edsApp.model.custom.recaptchaSiteKey}).then(() =>
        {
            grecaptcha.enterprise.ready(function() {
                grecaptcha.enterprise.execute(edsApp.model.custom.recaptchaSiteKey, {action: action}).then(function(token) {
                    codeArgs["recaptchaToken"] = token;
                    edsApp.model.postDataToURL(url, codeArgs, function (success, data) {
    
                        if (!success) {
            
                            newUserCreateController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                            newUserCreateController._view.getChildView("alert").slideDown();
                        }
                    });
                });
            });
        });
    }

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }
};
