(async function () {
    /* We load the session variables and the JSON localized strings file, the two model pieces we need before we can
       instantiate any controllers. */

    //Deal with 401 errors by forcing the browser to refresh the page. The server will redirect them to the login
    //page where they may enter their credentials.
    var ajaxHandlers = {401: function (rawResponse, url) {window.location.reload(true);} };

    //We catch global errors and send them to the error page.
    window.addEventListener('error', function (errorEvent) {
        edsApp.utilities.custom.reportError(errorEvent);
    });

    var response = await edsApp.model.fetchAsync("/session_variables.json", {edsOmitAjaxPath: true});
    
    if (!response.ok)
        edsApp.utilities.custom.redirectToErrorPage(null, "Failed to load session variables.");
    
    var data = await response.json();

    edsApp.model.custom.languageId = data.languageId;
    edsApp.model.custom.countryId = data.countryId;
    edsApp.model.custom.user = data.user;
    edsApp.model.custom.sessionInfo = data.sessionInfo;
    edsApp.model.custom.foreignProviderNames = data.foreignProviderNames;
    edsApp.model.custom.privateForeignProviders = data.privateForeignProviders;
    edsApp.model.custom.googleAuth2ClientId = data.googleAuth2ClientId;
    edsApp.model.custom.facebookAuth2ClientId = data.facebookAuth2ClientId;
    edsApp.model.custom.foreignProviderRedirectPath = data.foreignProviderRedirectPath;
    edsApp.model.custom.facebookGraphApiURL = data.facebookGraphApiURL;
    edsApp.model.custom.recaptchaSiteKey = data.recaptchaSiteKey;

    edsApp.init({viewsURL: "/views/",
                    ajaxURL: "/",
                    ajaxHeaders: {"Mca-XSRF": edsApp.utilities.custom.getXSRFValue, "Mca-sub": data.user ? data.user.id : ""},
                    ajaxHandlers: ajaxHandlers,
                    localizedStrings: data.localizedStrings,
                    localizedLanguages: data.localizedLanguages,
                    localizedCountries: data.localizedCountries});
}());