edsApp.classes.controllers.custom.NewForeignAccountController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "new_foreign_account";
        this.performingRequest = false;
        this.requiresLogin = true;
        this.helpLink = "foreign_accounts";
    }

    setViewHTML(html) {

        var newForeignAccountController = this;

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);
        
        //Add buttons for all foreign providers.
        _.each(edsApp.model.custom.foreignProviderNames, function(providerName) {

            // hide facebook
            if (providerName === 'facebook') {
                return;
            }

            var linkAccountButtonDomObject = $('<div class="btn-group edsAppVerticalPadded" data-mca-provider="' + providerName + '" style="width: 100%;">' +
                            '<a class="' + edsApp.utilities.custom.getButtonStyleForProvider(providerName) +' disabled" href="#" style="width: 15%;"><img src="' + edsApp.utilities.custom.getImageURLForProvider(providerName) +'" style="width:16px; height:16px"/></a>' +
                            '<a class="' + edsApp.utilities.custom.getButtonStyleForProvider(providerName) + '" href=#" style="width: 85%;">' + _.escape(edsApp.model.getLocalizedString("link_account", {provider: edsApp.model.getLocalizedString(providerName)})) + '</a>' +
                        '</div><br>');

            newForeignAccountController._view.getChildView("button_container").append(linkAccountButtonDomObject);
        });
        
        //Hookup view events to their respective "listeners" or "targets".
        this._view.getChildView("button_container").find("[data-mca-provider]").on("click", function (e) {
            e.preventDefault();

            if (newForeignAccountController.performingRequest)
                return;

            newForeignAccountController.performingRequest = true;
            newForeignAccountController._view.find(".btn-group").hide();
            newForeignAccountController._view.getChildView("spinner").show();

            var selectedProvider = $(this).attr("data-mca-provider");

            edsApp.utilities.custom.loginWithForeignProvider(selectedProvider, undefined, undefined, function (success, providerName, providerToken, providerId, providerEmail, userName, userImageURL) {

                if (!success) {
                    newForeignAccountController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("foreign_provider_login_error", {provider: edsApp.model.getLocalizedString(selectedProvider)}) + (providerName ? " (" + providerName + ")" : ""));
                    newForeignAccountController._view.getChildView("alert").slideDown();
                    newForeignAccountController._view.find(".btn-group").show();
                    newForeignAccountController._view.getChildView("spinner").hide();
                    newForeignAccountController.performingRequest = false;
                    return;
                }

                var newForeignAccount = {providerToken: providerToken,
                                         providerName: providerName,
                                         providerId: providerId};

                var currentForeignAccounts = edsApp.model.custom.user.foreignAccounts.slice(); //Copies the array.
                currentForeignAccounts.push(newForeignAccount);
                var putArgs = {foreignAccounts: currentForeignAccounts};

                edsApp.model.putDataToURL("user", putArgs, function (putSuccess, data) {

                    if (putSuccess) {

                        edsApp.model.custom.user = data;
                        edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("new_foreign_account_success", {account: providerEmail, provider: edsApp.model.getLocalizedString(providerName)})});

                    } else {

                        newForeignAccountController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                        newForeignAccountController._view.getChildView("alert").slideDown();
                        newForeignAccountController._view.find(".btn-group").show();
                        newForeignAccountController._view.getChildView("spinner").hide();
                    }

                    newForeignAccountController.performingRequest = false;
                });
            });
        });
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
    };
};