edsApp.classes.controllers.custom.DeleteAccountController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();
        /* Do setup specific to this class. */
        //Define members.
        this.name = "delete_account";
        this.performingRequest = false;
        this.requiresLogin = true;
        this.helpLink = "delete_account";
        this.consentList = [];
    }

    setViewHTML(html) {
        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        var deleteAccountController = this;
        this._view.getChildView("delete").on("click", function (e) {

            e.preventDefault();

            if (deleteAccountController.performingRequest)
                return;

            deleteAccountController.performingRequest = true;
            deleteAccountController._view.getChildView("delete").hide();
            deleteAccountController._view.getChildView("keep").hide();
            deleteAccountController._view.getChildView("spinner").show();
            deleteAccountController._view.getChildView("alert").hide();

            edsApp.model.deleteDataAtURL("user", {}, function (success, data) {

                if (success) {
                    window.location.reload(true);
                } else {
                    deleteAccountController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    deleteAccountController._view.getChildView("alert").show();
                    deleteAccountController._view.getChildView("delete").show();
                    deleteAccountController._view.getChildView("keep").show();
                    deleteAccountController._view.getChildView("spinner").hide();
                }

                deleteAccountController.performingRequest = false;
            });
        });

        this._view.getChildView("next").on("click", function (e) {

            e.preventDefault();

            deleteAccountController.consentList.shift();

            if (deleteAccountController.consentList.length <= 1) {
                deleteAccountController._view.getChildView("delete").show();
                deleteAccountController._view.getChildView("next").hide();
            }

            deleteAccountController.loadConsentItem(deleteAccountController.consentList[0])
        });

        this._view.getChildView("checkbox").on("click", function (e) {

            if (deleteAccountController.consentList.length > 1) {
                deleteAccountController._view.getChildView("next").prop("disabled", !$(this).is(":checked"));
            } else {
                deleteAccountController._view.getChildView("delete").prop("disabled", !$(this).is(":checked"));
            }
        });
    }

    loadConsentItem(item) {

        this._view.getChildView("checkbox").prop("checked", false);
        this._view.getChildView("message").text(item.text);
        this._view.getChildView("next").prop("disabled", true);

        if (item.button && item.link) {

            this._view.getChildView("action").attr("href", item.link).text(item.button).attr("data-eds-link", item.local_link);
        } else {
            this._view.getChildView("action").hide();
        }
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        //Reset the consent list.
        this.consentList = [];

        this.consentList.push({
            text: edsApp.model.getLocalizedString("delete_account_email_warning", {emails: edsApp.model.custom.user.emails.join(", "), days: 45}),
            button: edsApp.model.getLocalizedString("edit_emails"),
            link: edsApp.utilities.createRelativeURLForController(edsApp.controllers.emails.name),
            local_link: true});

        this.consentList.push({
            text: edsApp.model.getLocalizedString("delete_account_licenses_warning"),
            button: edsApp.model.getLocalizedString("view_licenses"),
            link: "https://www.rhino3d.com/licenses?_forceEmpty=true",
            local_link: false});

        this.consentList.push({
            text: edsApp.model.getLocalizedString("delete_account_group_warning"),
            button: edsApp.model.getLocalizedString("view_groups"),
            link: edsApp.utilities.createRelativeURLForController(edsApp.controllers.groups.name),
            local_link: true});

        this.consentList.push({
            text: edsApp.model.getLocalizedString("delete_account_services_warning")});

        this.consentList.push({
            text: edsApp.model.getLocalizedString("delete_account_permanent_warning")});

        this.loadConsentItem(this.consentList[0]);

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("delete").prop("disabled", true).hide();
        this._view.getChildView("next").prop("disabled", true).show();
    };
};
