edsApp.classes.controllers.custom.DomainDecisionController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "domain_decision";
        this.loginArgs = null;
        this.domain = null;
        this.redirectControllerName = "home";
        this.redirectControllerArgs = {};
        this.performingRequest = false;
        this.requiresLogin = false;
        this.user = null;
        this.join = false;
    }


    setViewHTML(html) {

        var domainDecisionController = this;

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("email_password_container"), this, true, false);

        //Hookup view events to their respective "listeners" or "targets".
        this._view.getChildView("email_button").on("click", function (e) {
            e.preventDefault();

            domainDecisionController._view.getChildView("button_container").hide();
            domainDecisionController._view.getChildView("email_password_container").show();
            domainDecisionController._view.getChildView("save").prop("disabled", !domainDecisionController.formValidator.getFormStatus());
        });

        this._view.getChildView("password").on("change keyup paste focus", function (e) {

            var strength = edsApp.utilities.custom.passwordStrength($(this).val());
            var progressBarDomObject = domainDecisionController._view.getChildView("progress_bar");
            var progressBarTextDomObject = domainDecisionController._view.getChildView("progress_bar_text");

            progressBarDomObject.attr("aria-valuenow", strength);
            progressBarDomObject.css("width", strength + "%");
            progressBarDomObject.removeClass("progress-bar-danger").removeClass("progress-bar-warning").removeClass("progress-bar-success");

            if (strength < 25) {
                progressBarDomObject.addClass("progress-bar-danger");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("weak"));
            } else if (strength < 50) {
                progressBarDomObject.addClass("progress-bar-warning");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("fair"));
            } else {
                progressBarDomObject.addClass("progress-bar-success");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("strong"));
            }
        });

        this._view.getChildView("join_decline_container").on("click", "a", function (e) {

            e.preventDefault();

            domainDecisionController._view.getChildView("alert").slideUp();
            var clickedButtonName = $(this).attr("data-eds-view");

            var user = domainDecisionController.user;
            var domain = domainDecisionController.domain;
            var newLoginArgs = _.clone(domainDecisionController.loginArgs);
            var foreignAccount;
            var email;

            if (clickedButtonName == "join") {
                //See if we can send a domain decision OR if we have to prompt for an additional login method.
                foreignAccount = _.find(user.foreignAccounts, function (x) {
                    return x.providerName == domain.loginProviderName && x.providerEmail && x.providerEmail.split("@")[1] == domain.domain && (!user.foreignAccounts[0].domain || user.foreignAccounts[0].domain.domain == domain.domain);
                });

                email = _.find(user.emails, function (x) {
                   return x.split("@")[1] == domain.domain;
                });

                if (foreignAccount && email) {

                    newLoginArgs.domain_decision = {join: true};
                    domainDecisionController.performLogin(newLoginArgs);

                } else {

                    domainDecisionController.join = true;
                    domainDecisionController.setupLoginMethodsUI();
                }

            } else if (clickedButtonName == "decline") {
                //See if we can send a domain decision OR if we have to prompt for an additional login method.
                foreignAccount = _.find(user.foreignAccounts, function (x) {
                    return !x.providerEmail || x.providerEmail.split("@")[1] != domain.domain;
                });

                email = _.find(user.emails, function (x) {
                   return x.split("@")[1] != domain.domain;
                });

                if (foreignAccount || (email && user.hasPassword)) {

                    newLoginArgs.domain_decision = {join: false};
                    domainDecisionController.performLogin(newLoginArgs);

                } else {
                    domainDecisionController.join = false;
                    domainDecisionController.setupLoginMethodsUI();
                }
            }

            domainDecisionController._view.getChildView("join_decline_container").hide();
        });

        this._view.getChildView("foreign_accounts_container").on("click", "[data-mca-provider]", function (e) {

            domainDecisionController._view.getChildView("alert").slideUp();
            var clickedProviderName = $(this).attr("data-mca-provider");
            var clickedDomain = $(this).data("mca-domain");

            edsApp.utilities.custom.loginWithForeignProvider(clickedProviderName, undefined, clickedDomain, function (success, providerName, providerToken, providerId, providerEmail, userName, userImageURL) {

                if (success) {
                    var newLoginArgs = _.clone(domainDecisionController.loginArgs);
                    newLoginArgs.domain_decision = {join: domainDecisionController.join, email: providerEmail, providerName: providerName, providerId: providerId, providerToken: providerToken};
                    domainDecisionController.performLogin(newLoginArgs);
                } else {
                    domainDecisionController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("foreign_provider_login_error", {provider: edsApp.model.getLocalizedString(clickedProviderName)}) + (providerName ? " (" + providerName + ")" : ""));
                    domainDecisionController._view.getChildView("alert").slideDown();
                }

            });
        });

        this._view.getChildView("email_password_container").on("submit", function (e) {
            if (!domainDecisionController.formValidator.getFormStatus())
                return;

            domainDecisionController._view.getChildView("alert").slideUp();
            var newLoginArgs = _.clone(domainDecisionController.loginArgs);
            newLoginArgs.domain_decision = {
                join: domainDecisionController.join,
                email: domainDecisionController._view.getChildView("email").val(),
                password: domainDecisionController._view.getChildView("password").val() ? domainDecisionController._view.getChildView("password").val() : undefined};

            domainDecisionController.performLogin(newLoginArgs);
        });

        this._view.getChildView("unmask").on("click", function (e) {
            e.preventDefault();
            // if we need to support IE, see https://gist.github.com/bminer/3559343
            var pass = domainDecisionController._view.getChildView("password");
            if (pass.prop("type") === "password") {
                pass.prop("type", "text");
                domainDecisionController._view.getChildView("unmask").html('<i class="glyphicon glyphicon-eye-close form-control-feedback" style="pointer-events: auto !important;"></i>');
            } else {
                pass.prop("type", "password");
                domainDecisionController._view.getChildView("unmask").html('<i class="glyphicon glyphicon-eye-open form-control-feedback" style="pointer-events: auto !important;"></i>');
            }
        });

    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        return (_.has(a, "redirectControllerName") && _.has(edsApp.controllers, a.redirectControllerName) &&
                _.has(a, "redirectControllerArgs"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.redirectControllerName = a.redirectControllerName;
        this.redirectControllerArgs = JSON.parse(a.redirectControllerArgs);

        //Reset the controller.
        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("save").show();
        this._view.getChildView("login_methods").hide();
        this._view.getChildView("domain_decision_message").hide();
        this._view.getChildView("join_decline_container").hide();

        //This would only happen if this page is refreshed.
        if (!this.domain || !this.user || !this.loginArgs) {
            edsApp.utilities.invokeFunctionWithDelay(1, function () {
                edsApp.controllers.app.pushController(edsApp.controllers.login, false, {
                    redirectControllerArgs: a.redirectControllerArgs,
                    redirectControllerName: a.redirectControllerName
                });
            });

            return;
        }

        //Find the group with the given group id.
        var user = this.user;
        var allGroups = user.memberGroups.concat(user.adminGroups).concat(user.ownerGroups);
        var group = _.findWhere(allGroups, {id: this.domain.group.id});

        if (group) {
            this._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("domain_decision_subtitle_alternate", {domain: this.domain.domain}));
            this.join = true;
            this.setupLoginMethodsUI(true);

        } else {
            this._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("domain_decision_subtitle", {group: this.domain.group.name, domain: this.domain.domain })).show();
            this._view.getChildView("domain_decision_message").text(edsApp.model.getLocalizedString("domain_decision_message", {group: this.domain.group.name, domain: this.domain.domain })).show();
            this._view.getChildView("join_decline_container").show();
        }
    };

    willResignKeyController() {
        //For security reasons, we clear these fields.
        this._view.getChildView("email").val("");
        this._view.getChildView("password").val("");

        //Call the superclass.
        super.willResignKeyController();
    }

    createForeignAccountButton(providerName, domain) {

        var buttonText;

        if (domain) {
            buttonText = edsApp.model.getLocalizedString("link_account", {provider: domain.domain});
        } else {
            buttonText = edsApp.model.getLocalizedString("link_account", {provider: edsApp.model.getLocalizedString(providerName)});
        }

        var linkAccountButtonDomObject = $('<div class="btn-group edsAppVerticalPadded" data-mca-provider="' + providerName + '" style="width: 100%;">' +
                                '<a class="' + edsApp.utilities.custom.getButtonStyleForProvider(providerName) +' disabled" href="#" style="width: 15%;"><img src="' + edsApp.utilities.custom.getImageURLForProvider(providerName) +'" style="width:16px; height:16px"/></a>' +
                                '<a class="' + edsApp.utilities.custom.getButtonStyleForProvider(providerName) + '" href="#" style="width: 85%;">' + _.escape(buttonText) + '</a>' +
                            '</div><br>');

        if (domain)
            linkAccountButtonDomObject.data("mca-domain", domain);

        return linkAccountButtonDomObject;
    }

    setupLoginMethodsUI() {

        var domainDecisionController = this;
        this._view.getChildView("foreign_accounts_container").empty();

        if (this.join) {

            this._view.getChildView("login_method_title").text(edsApp.model.getLocalizedString("domain_joining_message", {domain: this.domain.domain, provider: edsApp.model.getLocalizedString(this.domain.loginProviderName)}));
            this._view.getChildView("foreign_accounts_container").append(this.createForeignAccountButton(this.domain.loginProviderName, this.domain));
            this._view.getChildView("email_password_container").hide();
            this._view.getChildView("email_button_container").hide();
            this._view.getChildView("button_container").show();

        } else {

            this._view.getChildView("login_method_title").text(edsApp.model.getLocalizedString("domain_declining_message", {domain: this.domain.domain}));

            //Add buttons for all foreign providers.
            _.each(edsApp.model.custom.foreignProviderNames, function(providerName) {

                var linkAccountButtonDomObject = domainDecisionController.createForeignAccountButton(providerName, undefined);
                domainDecisionController._view.getChildView("foreign_accounts_container").append(linkAccountButtonDomObject);
            });

            if (this.user.hasPassword) {
                this._view.getChildView("email_button").text(edsApp.model.getLocalizedString("enter_email"));
                this._view.getChildView("password_container").hide();
                this._view.getChildView("password").attr("data-eds-validator", "true");
            } else {
                this._view.getChildView("email_button").text(edsApp.model.getLocalizedString("enter_email_password"));
                this._view.getChildView("password_container").show();
                this._view.getChildView("password").attr("data-eds-validator", "edsApp.utilities.custom.passwordStrength($('#domain_decision_password').val()) > 20");
            }

            this._view.getChildView("email_button_container").show();
            this._view.getChildView("email_password_container").hide();
            this._view.getChildView("button_container").show();
        }

        this._view.getChildView("login_methods").show();
        this.formValidator.reloadFormStatus();
    }

    performLogin(newLoginArgs) {

        var domainDecisionController = this;

        if (domainDecisionController.performingRequest)
            return;

        domainDecisionController.performingRequest = true;
        domainDecisionController._view.getChildView("spinner").show();
        domainDecisionController._view.getChildView("save").hide();
        domainDecisionController._view.getChildView("join_decline_container").hide();

        edsApp.model.postDataToURL("login", newLoginArgs, function (success, data) {

            if (success && data.user && !data.domain) {

                //We're logged in!
                edsApp.utilities.custom.processSuccessfulLogin(
                    newLoginArgs.stay_signed_in,
                    newLoginArgs.email,
                    newLoginArgs.domain_decision.providerName,
                    data,
                    domainDecisionController.redirectControllerName,
                    false,
                    domainDecisionController.redirectControllerArgs
                );

            } else if (success) {

                //We redirect them to the login controller, since we can't help them further here.
                var controllerArgs = {redirectControllerArgs: JSON.stringify(domainDecisionController.redirectControllerArgs), redirectControllerName: domainDecisionController.redirectControllerName};

                if (data.guidance && data.guidance.verify_email) {
                    controllerArgs.infoMessage = edsApp.model.getLocalizedString("email_verification", {email: data.guidance.verify_email});
                }

                edsApp.controllers.app.pushController(edsApp.controllers.login, false, controllerArgs);

            } else {
                //We got an error. Let's display it.'
                domainDecisionController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                domainDecisionController._view.getChildView("alert").slideDown();
            }

            domainDecisionController.performingRequest = false;
            domainDecisionController._view.getChildView("spinner").hide();
            domainDecisionController._view.getChildView("save").show();

            var user = domainDecisionController.user;
            var allGroups = user.memberGroups.concat(user.adminGroups).concat(user.ownerGroups);
            var group = _.findWhere(allGroups, {id: domainDecisionController.domain.group.id});

            if (!group)
                domainDecisionController._view.getChildView("join_decline_container").show();
        });
    }

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("email_password_container").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }
};
