/** This abstract class facilitates fetching and sorting table data. In addition to providing all the
 * convenience of its superclass, ViewController, this class makes it easier for subclasses to work with a TableView. This
 * class overrides willBecomeKeyController and resets the table sorting every time the controller becomes key. It also
 * overwrites didPressKey to automatically scroll through the table view's pages using the keyboard's arrow keys. You
 * can implement a concrete subclass provided you follow the following requirements:
 * You must assign the tableView you create individually to the tableView
 * variable. In addition, you must implement the following 2 methods, which are described in detail hereafter:
 * sortTableDataAsync(columnNumber, descending, data) (If you want/implement table sorting) and
 * fetchTableDataAsync(numberOfRowsToFetch, offset, continuationToken). Additionally, the subclass may also optionally implement
 * the following methods:
 * -getTableViewMessage(tableData) (If you want to display a message at the bottom of the table view. Setting a message directly is
 * discouraged, as this class may constantly change it to null during sorting and fetching to display the spinner).
 * -didFinishDownloadingTableData(tableData) (Called when all the data contained in the tableData property has downloaded
 * from the remote server).
 * Before explaining the details of the two required methods, it is important to understand why this class can be very
 * helpful in streamlining an application's development process. A table view requires its controller to handle 2 things:
 * data fetching and data sorting. Without fetching data, the table view cannot display  any contents. Without sorting data,
 * the user will not be able to toggle the order of rows in the table. In a network application, this process can be quite
 * complex. Fetching can either be done all at once (i.e. grabbing all available rows from the server in a single network
 * request), or it can be done in batches (i.e. requesting 10 or 20 rows at a time from the server). Sorting can be done
 * immediately for a particular column (i.e. the name of a table of people where the client currently has all the names
 * in memory), or the application might need to ask the server for additional information before the sorting order can
 * be determined (i.e. if a person's manager is specified as an employee id, the application needs to request the server
 * the name for that specific employee id before it can determine the sort order by manager of the list of people). We
 * shall call the latter case of sorting "complex sorting", because it needs to be done asynchronously and the user will
 * need to wait until the application has retrieved all the necessary data from the server. Furthermore, there is another
 * case that needs to be considered: What if the application is in the process of fetching data in batches and the user
 * decides to sort a column? Or worse, what if the sorting for that column happens to require complex sorting? For
 * example, what if a user wants to see a list of 10,000 employees and the application fetches the data in batches of
 * 100 employees. What happens if, after loading 500 or so employees, the user decides to sort the table by manager name?
 * A traditional controller would need to implement complex code to keep all the fetching and sorting happening
 * asynchronously in harmony. This class does the hard and tedious async work for you. To start a fetch, all you need to
 * do is call beginFetching(). To start a sort, all you need to do is call beginSorting(). All a subclass needs to do is
 * to reference a table view to the tableView variable and implement sortTableDataAsync(columnNumber, descending, data)
 * (if you implement sorting) and fetchTableDataAsync(numberOfRowsToFetch, offset, continuationToken)
 * This class will invoke these methods when necessary to fetch and sort table data.
 * Below are explanations for each:
 *
 * REQUIRED METHODS TO IMPLEMENT FOR SUBCLASS:
 * fetchTableDataAsync(numberOfRowsToFetch, offset, continuationToken):
 * *numberOfRowsToFetch: Specifies the number of rows that the subclass should return in the fetchCallbackFn's data
 *                       parameter. If beginFetching() was called with no parameters, this value
 *                       is undefined and subclasses are not expected to use it.
 * *offset: Specifies the first row from which the subclass should start requesting data from a server. If
 *          beginFetching() was called with no parameters (no batch fetching desired), this value is undefined and
 *          subclasses are not expected to use it.
 * *continuationToken : An opaque token that was returned from the previous call to fetchTableDataAsync. This can be used
 * by the subclass to resume the query for more rows.
 * This method should return a Promise that itself resolves to a tuple whose first element is
 * the array with the data, and the second element is the next continuationToken, or null/undefined if this is the last batch to fetch.
 * If there is a problem fetching the data, the Promise should reject with an error.
 * Remarks: This method will keep getting invoked until a null or undefined continuatonToken is returned. 
 * (DEPRECATED - implement fetchTableDataAsync instead) fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn):
 * *numberOfRowsToFetch: Specifies the number of rows that the subclass should return in the fetchCallbackFn's data
 *                       parameter. It may return less if there is no more data available, but it should never return
 *                       more. If beginFetching() was called with no parameters (no batch fetching desired), this value
 *                       is undefined and subclasses are not expected to use it.
 * *offset: Specifies the first row from which the subclass should start requesting data from a server. If
 *          beginFetching() was called with no parameters (no batch fetching desired), this value is undefined and
 *          subclasses are not expected to use it.
 * *fetchCallbackFn(success, data, keepFetching): Once the rows have been retrieved from the server, you must return them in the form
 *                                  of an array in the data parameter. If there was a problem fetching the data, success
 *                                  should be set to false. For all other cases, including no results, success should be
 *                                  set to true. If keepFetching is true, then fetchTableData will be called again
 *                                  regardless of the number of items in the data array returned.
 * sortTableDataAsync(columnNumber, descending, data):
 * * columnNumber: The column number by which data is to be sorted.
 * * descending: Whether the data should be sorted in descending order. If false, it should be in ascending order.
 *   data: An array of previously fetched data that is to be sorted.
 *   This method should return a Promise that itself resolves to an array with the sorted data. If there is a problem 
 *   sorting the data, the Promise should reject with an error.
 * (DEPRECATED - implement sortTableDataAsync instead) sortTableData(columnNumber, descending, data, sortCallBackFn):
 * * columnNumber: The column number by which data is to be sorted.
 * * descending: Whether the data should be sorted in descending order. If false, it should be in ascending order.
 * * data: An array of previously fetched data that is to be sorted.
 * * sortCallbackFn(success, sortedData): Once the data has been sorted, you must return it as an array in the sortedData
 *                                        parameter. success should be set to false if there was a problem sorting the
 *                                        data. It should be set to true for all other cases.
 *
 * OPTIONAL METHODS TO IMPLEMENT FOR SUBCLASS:
 *
 * -getTableViewMessage(tableData):
 * (If you want to display a message at the bottom of the table view. Setting a message directly is
 * discouraged, as this class may constantly change it to null during sorting and fetching to display the spinner)
 *  *tableData: All the data in the form of an array available in memory that was fetched by the controller.
 *
 * -didFinishDownloadingTableData(tableData):
 * (Called when all the data contained in the tableData property has downloaded from the remote server).
 * *tableData: All the data in the form of an array available in memory that was fetched by the controller.
 **/
edsApp.classes.controllers.SingleTableViewController = class extends edsApp.classes.controllers.ViewController {

    constructor() {
		super();
		
		/* Do setup specific to this class. */

	    //Define Members.
	    //Table related members.
	    this.tableView = null;
	    this.tableData = [];
	    this._tableMessage = null; //Caches the table view's message.
	    this._sortCounter = 0; //Used by sorting/fetching methods to determine the current sorting operation.
	    this._fetchCounter = 0; //Used by sorting/fetching methods to determine the current fetching operation.
	    this._isSorting = false;
	    this._isSorted = false;
	    this.sortedColumn = -1; //Denotes the table is not sorted.
	    this.sortDescending = false;
    }

    //OPTIONAL PUBLIC METHODS TO OVERRIDE:

    /* Returns the message that should be set for the table view. This method is called at appropriate times when data
     * fetching and sorting have finished and the spinner is no longer shown. data is an array containing all of the
     * table's data. You may not modify this array, but you may use if for reference to customize the message returned.
     * NOTE: You do not need to override this method. The default implementation returns the number of rows in the table.*/
    getTableViewMessage(data) {
        return edsApp.model.getLocalizedString("number_of_results", {number : data.length});
    }


    /* Clears the table and its data every time the controller is made key. */
    willBecomeKeyController(a) {

        //Call the superclass.
		super.willBecomeKeyController(a);
		
        this._resetTableState();
    }

    /* Changes the page of the table view. */
	didPressKey(keyCode) {

        //Left arrow key and right arrow key, respectively
        if (keyCode == 37 || keyCode == 39) {

            //Let's make sure no input has focus in the controller's view.
            if (this._view.find("input:focus").length == 0) {
                this.tableView.loadPage(this.tableView.currentPageNumber() + (keyCode == 37 ? -1 : 1));
            }
        }

        //Call the superclass.
        super.didPressKey();
    }

    /* Called when all the data contained in the tableData property has downloaded from the remote server */
    didFinishDownloadingTableData(tableData) {
      //Do nothing here. If subclasses want to do something, they may.
    }

    //PUBLIC METHODS (DO NOT OVERRIDE!):

    /* Begins fetching data and feeds it to the table view for display. Calling this method will cause fetchTableDataAsync()
     * to be called on the subclass at some point. You should specify the amount of rows that the receiver should ask for
     * on the first fetch and how many rows it should ask on subsequent fetches. Making the initial fetch small can
     * increase the GUI's responsiveness by providing the user something to play with while more data is fetched in the
     * background. If your subclass does not need to or cannot fetch by batches, you can call this function with no
     * arguments specified: When fetchTableDataAsync() is invoked, its numberOfRowsToFetch and offset parameters will be
     * undefined and are not expected to be used in this case. If there was a previous fetch ongoing, the previous fetch
     * will be ignored and overwritten by the current one.*/
    beginFetching(rowsForInitialFetch, rowsForSubsequentFetches, initialContinuationToken) {

        this._fetchCounter++; //Give the fetch request a unique integer to differentiate it from previous ones.
        this.tableView.setMessage(null);
        this.tableView.reload(0);
        this.tableData = [];

        var fetchOffset = 0;
        var currentFetchCount = this._fetchCounter; //This will be checked after each callback fn is invoked.
        var tableViewController = this;
        var numberOfRowsToFetch = rowsForInitialFetch;
        var callbackFnCopy = null; //We need to make a copy of the callback because we're referencing it inside itself.

        var callbackFn = function (success, data, continuationToken) {

            //If for whatever reason we're now doing another fetch, we ignore the results.
            if (currentFetchCount != tableViewController._fetchCounter)
                return;

            if (!success) {
                tableViewController._abortFetch();
                return;
            }

            //Add all the new elements to the current array.
            _.each(data, function(aDataObject) {
                tableViewController.tableData.push(aDataObject);
            });

            //See if the data array has been (or is being) sorted. If it's not, we reload the table and we're done.
            //If it is sorted, we start the sorting process again (effectively canceling the current complex sorting
            // process, if any).
            if (tableViewController._isSorted || tableViewController._isSorting)
                tableViewController.beginSorting(tableViewController.sortedColumn, tableViewController.sortDescending);
            else
                tableViewController.tableView.reload(tableViewController.tableData.length);

            //Check to see if we should request more data.
            //With fetchTableDataAsync, if there's no continuationToken, we bail.
            if (!continuationToken && (!_.isUndefined(tableViewController.fetchTableDataAsync) || _.isUndefined(numberOfRowsToFetch) || data.length < numberOfRowsToFetch)) {

                tableViewController._setTableViewMessage(tableViewController.getTableViewMessage(tableViewController.tableData));
                tableViewController.didFinishDownloadingTableData(tableViewController.tableData);

            } else {

                //Update the current offset and request the next batch.
                if (!_.isUndefined(numberOfRowsToFetch))
                    fetchOffset += data.length;

                numberOfRowsToFetch = rowsForSubsequentFetches;

                if (_.isUndefined(tableViewController.fetchTableDataAsync)) {
                    //Fallback to deprecated method for legacy code.
                    tableViewController.fetchTableData(numberOfRowsToFetch, fetchOffset, callbackFnCopy);
                } else {
                    var promise = tableViewController.fetchTableDataAsync(numberOfRowsToFetch, fetchOffset, continuationToken);
        
                    promise.then(([data, continuationToken]) => {
                        callbackFnCopy(true, data, continuationToken);
                    }).catch((error) => {
                        console.log(`Error in fetchTableDataAsync in controller '${tableViewController.name}': ${error} ${error.stack}`);
                        callbackFnCopy(false, error, null);
                    });
                }
            }
        };

        callbackFnCopy = callbackFn;

        if (_.isUndefined(this.fetchTableDataAsync)) {
            //Fallback to deprecated method for legacy code.
            this.fetchTableData(rowsForInitialFetch, fetchOffset, callbackFnCopy);
        } else {
            var promise = this.fetchTableDataAsync(rowsForInitialFetch, fetchOffset, initialContinuationToken);

            promise.then(([data, continuationToken]) => {
                callbackFnCopy(true, data, continuationToken);
            }).catch((error) => {
                console.log(`Error in fetchTableDataAsync in controller '${tableViewController.name}': ${error} ${error.stack}`);
                callbackFnCopy(false, error, null);
            });
        }

    }


    /* Begins sorting data and reloads the table with the sorted data when done. Calling this method will cause
     sortTableDataAsync()to be called on the subclass at some point. This method automatically sets the table view's
     carets.
     */
    beginSorting(columnNumber, sortDescending) {

        //Make sure we actually have something to sort!
        if (this.tableData.length == 0)
            return;

        // TODO: skip sorting if checkbox header

        //We see if there is a complex sorting operation going on right now. If there is, the value of
        //tableView.message() will be null, so we keep the value of tableMessage the same. If there is no complex
        //sorting going on, we set the value of tableMessage to tableView.message().
        this._tableMessage = this._isSorting ? this._tableMessage : this.tableView.message();
        this.tableView.setMessage(null);
        this._sortCounter++; //This will stop any ongoing complex sorting (if any).
        this._isSorting = true;

        //Set the appropriate icon on the table view.
        if (sortDescending) {
            this.tableView.setCaretForColumnHeader(columnNumber, "down");
        } else {
            this.tableView.setCaretForColumnHeader(columnNumber, "up");
        }

        var currentSortCount = this._sortCounter; //This will be checked after each callback fn is invoked.
        var tableViewController = this;

        var callbackFn = function(success, sortedData) {

            //Make sure the user still wants this sort. Otherwise we ignore the mapped data we got from the server.
            if (currentSortCount !== tableViewController._sortCounter)
                return;

            if (!success) {
                tableViewController._abortComplexSort();
                return;
            }

            tableViewController.tableData = sortedData;
            tableViewController.tableView.reload(tableViewController.tableData.length);
            tableViewController.tableView.setMessage(tableViewController._tableMessage);
            tableViewController._isSorting = false;
            tableViewController._isSorted = true;
        };

        if (_.isUndefined(this.sortTableDataAsync)) {
            //Fallback to deprecated method for legacy code.
            this.sortTableData(columnNumber, sortDescending, this.tableData, callbackFn);
        } else {
            var promise = this.sortTableDataAsync(columnNumber, sortDescending, this.tableData);

            promise.then((sortedData) => {
                callbackFn(true, sortedData);
            }).catch((error) => {
                console.log(`Error in sortTableDataAsync in controller '${tableViewController.name}': ${error}`);
                callbackFn(false, error);
            });
        }
    }

    //PRIVATE METHODS (DO NOT USE IN SUBCLASS OR OVERRIDE!):

    /** This method sets the message of the table referenced by tableView. It is designed so that if a complex sorting
     * process is ongoing (if _isSorting), it stores the message in the _tableMessage variable and
     * lets the subclass sorting progress set it when it's done. Discussion: This is useful because the
     * beginNewSortingProcess() method sets the table's message to null to show the spinner, with the implication that
     * the sorting process will restore the original message later from _tableMessage. Thus, if you set the tableView's
     * message directly while a complex sort process is ongoing, it will be overwritten later on. This method prevents
     * that by assigning message to the _tableMessage variable that should be used by the sorting algorithm when it's
     * done to replace the tableView's message. */
    _setTableViewMessage(message) {

        if (this._isSorting)
            this._tableMessage = message;
        else
            this.tableView.setMessage(message);
    }


    /* Sets the tableView's message and presents an alert to the user. The message is set using setTableViewMessage(),
     * so it is safe to call this method even if a complex sort process is ongoing. */
    _abortFetch() {

        var message = edsApp.model.getLocalizedString("table_fetching_error");
        this._setTableViewMessage(message);
    }


    /* Presents an alert to the user, erases any carets in the tableView's headers, reloads the table with
     * tableData.length number of rows, sets _sortComplexDataProgress to -1 to signify the end of the sort process, and
     * adds one to _sortCounter. */
    _abortComplexSort() {

        //Erase any carets on the table view's headers.
        this.tableView.setCaretForColumnHeader(0, null);

        //Reload the table.
        this.tableView.reload(this.tableData.length);
        this.tableView.setMessage(this._tableMessage);

        //Reset the sorting boolean.
        this._isSorting = false;
        this._isSorted = false;

        //Prevent the alert from showing up multiple times by changing the sort counter.
        this._sortCounter++;
    }

    /* This convenience method resets various variables */
    _resetTableState() {

        //Reset any sorting that may have been set.
        this.tableView.setCaretForColumnHeader(0, null);
        this._isSorted = false;
        this._isSorting = false;
        this.sortedColumn = -1; //Denotes the table is not sorted.
        this.sortDescending = false;
    }
};