edsApp.classes.controllers.custom.NewMemberInvitesController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        //Define members.
        this.name = "new_member_invites";
        this.requiresLogin = true;
        this.groupId = null;
        this.emails = false;
        this.helpLink = "invite_group_members";
        this.secureCallback = null;
    }

    //Define Functions.
    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Load all languages to the select form control
        var selectDomObject = this._view.getChildView("language");
        edsApp.utilities.appendSortedLanguageIdsToSelectDomObject(selectDomObject);

        //Hookup view events to their respective "listeners" or "targets".
        var newMemberInvitesController = this;
        this._view.getChildView("send").on("click", function (e) {

            e.preventDefault();

            newMemberInvitesController._view.getChildView("send").hide();
            newMemberInvitesController._view.getChildView("spinner").show();

            var postArgs = {emails: newMemberInvitesController.emails,
                            group_id: newMemberInvitesController.groupId,
                            language_ids: [newMemberInvitesController._view.getChildView("language").val()]};

            edsApp.model.postDataToURL("members", postArgs, function (success, data) {

                if (success) {

                    var onSuccessFn = function () {

                        edsApp.model.clearJSONDataCacheForURLBeginningWith("invite");

                        edsApp.controllers.app.pushController(edsApp.controllers.group_detail, true, {
                            successMessage: edsApp.model.getLocalizedString("invites_send_successful"),
                            group_id: newMemberInvitesController.groupId
                        });
                    };

                    if (newMemberInvitesController.secureCallback) {
                        newMemberInvitesController.secureCallback(["groups"], data, function () {
                            onSuccessFn();
                        })
                    } else {
                        onSuccessFn();
                    }

                } else {

                    newMemberInvitesController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    newMemberInvitesController._view.getChildView("alert").slideDown();
                    newMemberInvitesController._view.getChildView("create").show();
                    newMemberInvitesController._view.getChildView("spinner").hide();
                }
            });
        });

        this._view.getChildView("emails").on("change keyup paste", function (e) {

            newMemberInvitesController.emails = newMemberInvitesController.parseEmails($(this).val());
            var emailContainerDomObject = newMemberInvitesController._view.getChildView("emails_container");
            emailContainerDomObject.empty();

            if (newMemberInvitesController.emails === false) {

                newMemberInvitesController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("email_parse_error"));
                newMemberInvitesController._view.getChildView("alert").slideDown();
                newMemberInvitesController._view.getChildView("send").prop("disabled", true);

            } else if (newMemberInvitesController.emails.length === 0) {

                newMemberInvitesController._view.getChildView("send").prop("disabled", true);

            } else {
                var labelDomObject = $('<span class="label label-default label-wrapping edsAppHorizontalSpaced small"></span>');

                _.each(newMemberInvitesController.emails, function (anEmail) {
                    emailContainerDomObject.append(labelDomObject.clone().text(anEmail));
                });

                newMemberInvitesController._view.getChildView("alert").slideUp();
                newMemberInvitesController._view.getChildView("send").prop("disabled", false);
            }
        });
    };

    canBecomeKeyController(a) {
        //Note we are overriding the superclass implementation.
        return (_.has(a, "group_id"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.groupId = a.group_id;

        this._view.getChildView("emails_container").empty();
        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("send").show();
        this._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("loading"));
        edsApp.utilities.appendLanguageIdToSelectDomObject(this._view.getChildView("language"), edsApp.model.custom.user.languageId);

        var newMemberInvitesController = this;
        var currentUser = edsApp.model.custom.user;
        var allGroups = currentUser.memberGroups.concat(currentUser.adminGroups).concat(currentUser.ownerGroups);
        var group = _.findWhere(allGroups, {id: this.groupId});
        
        if (group)
            newMemberInvitesController._view.getChildView("subtitle").text(group.name);
        else
            newMemberInvitesController._view.getChildView("subtitle").html($('<span class="glyphicon glyphicon-question-sign"></span>'));

        //Reset the form.
        this._view.getChildView("emails").val("");
        this._view.getChildView("send").prop("disabled", true);

        //Setup secure callbacks.
        if (a.secureCallback)
            this.secureCallback = edsApp.utilities.custom.setupSecureCallback();
        else
            this.secureCallback = null;
    };

    parseEmails(aString) {

        var parseError = false;
        var startIndex = 0;
        var emails = [];
        var strLen = aString.length;

        while (!parseError && strLen - 1 > startIndex && (startIndex = aString.indexOf("@", startIndex+1)) > - 1) {

            var email = "";
            var i;
            var aChar;
            var hasTrailingChar;

            //Figure out the prefix.
            for (i = startIndex-1; i >= 0; i--) {

                aChar = aString[i];

                if (/\s/g.test(aChar) || aChar === ',' || aChar === "<" || aChar === ">" || aChar === ";") {
                    break;
                } else if (aChar === "@") {
                    parseError = true;
                    break;
                } else {
                    email = aChar + email;
                }
            }

            email += "@";
            hasTrailingChar = false;

            //Figure out the suffix.
            for (i = startIndex+1; i < strLen; i++) {

                aChar = aString[i];

                if (/\s/g.test(aChar) || aChar === ',' || aChar === "<" || aChar === ">" || aChar === ";") {
                    break;
                } else if (aChar === "@") {
                    parseError = true;
                    break;
                } else {
                    email += aChar;
                    hasTrailingChar = true;
                }
            }

            //Make sure this is a valid email.
            if (!parseError && hasTrailingChar && !_.contains(emails, email)) {
                emails.push(email);
            }
        }

        if (parseError)
            return false;
        else
            return emails;
    };
};