/* Setup the App namespace below. We create containers (objects) for each layer of the application. */
var edsApp = {};
edsApp.model = {};
edsApp.model.custom = {};
edsApp.utilities = {};
edsApp.utilities.custom = {};
edsApp.classes = {};
edsApp.classes.utilities = {};
edsApp.classes.controllers = {};
edsApp.classes.controllers.custom = {};
edsApp.classes.controllers.customAbstract = {};
edsApp.classes.views = {};
edsApp.classes.views.custom = {};
edsApp.controllers = {};
