edsApp.classes.controllers.custom.LoginController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "login";
        this.performingLogin = false;
        this.retryCounter = {};
        this.formValidator = null;
        this.redirectControllerName = null;
        this.redirectControllerArgs = null;
        this.redirectRecordHistory = null;
        this.forgivenessToken = null;
        this.twoFactorMethod = null;
        this.magicLinkRequested = false; // TODO: figure out a better way to pass this to performLogin()
        this.magicLinkToken = null;
        this.debug = false;
    }

    //Define Functions.
    setViewHTML(html) {

        var loginController = this;

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this._view.getChildView("stay_logged_in_popover").popover();
        this._view.getChildView("stay_logged_in").prop("checked", edsApp.model.getLocalStorageValueForKey("stay_logged_in") !== false);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("login_box"), this, true, false);

        //Attach event listeners.
        this._view.getChildView("stay_logged_in").on("change", function (e) {
            edsApp.model.setLocalStorageValueForKey($(this).is(':checked'), "stay_logged_in");
        });

        this._view.getChildView("login_box").on("submit", function (e) {
            if (loginController.formValidator.getFormStatus())
                loginController.performLogin();
        });

        this._view.getChildView("next_button").on("click", function (e) {
            e.preventDefault();
            loginController.performLogin();
        });

        this._view.getChildView("magic_link_button").on("click", function (e) {
            e.preventDefault();
            loginController.magicLinkRequested = true;
            loginController.performLogin();
        });

        this._view.getChildView("create_account").on("click", function (e) {
            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.new_user_create, true, {redirectControllerName: loginController.redirectControllerName, redirectControllerArgs: JSON.stringify(loginController.redirectControllerArgs)})
        });

        this._view.getChildView("forgot_password").on("click", function (e) {
            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.forgot_password, true, {redirectControllerName: loginController.redirectControllerName, redirectControllerArgs: JSON.stringify(loginController.redirectControllerArgs)})
        });

        this._view.getChildView("didnt_get_email").on("click", function (e) {
            e.preventDefault();

            var email = loginController._view.getChildView("email").val();

            if (email) {
                edsApp.controllers.app.pushController(edsApp.controllers.verify_email, true, {email: email, redirectControllerName: loginController.redirectControllerName, redirectControllerArgs: JSON.stringify(loginController.redirectControllerArgs)})
            }
        });

        this._view.getChildView("foreign_accounts_container").on("click", "[data-mca-provider]", function (e) {

            loginController._view.getChildView("alert").slideUp();
            var clickedProviderName = $(this).attr("data-mca-provider");
            var clickedDomain = $(this).data("mca-domain");

            edsApp.utilities.custom.loginWithForeignProvider(clickedProviderName, undefined, clickedDomain, function (success, providerName, providerToken, providerId, providerEmail, userName, userImageURL) {

                if (success) {
                    if (loginController.debug) {
                        console.log(providerToken);
                        console.log(providerEmail);
                    }
                    loginController.performLogin(providerId, providerName, clickedDomain ? clickedDomain.domain : undefined, providerToken, providerEmail, userName);
                } else {
                    loginController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("foreign_provider_login_error", {provider: edsApp.model.getLocalizedString(clickedProviderName)})  + " (" + providerName + ")");
                    loginController._view.getChildView("alert").slideDown();
                }
            });
        });

        this._view.getChildView("two_factor_by_text").on("click", function (e) {

            e.preventDefault();
            loginController._view.getChildView("two_factor_code").val("");
            loginController.twoFactorMethod = "text";
            loginController.performLogin();
        });

        this._view.getChildView("two_factor_by_phone").on("click", function (e) {

            e.preventDefault();
            loginController._view.getChildView("two_factor_code").val("");
            loginController.twoFactorMethod = "phone";
            loginController.performLogin();
        });

        this._view.getChildView("two_factor_by_email").on("click", function (e) {

            e.preventDefault();
            loginController._view.getChildView("two_factor_code").val("");
            loginController.twoFactorMethod = "email";
            loginController.performLogin();
        });

        this._view.getChildView("two_factor_by_totp").on("click", function (e) {

            e.preventDefault();

            //This doesn't require a round-trip to the server -- it should be immediate.
            loginController._view.getChildView("two_factor_code").val("");
            loginController.twoFactorMethod = "totp";
            loginController._view.getChildView("info_alert_message").text(edsApp.model.getLocalizedString("input_totp_code"));
            loginController._view.getChildView("two_factor_code").attr("aria-label", edsApp.model.getLocalizedString("input_totp_code"));
            loginController._view.getChildView("two_factor_by_totp").slideUp();
        });

        this._view.getChildView("account_chooser_container").on("click", "a", function (e) {

            e.preventDefault();
            var accountChooserItemDomObject = $(this);
            var foreignAccounts = accountChooserItemDomObject.data("mca-foreign-accounts");
            var hasPassword = accountChooserItemDomObject.data("mca-has-password");
            var magicLinkEligible = accountChooserItemDomObject.data("mca-magic-link-eligible");

            //Either login with the foreign provider or offer login options.
            if (foreignAccounts.length == 1 && !hasPassword && !magicLinkEligible) {

                loginController._view.getChildView("alert").slideUp();

                //This bit is crucial for teams who have switched authentication methods.
                var clickedProviderName = foreignAccounts[0].domain ? foreignAccounts[0].domain.loginProviderName : foreignAccounts[0].providerName;

                var clickedDomain = foreignAccounts[0].domain;
                var clickedProviderId = foreignAccounts[0].providerId;

                edsApp.utilities.custom.loginWithForeignProvider(clickedProviderName, clickedProviderId, clickedDomain, function (success, providerName, providerToken, providerId, providerEmail, userName, userImageURL) {

                    if (success) {
                        if (loginController.debug) {
                            console.log(providerToken);
                            console.log(providerEmail);
                        }
                        loginController.performLogin(providerId, providerName, clickedDomain ? clickedDomain.domain : undefined, providerToken, providerEmail, userName);
                    } else {
                        loginController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("foreign_provider_login_error", {provider: edsApp.model.getLocalizedString(clickedProviderName)})  + "(" + providerName + ")");
                        loginController._view.getChildView("alert").slideDown();
                    }
                });

            } else if (magicLinkEligible && foreignAccounts.length == 0 && !hasPassword) {
                // send the magic link straight away if that's their only login option
                loginController._view.getChildView("alert").slideUp();
                loginController._view.getChildView("email").val(accountChooserItemDomObject.data("mca-email"));
                loginController.magicLinkRequested = true;
                loginController.performLogin();
            } else {
                loginController._view.getChildView("email").val(accountChooserItemDomObject.data("mca-email"));
                // manually validate next button in case user later backs out via "other login options"
                loginController._view.getChildView("next_button").prop("disabled", !loginController._view.getChildView("email")[0].checkValidity());
                loginController.setupLoginUI(false, false, hasPassword, magicLinkEligible, false, _.map(foreignAccounts, function(x){ return {name: x.providerName, domain: x.domain}; }), true, false, false, false, false, true);
            }
        });

        this._view.getChildView("other_login_options").on("click", function (e) {
            e.preventDefault();

            loginController._view.getChildView("two_factor_code").attr("data-eds-validator", "true");
            loginController.formValidator.reloadFormStatus();
            loginController._view.getChildView("password").val("");
            loginController._view.getChildView("two_factor_code").val("");
            loginController.magicLinkToken = null;
            loginController.setupLoginUI(false, true, false, false, false, _.map(edsApp.model.custom.foreignProviderNames, function(x){ return {name: x}; }), true, false, false, false, false, true);
        });
    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        return (_.has(a, "redirectControllerName") && _.has(edsApp.controllers, a.redirectControllerName) &&
                _.has(a, "redirectControllerArgs"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.redirectControllerName = a.redirectControllerName;
        this.redirectControllerArgs = JSON.parse(a.redirectControllerArgs);
        this.redirectRecordHistory = a.redirectRecordHistory ? true : false;
        this.twoFactorMethod = null;
        this.magicLinkToken = null;

        this._view.getChildView("heading").text(edsApp.model.getLocalizedString("login_title"));

        if (a.forgivenessToken)
            this.forgivenessToken = a.forgivenessToken;

        if (a.successMessage) {
            this._view.getChildView("success_alert_message").text(a.successMessage);
        }

        if (a.infoMessage) {
            this._view.getChildView("info_alert_message").text(a.infoMessage);
        }

        var showAlert = false;

        if (!navigator.cookieEnabled) {
            this._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("cookies_required_message", {host: window.location.host}));
            showAlert = true;
        } else if (a.redirectControllerName == edsApp.controllers.member_invite_confirm.name) {
            this._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("login_required_member_invite_confirm", {group: this.redirectControllerArgs.name}));
            showAlert = true;
        }

        //Reset the UI.

        //See what login options to show based on the session info object.
        var sessionInfo = edsApp.model.custom.sessionInfo;
        var sessionInfoUsers = false;
        var foreignAccounts = [];
        var showEmail = false;

        if (_.isArray(a.providerNames) && _.difference(a.providerNames, edsApp.model.custom.foreignProviderNames).length === 0) {
            foreignAccounts = a.providerNames;
        }

        if (a.login_hint) {

            //Show only the hinted user.
            sessionInfoUsers = _.where(sessionInfo.users, {id: a.login_hint});

        } else if (foreignAccounts.length > 0 || a.email) {

            //Show specific login options.
            if (a.email) {
                this._view.getChildView("email").val(a.email);
                showEmail = true;
            }

        } else if (sessionInfo && sessionInfo.users.length > 0) {

            //Show the account chooser.
            sessionInfoUsers = sessionInfo.users;

        } else {

            //Show all login options.
            showEmail = true;
            foreignAccounts = edsApp.model.custom.foreignProviderNames;
        }

        this.debug = !!a.debug;

        this.setupLoginUI(sessionInfoUsers, showEmail, false, false, false, _.map(foreignAccounts, function(x){ return {name: x}; }), true, !_.isUndefined(a.successMessage), !_.isUndefined(a.infoMessage), showAlert, false, false);
        this._view.getChildView("spinner").hide();
        this._view.getChildView("didnt_get_email").hide();

        //Reset the validator.
        this._view.getChildView("two_factor_code").attr("data-eds-validator", "true");
        this.formValidator.reloadFormStatus();
        this._view.getChildView("next_button").prop("disabled", !this._view.getChildView("email")[0].checkValidity());
    };

    willResignKeyController() {

        //For security reasons, we clear these fields.
        this._view.getChildView("email").val("");
        this._view.getChildView("password").val("");
        this._view.getChildView("two_factor_code").val("");
        this.magicLinkToken = null;
        this.debug = false;

        //Call the superclass.
        super.willResignKeyController();
    };

    didPressKey(keyCode) {
        //13 is the return key. Note that we do not call the superclass.
        if (this._view.getChildView("email").is(":focus") && keyCode == 13 && this._view.getChildView("email")[0].checkValidity())
            this.performLogin();
    };

    setupLoginUI(sessionInfoUsers, email, password, magicLink, twoFactorMethods, providerInfo, staySignedIn, successAlert, infoAlert, warningAlert, helpBlocks, animated) {

        var loginController = this;
        loginController._view.getChildView("spinner").hide();
        loginController._view.getChildView("magic_link_container").hide();
        loginController._view.getChildView("login_box").show();
        loginController._view.getChildView("options").show();
        loginController._view.getChildView("create_account_container").hide();
        loginController._view.getChildView("forgot_password_container").hide();
        loginController._view.getChildView("go_back_container").hide();

        var showAnimated = function ($element) {
          if (animated) {
              $element.slideDown();
          } else {
              $element.show();
          }
        };

        var hideAnimated = function ($element) {
          if (animated) {
              $element.slideUp();
          } else {
              $element.hide();
          }
        };

        if (sessionInfoUsers && sessionInfoUsers.length > 0) {

            //First, empty out the container of any cached accounts.
            this._view.getChildView("account_chooser_container").empty();

            //Add buttons for all the users provided.
            _.each(sessionInfoUsers, function (user) {

                var picture = user.picture ? user.picture : "/media/silhouette.png";

                var accountChooserItemDomObject = $(
                    '<a href="#" class="btn btn-default mcaAccountChooserItem">' +
                        '<img class="mcaAccountChooserImage pull-left" src="' + picture + '">' +
                        '<div class="mcaAccountChooserText">' +
                            _.escape(user.name) +
                            '<br>' +
                            '<small class="edsAppGrayColor">' + _.escape(user.email) + '</small>' +
                        '</div>' +
                    '</a>');

                accountChooserItemDomObject.data("mca-has-password", user.hasPassword);
                accountChooserItemDomObject.data("mca-magic-link-eligible", user.magicLinkEligible);
                accountChooserItemDomObject.data("mca-email", user.email);
                accountChooserItemDomObject.data("mca-foreign-accounts", user.foreignAccounts);
                loginController._view.getChildView("account_chooser_container").append(accountChooserItemDomObject);
            });


            this._view.getChildView("other_login_options").text(edsApp.model.getLocalizedString("use_different_account"));
            this._view.getChildView("account_chooser_container").show();


        } else {
            this._view.getChildView("other_login_options").text(edsApp.model.getLocalizedString("go_back"));
            this._view.getChildView("account_chooser_container").hide();
        }

        if (email || password || twoFactorMethods) {

            if (!_.isEmpty(providerInfo) || !_.isEmpty(twoFactorMethods)) {
                this._view.getChildView("login_box").find(".mcaSeparator").show();
            } else {
                this._view.getChildView("login_box").find(".mcaSeparator").hide();
            }

            this._view.getChildView("email_password_container").show();
        } else {

            this._view.getChildView("login_box").find(".mcaSeparator").hide();
            this._view.getChildView("email_password_container").hide();
        }

        if (email) {
            this._view.getChildView("email_container").show();
        } else {
            this._view.getChildView("email_container").hide();
        }

        if (password) {
            this._view.getChildView("password").prop("required", true);
            this._view.getChildView("password_container").show();
            this._view.getChildView("password").prop('readonly', false);
            // this._view.getChildView("password").focus();
            this._view.getChildView("forgot_password_container").show();
        } else {
            this._view.getChildView("password_container").hide();
            // prevent browser from autofilling password when we aren't looking
            this._view.getChildView("password").prop('readonly', true);
        }

        if (magicLink) {
            this._view.getChildView("magic_link_button_container").show();
            if (password) {
                this._view.getChildView("login_box").find(".mcaSeparator").show();
            }
        } else {
            this._view.getChildView("magic_link_button_container").hide();
        }

        if (!sessionInfoUsers && !email && !password && !magicLink && !twoFactorMethods && _.isEmpty(providerInfo)) {
            // if no login options, then assume we're wating for a magic link to be clicked
            loginController._view.getChildView("heading").text(edsApp.model.getLocalizedString("magic_link_check_email"));
            loginController._view.getChildView("magic_link_container").show();
            loginController._view.getChildView("login_box").hide();
            loginController._view.getChildView("options").hide();

            var checkMagicLink = function checkMagicLinkRecursiveFn() {
                if (!loginController.magicLinkToken) {
                    return;
                }
                // loginController._view.getChildView("spinner").show();
                edsApp.model.getJSONDataForURL("magic-link", {token: loginController.magicLinkToken}, 0, function(success, data) {
                    if (success && data === true) {
                        loginController.performLogin();
                    } else if (!loginController.magicLinkToken) {
                        // do nothing - user has probably navigated away
                    } else if (!success && !_.isUndefined(data) && data.error === "error_invalid_magic_link") {
                        // show warning and go back to the start
                        loginController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                        loginController._view.getChildView("magic_link_spinner").hide();
                        loginController.magicLinkToken = null;
                        loginController.setupLoginUI(false, true, false, false, false, _.map(edsApp.model.custom.foreignProviderNames, function(x){ return {name: x}; }), true, false, false, true, false, true);
                    } else {
                        edsApp.utilities.invokeFunctionWithDelay(5000, checkMagicLink);
                    }
                });
            }
            checkMagicLink()
        }

        var $methodsContainer = this._view.getChildView("two_factor_methods_container");

        if (twoFactorMethods) {

            // remove password validation, since it's necessary for 2fa validation
            this._view.getChildView("password").prop("required", false);
            this.formValidator.reloadFormStatus();

            //Show the appropriate two factor methods.
            if (_.contains(twoFactorMethods, "totp"))
                $methodsContainer.getChildView("two_factor_by_totp").show();
            else
                $methodsContainer.getChildView("two_factor_by_totp").hide();
            if (_.contains(twoFactorMethods, "text"))
                $methodsContainer.getChildView("two_factor_by_text").show();
            else
                $methodsContainer.getChildView("two_factor_by_text").hide();
            if (_.contains(twoFactorMethods, "phone"))
                $methodsContainer.getChildView("two_factor_by_phone").show();
            else
                $methodsContainer.getChildView("two_factor_by_phone").hide();
            if (_.contains(twoFactorMethods, "email"))
                $methodsContainer.getChildView("two_factor_by_email").show();
            else
                $methodsContainer.getChildView("two_factor_by_email").hide();

            this._view.getChildView("two_factor_code").prop('readonly', false);
            this._view.getChildView("two_factor_code_container").show();
            this._view.getChildView("two_factor_code").focus();
            showAnimated($methodsContainer);
        } else {
            this._view.getChildView("two_factor_code_container").hide();
            this._view.getChildView("two_factor_code").prop('readonly', true);
            hideAnimated($methodsContainer);
        }

        if (!_.isEmpty(providerInfo)) {

            //Get rid of all existing buttons.
            this._view.getChildView("foreign_accounts_container").children().remove();

            //Add buttons for all the foreign providers desired.
            _.each(providerInfo, function (info) {

                //This bit is crucial for teams who have switched authentication methods.
                var loginProviderName = info.domain ? info.domain.loginProviderName : info.name;

                // hide facebook on login page
                if (loginProviderName === 'facebook') {
                    return;
                }

                var buttonText;

                if (info.domain) {
                    buttonText = edsApp.model.getLocalizedString("sign_in_at_domain", {domain: info.domain.domain});
                } else {
                    buttonText = edsApp.model.getLocalizedString("sign_in_with_provider", {provider: edsApp.model.getLocalizedString(loginProviderName)});
                }

                var providerButtonDomObject = $('<div style="padding-bottom: 5px;" data-mca-provider="'+ loginProviderName +'">' +
                            '<div class="btn-group btn-group-sm" style="width: 100%;">' +
                                '<a class="' + edsApp.utilities.custom.getButtonStyleForProvider(loginProviderName) +' disabled" href="#" style="width: 15%;"><img src="' + edsApp.utilities.custom.getImageURLForProvider(loginProviderName) +'" style="width:14px; height:14px"/></a>' +
                                '<a class="' + edsApp.utilities.custom.getButtonStyleForProvider(loginProviderName) + '" href="#" style="width: 85%;">' + _.escape(buttonText) + '</a>' +
                            '</div>' +
                        '</div>');

                providerButtonDomObject.data("mca-domain", info.domain);
                loginController._view.getChildView("foreign_accounts_container").append(providerButtonDomObject);
            });

            showAnimated(this._view.getChildView("foreign_accounts_container"));
        } else {
            hideAnimated(this._view.getChildView("foreign_accounts_container"));
        }


        if (staySignedIn) {
            showAnimated(this._view.getChildView("stay_logged_in_container"));
        } else {
            hideAnimated(this._view.getChildView("stay_logged_in_container"));
        }


        if (successAlert) {
            showAnimated(this._view.getChildView("success_alert"));
        } else {
            hideAnimated(this._view.getChildView("success_alert"));
        }

        if (infoAlert) {
            showAnimated(this._view.getChildView("info_alert"));
        } else {
            hideAnimated(this._view.getChildView("info_alert"));
        }

        if (warningAlert) {
            showAnimated(this._view.getChildView("alert"));
        } else {
            hideAnimated(this._view.getChildView("alert"));
        }

        if (!helpBlocks) {
            hideAnimated(this._view.getChildView("login_box").find(".help-block"));
            this._view.getChildView("login_box").find("input").removeClass("mcaRedStripesBackgroundColor");
        }

        if (email && _.difference(edsApp.model.custom.foreignProviderNames, _.map(providerInfo, function(x){ return x.name; })).length == 0) {
            // this._view.getChildView("go_back_container").hide();
            this._view.getChildView("create_account_container").show();
        } else {
            this._view.getChildView("go_back_container").show();
            // this._view.getChildView("create_account_container").hide();
        }
    }

    performLogin(providerId, providerName, domainId, providerToken, providerEmail, providerUserName) {

        if (this.performingLogin)
            return;

        this.performingLogin = true;

        //Show the spinner. Remove animation classes for the login box.
        this._view.getChildView("stay_logged_in_container").hide();
        this._view.getChildView("alert").slideUp();
        this._view.getChildView("info_alert").slideUp();
        this._view.getChildView("stay_logged_in_container").hide();
        this._view.getChildView("spinner").show();
        this._view.getChildView("login_box").removeClass("animated");

        var loginController = this;

        //Login to the server.
        var loginArgs = {email: this._view.getChildView("email").val(),
                         password: this._view.getChildView("password").val(),
                         magicLinkRequested: this.magicLinkRequested,
                         magicLinkToken: this.magicLinkToken,
                         twoFactorCode: this._view.getChildView("two_factor_code").val() ? edsApp.utilities.custom.sanitizeTotpCode(this._view.getChildView("two_factor_code").val()) : null,
                         twoFactorMethod: this.twoFactorMethod,
                         providerId: providerId,
                         providerName: providerName,
                         domainId: domainId,
                         providerToken: providerToken,
                         stay_signed_in: this._view.getChildView("stay_logged_in").is(':checked'),
                         forgiveness_token: this.forgivenessToken,
                         gmt_offset_in_seconds: new Date().getTimezoneOffset() * (-60),
                         tz: Intl.DateTimeFormat().resolvedOptions().timeZone // ie11: undefined
                        };
        
        this.magicLinkRequested = false; // reset

        edsApp.model.postDataToURL("login", loginArgs, function (success, data) {

            if (success) {

                if (data.domain) {

                    //Send the user of to the domain decision screen.
                    var controller = edsApp.controllers.domain_decision;
                    controller.loginArgs = loginArgs;
                    controller.domain = data.domain;
                    controller.user = data.user;

                    edsApp.controllers.app.pushController(controller, false, {redirectControllerName: loginController.redirectControllerName, redirectControllerArgs: JSON.stringify(loginController.redirectControllerArgs)});

                } else if (data.guidance) {

                    //We need to guide the user.
                    if (data.guidance.account_exists) {

                        if (data.guidance.two_factor_required) {

                            var twoFactorMethods = [];

                            _.each(data.guidance.two_factor_required, function(value, key) {
                                if (value)
                                    twoFactorMethods.push(key);
                            });

                            //We need to come up with a two-factor method.
                            if (!_.contains(twoFactorMethods, loginController.twoFactorMethod)) {
                                if (_.contains(twoFactorMethods, "totp"))
                                    loginController.twoFactorMethod = "totp";
                                else if (_.contains(twoFactorMethods, "text"))
                                    loginController.twoFactorMethod = "text";
                                else if (_.contains(twoFactorMethods, "phone"))
                                    loginController.twoFactorMethod = "phone";
                                else if (_.contains(twoFactorMethods, "email"))
                                    loginController.twoFactorMethod = "email";
                            }

                            loginController._view.getChildView("two_factor_by_text_title").text(edsApp.model.getLocalizedString("two_factor_by_text_short", {number: data.guidance.two_factor_required.text}));
                            loginController._view.getChildView("two_factor_by_phone_title").text(edsApp.model.getLocalizedString("two_factor_by_phone_short", {number: data.guidance.two_factor_required.phone}));
                            loginController._view.getChildView("two_factor_by_email_title").text(edsApp.model.getLocalizedString("two_factor_by_email_short", {email: data.guidance.two_factor_required.email}));

                            if (loginController.twoFactorMethod == "totp") {
                                twoFactorMethods = _.without(twoFactorMethods, "totp");

                                loginController._view.getChildView("info_alert_message").text(edsApp.model.getLocalizedString("input_totp_code"));
                                loginController._view.getChildView("two_factor_code").attr("aria-label", edsApp.model.getLocalizedString("input_totp_code"));
                            } else if (loginController.twoFactorMethod == "text") {
                                loginController._view.getChildView("two_factor_by_text_title").text(edsApp.model.getLocalizedString("two_factor_by_text_again_short", {number: data.guidance.two_factor_required.text}));

                                loginController._view.getChildView("info_alert_message").text(edsApp.model.getLocalizedString("input_text_code", {number: data.guidance.two_factor_required.text}));
                                loginController._view.getChildView("two_factor_code").attr("aria-label", edsApp.model.getLocalizedString("input_text_code", {number: data.guidance.two_factor_required.text}));
                            } else if (loginController.twoFactorMethod == "phone") {
                                loginController._view.getChildView("two_factor_by_phone_title").text(edsApp.model.getLocalizedString("two_factor_by_phone_again_short", {number: data.guidance.two_factor_required.phone}));

                                loginController._view.getChildView("info_alert_message").text(edsApp.model.getLocalizedString("input_phone_code", {number: data.guidance.two_factor_required.phone}));
                                loginController._view.getChildView("two_factor_code").attr("aria-label", edsApp.model.getLocalizedString("input_phone_code", {number: data.guidance.two_factor_required.phone}));
                            } else if (loginController.twoFactorMethod == "email") {
                                loginController._view.getChildView("two_factor_by_email_title").text(edsApp.model.getLocalizedString("two_factor_by_email_again_short", {email: data.guidance.two_factor_required.email}));

                                loginController._view.getChildView("info_alert_message").text(edsApp.model.getLocalizedString("input_email_code", {email: data.guidance.two_factor_required.email}));
                                loginController._view.getChildView("two_factor_code").attr("aria-label", edsApp.model.getLocalizedString("input_email_code", {email: data.guidance.two_factor_required.email}));
                            }

                            loginController.setupLoginUI(false, false, false, false, twoFactorMethods, [], false, false, true, false, true, true);

                            loginController._view.getChildView("two_factor_code").attr("data-eds-validator", "!isNaN(edsApp.utilities.custom.sanitizeTotpCode($('#login_two_factor_code').val()))");
                            loginController.formValidator.reloadFormStatus();
                            loginController._view.getChildView("two_factor_button").prop("disabled", !loginController.formValidator.getFormStatus());

                        } else if (data.guidance.verify_email) {

                            loginController._view.getChildView("alert").slideUp();
                            loginController._view.getChildView("info_alert_message").text(edsApp.model.getLocalizedString("email_verification", {email: data.guidance.verify_email}));
                            loginController._view.getChildView("didnt_get_email").show();
                            loginController._view.getChildView("info_alert").slideDown();

                        } else if (data.guidance.magic_link_token) {

                            // store magic link token so we can check for validation and continue login
                            loginController.magicLinkToken = data.guidance.magic_link_token
                            loginController._view.getChildView("magic_link_message").html(edsApp.model.getLocalizedString("magic_link_message", {email: "<strong>" + loginArgs.email + "</strong>"}));
                            loginController.setupLoginUI(false, false, false, false, false, [], false, false, false, false, false, true);

                        } else {
                            //The problem is the user used the wrong login method. We'll guide them to use the correct one.

                            if (data.guidance.forgiveness_token)
                                loginController.forgivenessToken = data.guidance.forgiveness_token;

                            loginController.setupLoginUI(false, false, data.guidance.has_password, data.guidance.magic_link_eligible, false, data.guidance.foreign_account_provider_info, false, false, false, false, true, true);

                            var hasForeignAccounts = data.guidance.foreign_account_provider_info.length > 0;

                            if (loginArgs.providerName) {
                                //Ask the user to login using their password or a different foreign account.
                                var localizedGuidance;

                                var providerNames = _.map(data.guidance.foreign_account_provider_info, function (x) { return edsApp.model.getLocalizedString(x.name);});

                                if (hasForeignAccounts && data.guidance.has_password) {
                                    localizedGuidance = edsApp.model.getLocalizedString("guidance_use_password_foreign_account", {invalid_provider: edsApp.model.getLocalizedString(loginArgs.providerName), valid_provider: providerNames.join("/")});
                                } else if (hasForeignAccounts) {
                                    localizedGuidance = edsApp.model.getLocalizedString("guidance_use_foreign_account", {invalid_provider: edsApp.model.getLocalizedString(loginArgs.providerName), valid_provider: providerNames.join("/")});
                                } else {
                                    // account uses magic link and possibly a password
                                    localizedGuidance = edsApp.model.getLocalizedString("guidance_use_another_login_method", {invalid_provider: edsApp.model.getLocalizedString(loginArgs.providerName)});
                                }

                                loginController._view.getChildView("alert").slideUp();
                                loginController._view.getChildView("info_alert_message").text(localizedGuidance);
                                loginController._view.getChildView("info_alert").slideDown();
                            }

                            // populate email field so that password and magic link options work
                            if (loginArgs.providerName) {
                                loginController._view.getChildView("email").val(providerEmail)
                            }
                        }

                    } else {

                        //Warn them that their account is non-existent.
                        loginController._view.getChildView("login_box").addClass("animated");
                        loginController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("account_does_not_exist_message"));
                        loginController._view.getChildView("alert").slideDown();
                        loginController._view.getChildView("info_alert").slideUp();
                    }

                } else {
                    //We're logged in!
                    edsApp.utilities.custom.processSuccessfulLogin(
                        loginArgs.stay_signed_in,
                        loginArgs.email,
                        loginArgs.providerName,
                        data,
                        loginController.redirectControllerName,
                        loginController.redirectRecordHistory,
                        loginController.redirectControllerArgs
                    );
                }
            } else {
                var localizedError = edsApp.model.getLocalizedError(data);

                if (data && (data.error == "error_incorrect_password" || data.error == "error_data_invalid_two_factor_code")) {
                    loginController._view.getChildView("login_box").addClass("animated");

                    if (_.has(loginController.retryCounter, loginArgs.email))
                        loginController.retryCounter[loginArgs.email] += 1;
                    else
                        loginController.retryCounter[loginArgs.email] = 1;

                    if (loginController.retryCounter[loginArgs.email] > 4)
                        //Send the user to the forgot password page.
                        edsApp.controllers.app.pushController(edsApp.controllers.forgot_password, false, {redirectControllerName: loginController.redirectControllerName, redirectControllerArgs: JSON.stringify(loginController.redirectControllerArgs), email: loginArgs.email});
                }

                loginController._view.getChildView("alert_message").text(localizedError);
                loginController._view.getChildView("alert").slideDown();
                loginController._view.getChildView("info_alert").slideUp();

                //Required to fix bug with incorrect password auto-filled by the browser.
                if (data && data.error == "error_incorrect_password") {
                    loginController.setupLoginUI(
                        false,
                        false,
                        true,
                        false,
                        false,
                        [],
                        false,
                        false,
                        false,
                        true,
                        false,
                        true);
                }
            }

            // hide spinner unless we're now waiting for the magic link to be validated
            if (!data || !data.guidance || !data.guidance.magic_link_token) {
                loginController._view.getChildView("spinner").hide();
            }
            loginController.performingLogin = false;
        });
    }

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("login_box").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("next_button").prop("disabled", !this._view.getChildView("email")[0].checkValidity());
        this._view.getChildView("login_button").prop("disabled", !this._view.getChildView("password")[0].checkValidity());
        this._view.getChildView("two_factor_button").prop("disabled", !formValidator.getFormStatus());
    }
};