edsApp.init = async function (options={}) {

    //Check for dependencies first.
    if (!self._) {
        var underscoreError = new Error();
        underscoreError.name = edsApp.model.errors.error;
        underscoreError.message = "underscore.js is not loaded.";
        throw underscoreError;
    }

    if (!self.jQuery) {
        var jQueryError = new Error();
        jQueryError.name = edsApp.model.errors.error;
        jQueryError.message = "jQuery is not loaded.";
        throw jQueryError;
    }

    if (!self.jQuery.query) {
        var jQueryObjectError = new Error();
        jQueryObjectError.name = edsApp.model.errors.error;
        jQueryObjectError.message = "jQuery plugin query-object is not loaded.";
        throw jQueryObjectError;
    }

    options.defaultControllerName  = _.isUndefined(options.defaultControllerName) ? "home" : options.defaultControllerName;
    edsApp.model.viewsURL  = _.isUndefined(options.viewsURL) ? "/" : options.viewsURL;
    edsApp.model.ajaxURL  = _.isUndefined(options.ajaxURL) ? "/" : options.ajaxURL;
    edsApp.model.ajaxHeaders  = _.isUndefined(options.ajaxHeaders) ? {} : options.ajaxHeaders;
    edsApp.model.ajaxHandlers  = _.isUndefined(options.ajaxHandlers) ? {} : options.ajaxHandlers;
    edsApp.model.localizedStrings  = _.isUndefined(options.localizedStrings) ? {} : options.localizedStrings;
    edsApp.model.localizedCountries  = _.isUndefined(options.localizedCountries) ? {} : options.localizedCountries;
    edsApp.model.localizedLanguages = _.isUndefined(options.localizedLanguages) ? {} : options.localizedLanguages;
    edsApp.model.googleMapsApiKey = _.isUndefined(options.googleMapsApiKey) ? "" : options.googleMapsApiKey;

    //We add the getChildView() plugin to jQuery.
    (function($){

      $.fn.extend({ getChildView: function(viewName) { return $(this).find("[data-eds-view='" + viewName + "']"); }
      });

    })(jQuery);

    //Now let's make sure the html file contains the container view.
    if ($("body").getChildView("container").length != 1) {
        var containerViewError = new Error();
        containerViewError.name = edsApp.model.errors.error;
        containerViewError.message = "The main html file must contain 1 data-eds-view named 'container'";
        throw containerViewError;
    }

    //We initialize the appController as well as all other controllers. The initial DOM is already loaded at this point.
    for (const aControllerClass of Object.values(edsApp.classes.controllers.custom)) {
        if (aControllerClass.prototype instanceof edsApp.classes.controllers.AppController) {

            if (_.has(edsApp.controllers, "app")) {
                var appError = new Error();
                appError.name = edsApp.model.errors.error;
                appError.message = "Only one App Controller subclass may be present in edsApp.classes.controllers.custom";
                throw appError;
            } else {
                edsApp.controllers.app = new aControllerClass($("body").getChildView("container"));
            }
        } else if (aControllerClass.prototype instanceof edsApp.classes.controllers.ViewController) {

            var newController = new aControllerClass();

            if (!newController.name || _.has(edsApp.controllers, newController.name)) {
                var noNameError = new Error();
                noNameError.name = edsApp.model.errors.error;
                noNameError.message = "ViewController subclasses must contain a non-null, unique name. Duplicate/Illegal controller: " + aControllerClass.toString();
                throw noNameError;
            } else {
                edsApp.controllers[newController.name] = newController;
            }
        } else {
            var inheritanceError = new Error();
            inheritanceError.name = edsApp.model.errors.error;
            inheritanceError.message = "Only ViewController subclasses may be present in edsApp.classes.controllers.custom. Illegal controller: " + aControllerClass.toString();
            throw inheritanceError;
        }
    }

    if (!_.has(edsApp.controllers, options.defaultControllerName)) {
        var noDefaultControllerError = new Error();
        noDefaultControllerError.name = edsApp.model.errors.error;
        noDefaultControllerError.message = "No ViewController subclass found for default name '" +  options.defaultControllerName + "'";
        throw noDefaultControllerError;
    }

    if (!_.has(edsApp.controllers, "app")) {
        var noAppControllerError = new Error();
        noAppControllerError.name = edsApp.model.errors.error;
        noAppControllerError.message = "No AppController subclass is present in edsApp.classes.controllers.custom";
        throw noAppControllerError;
    }

    //we load all the views for the controllers, and assign them accordingly. We also retrieve the controller
    // requested (if any) from the query string and tell the app controller to push it when the view loads.
    var allURLParameters = edsApp.utilities.getAllURLParameters();
    var requestedController = allURLParameters["controller"];

    //See if the controller passed actually exists. If not, redirect them to the default controller.
    if (!_.has(edsApp.controllers, requestedController)) {
        requestedController = options.defaultControllerName;
    }

    //Remove the controller parameter as we don't need it when passing the arguments when pushing the new controller.
    if (_.has(allURLParameters, "controller"))
        delete allURLParameters["controller"];


    //Load the view for the requested controller first to give the illusion of faster page loads.
    var requestOptions = {cache: "default", edsOmitAjaxPath: true};
    var response = await edsApp.model.fetchAsync(edsApp.controllers[requestedController].getViewURL(), requestOptions);

    if (!response.ok) {
        var error = new Error();
        error.name = edsApp.model.errors.IO;
        error.message = "Failed to load view for Controller '" + requestedController + "'. textStatus: " + response.statusText;
        throw error;
    }

    edsApp.controllers[requestedController].setViewHTML(await response.text());
    edsApp.controllers.app.pushController(edsApp.controllers[requestedController], false, allURLParameters);

    //Iterate through all the controllers instantiated in the previous step and load their views using AJAX.
    var responsePromises = [];

    for (const [controllerName, aController] of Object.entries(edsApp.controllers)) {
        if (controllerName !== "app" && controllerName !== requestedController) {

            responsePromises.push((async () => {
                var viewResponse = await edsApp.model.fetchAsync(aController.getViewURL(), requestOptions);

                if (!viewResponse.ok) {
                    var error = new Error();
                    error.name = edsApp.model.errors.IO;
                    error.message = "Failed to load view for Controller '" + aController.name + "'. textStatus: " + viewResponse.statusText;
                    throw error;
                }

                aController.setViewHTML(await viewResponse.text());
            })());
        }
    }

    await Promise.all(responsePromises);
};
