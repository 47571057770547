edsApp.classes.controllers.custom.EmailsController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "emails";
        this.requiresLogin = true;
        this.helpLink = "emails";

        //Table related members.
        this.rowsPerPage = 10;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        this._view.getChildView("new_email").on("click", function (e) {

            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.new_email, true);
        });

        //Setup the tableView.
        var columnNames = [""];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveRows(true);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);
        this.beginFetching();
    };

    //Table data methods as required by SingleTableViewController
    fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        fetchCallbackFn(true, edsApp.model.custom.user.emails);
    };

    getTableViewMessage(data) {
        return edsApp.model.getLocalizedString("emails_table_message");
    };

    //Table delegate functions.
    dataForCell(tableView, row, column, callbackFunction) {

        var email = this.tableData[row];

        if (column == 0) {

            if (row == 0)
                callbackFunction($('<strong></strong>').text(email), "jQueryObject", false, ["text-center"]);
            else
                callbackFunction(email, "text", false, ["edsAppGrayColor", "text-center"]);
        } else {
            callbackFunction(null, "text", false);
        }
    };

    didSelectRow(tableView, row) {

        var email = this.tableData[row];
        edsApp.controllers.app.pushController(edsApp.controllers.email_detail, true, {email: email});
    };
};