edsApp.classes.controllers.custom.MemberInviteSuccessfulController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "member_invite_successful";
        this.requiresLogin = true;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        //None for this controller :)
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this._view.getChildView("licenses").text(
            edsApp.model.getLocalizedString("view_products", {group: a.name})).attr(
                "href", "https://www.rhino3d.com/licenses?" + $.param({controller:"licenses", entityId: a.group_id + "-|-Group", _forceEmpty: "true"})
        );

        this._view.getChildView("group_detail").attr(
            "href", edsApp.utilities.createRelativeURLForController("group_detail", {group_id: a.group_id})
        );
    };
};