edsApp.classes.controllers.custom.ChangePasswordController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        /* Do setup specific to this class. */
        super();
        //Define members.
        this.name = "change_password";
        this.performingRequest = false;
        this.requiresLogin = true;
        this.helpLink = "change_password";
    }

    //Declare methods.
    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Hookup view events to their respective "listeners" or "targets".
        var changePasswordController = this;
        this._view.getChildView("password").on("blur focus", function (e) {
            changePasswordController._view.getChildView("progress_bar_container").slideToggle();
        });

        this._view.getChildView("password").on("change keyup paste focus", function (e) {

            var strength = edsApp.utilities.custom.passwordStrength($(this).val());
            var progressBarDomObject = changePasswordController._view.getChildView("progress_bar");
            var progressBarTextDomObject = changePasswordController._view.getChildView("progress_bar_text");

            progressBarDomObject.attr("aria-valuenow", strength);
            progressBarDomObject.css("width", strength + "%");
            progressBarDomObject.removeClass("progress-bar-danger").removeClass("progress-bar-warning").removeClass("progress-bar-success");

            if (strength < 25) {
                progressBarDomObject.addClass("progress-bar-danger");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("weak"));
            } else if (strength < 50) {
                progressBarDomObject.addClass("progress-bar-warning");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("fair"));
            } else {
                progressBarDomObject.addClass("progress-bar-success");
                progressBarTextDomObject.text(edsApp.model.getLocalizedString("strong"));
            }
        });

        this._view.getChildView("form").on("submit", function (e) {
            if (!changePasswordController.formValidator.getFormStatus() || changePasswordController.performingRequest)
                return;

            changePasswordController.performingRequest = true;
            changePasswordController._view.getChildView("save").hide();
            changePasswordController._view.getChildView("spinner").show();

            var putArgs = {currentPassword: changePasswordController._view.getChildView("current_password").val(),
                           password: changePasswordController._view.getChildView("password").val()};

            edsApp.model.putDataToURL("user", putArgs, function (success, data) {

                if (success) {

                    edsApp.model.custom.user = data;
                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("change_password_success")});

                } else {

                    changePasswordController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    changePasswordController._view.getChildView("alert").slideDown();
                    changePasswordController._view.getChildView("save").show();
                    changePasswordController._view.getChildView("spinner").hide();
                }

                changePasswordController.performingRequest = false;
            });
        });
    }

    willBecomeKeyController(a) {
        super.willBecomeKeyController(a);

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("save").show();

        if (edsApp.model.custom.user.hasPassword)
            this._view.getChildView("current_password_container").show();
        else
            this._view.getChildView("current_password_container").hide();

        //Reset the form.
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("progress_bar_container").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    }

    willResignKeyController() {

        //For security reasons, we clear these fields.
        this._view.getChildView("current_password").val("");
        this._view.getChildView("password").val("");
        this._view.getChildView("confirm_password").val("");

        super.willResignKeyController();
    }

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }
};
