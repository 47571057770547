edsApp.classes.controllers.custom.GroupDetailController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        //Define members.
        this.name = "group_detail";
        this.requiresLogin = true;
        this.groupId = null;
        this.memberGroupIds = [];
        this.adminGroupIds = [];
        this.ownerGroupIds = [];
        this.formValidator = null;
        this.helpLink = "groups";

        //Table related members.
        // this.rowsPerPage = 20; // TODO: 
        this.rowsPerPage = 10;
        this.firstFetch = this.rowsPerPage * 2;
        this.secondFetch = 500;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Hookup view events to their respective "listeners" or "targets".
        var groupDetailController = this;
        this._view.getChildView("save").on("click", function (e) {

            e.preventDefault();

            if (!groupDetailController.formValidator.getFormStatus())
                return;

            groupDetailController._view.getChildView("dropdown_button").hide();
            groupDetailController._view.getChildView("save").hide();
            groupDetailController._view.getChildView("spinner").show();
            groupDetailController._view.getChildView("success_alert").slideUp();

            var putArgs = {group_id: groupDetailController.groupId,
                           name: groupDetailController._view.getChildView("name").val(),
                           description: groupDetailController._view.getChildView("description").val()};

            edsApp.model.putDataToURL("groups", putArgs, function (success, data) {

                if (success) {

                    groupDetailController._view.getChildView("success_alert_message").text(edsApp.model.getLocalizedString("save_successful"));
                    groupDetailController._view.getChildView("success_alert").slideDown();
                    groupDetailController._view.getChildView("title").text(data.name);
                    edsApp.model.clearJSONDataCacheForURLBeginningWith("groups");

                } else {

                    groupDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    groupDetailController._view.getChildView("alert").slideDown();
                }

                groupDetailController._view.getChildView("dropdown_button").show();
                groupDetailController._view.getChildView("save").show();
                groupDetailController._view.getChildView("spinner").hide();
            });
        });

        this._view.getChildView("leave_group").on("click", function (e) {
            e.preventDefault();

            groupDetailController._view.getChildView("dropdown_button").hide();
            groupDetailController._view.getChildView("save").hide();
            groupDetailController._view.getChildView("spinner").show();
            groupDetailController._view.getChildView("success_alert").slideUp();

            edsApp.model.deleteDataAtURL("members", {group_id: groupDetailController.groupId, member_id: edsApp.model.custom.user.id}, function (success, data) {

                if (success) {

                    edsApp.model.custom.user.memberGroups = _.filter(edsApp.model.custom.user.memberGroups, function(x){ return x.id != groupDetailController.groupId; });
                    edsApp.model.custom.user.adminGroups = _.filter(edsApp.model.custom.user.adminGroups, function(x){ return x.id != groupDetailController.groupId; });
                    edsApp.model.custom.user.ownerGroups = _.filter(edsApp.model.custom.user.ownerGroups, function(x){ return x.id != groupDetailController.groupId; });

                    edsApp.model.clearJSONDataCacheForURLBeginningWith("members");
                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("left_group", {name: groupDetailController._view.getChildView("title").text()})});

                } else {

                    groupDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    groupDetailController._view.getChildView("alert").slideDown();
                    groupDetailController._view.getChildView("dropdown_button").show();
                    groupDetailController._view.getChildView("save").show();
                    groupDetailController._view.getChildView("spinner").hide();
                }
            });
        });

        this._view.getChildView("delete_group").on("click", function (e) {
            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.delete_group, true, {group_id: groupDetailController.groupId});
        });

        this._view.getChildView("invite_members").on("click", function (e) {
            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.new_member_invites, true, {group_id: groupDetailController.groupId});
        });

        this._view.getChildView("view_member_invites").on("click", function (e) {
            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.member_invites, true, {group_id: groupDetailController.groupId});
        });

        this._view.getChildView("download_csv").on("click", function (e) {
            e.preventDefault();
            // write members to csv file
            var data = groupDetailController.generateCSV();
            var blob = new Blob(["\ufeff" + data], { type: 'text/csv' }); // add utf-8 bom
            var url = URL.createObjectURL(blob);

            // https://stackoverflow.com/a/49917066
            var a = document.createElement('a');
            a.href = url;
            a.download = groupDetailController.groupId.toString() + '.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });

        this._view.getChildView("select_all").on("click", function(e) {

            e.preventDefault();
            groupDetailController.tableView.checkAllRowsOnPage();
        });

        this._view.getChildView("deselect_all").on("click", function(e) {

            e.preventDefault();
            groupDetailController.tableView.uncheckAllRowsOnPage();
        });

        this._view.getChildView("remove").on("click", function (e) {

            e.preventDefault();

            groupDetailController._view.getChildView("alert").slideUp();
            groupDetailController._view.getChildView("success_alert").slideUp();

            var membersToRemove = groupDetailController.tableData.filter((member) => member._checked === true);

            // DEBUG
            _.each(membersToRemove, (member) => {
                console.log(`REMOVE ${member.email}`);
            });
            console.log(membersToRemove.length)
            // return;

            if (membersToRemove.length == 0)
                return;
            
            const maxMembers = 20
            if (membersToRemove.length > maxMembers) { // see transaction limit in DELETE /members
                groupDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("member_remove_too_many", {number: maxMembers}));
                groupDetailController._view.getChildView("alert").slideDown();
                return;
            }

            // TODO: show modal to confirm removal

            var subs = _.map(membersToRemove, function (x){ return x.sub; });

            groupDetailController._view.getChildView("dropdown_button").hide();
            groupDetailController._view.getChildView("spinner").show();
            groupDetailController._view.getChildView("table").hide();

            edsApp.model.deleteDataAtURL("members", {group_id: groupDetailController.groupId, ids: subs}, function (success, data) {

                if (success) {

                    // refresh table data
                    edsApp.model.clearJSONDataCacheForURLBeginningWith("members");
                    groupDetailController.beginFetching(groupDetailController.firstFetch, groupDetailController.secondFetch);

                    var successMessage = edsApp.model.getLocalizedString("member_remove_success", {number: membersToRemove.length});
                    // if (membersToRemove.length === 1) {
                    //     successMessage = `Successfully removed ${membersToRemove[0].email}.`;
                    // } else {
                    //     successMessage = `Successfully removed ${membersToRemove[0].email} and ${membersToRemove.length - 1} other(s).`;
                    // }
                    groupDetailController._view.getChildView("success_alert_message").text(successMessage);
                    groupDetailController._view.getChildView("success_alert").slideDown();

                } else {

                    groupDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    groupDetailController._view.getChildView("alert").slideDown();
                }

                groupDetailController._view.getChildView("table").show();
                groupDetailController._view.getChildView("dropdown_button").show();
                groupDetailController._view.getChildView("spinner").hide();
            });
        });

        //Setup the tableView.
        var columnNames = [edsApp.model.getLocalizedString("name"), edsApp.model.getLocalizedString("email"), edsApp.model.getLocalizedString("role"), edsApp.model.getLocalizedString("join_date")];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveColumns(true);
        this.tableView.setAllowsInteractiveRows(true);
    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        return (_.has(a, "group_id"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.groupId = a.group_id.toString();

        this.memberGroupIds =  _.pluck(edsApp.model.custom.user.memberGroups, "id");
        this.adminGroupIds =  _.pluck(edsApp.model.custom.user.adminGroups, "id");
        this.ownerGroupIds =  _.pluck(edsApp.model.custom.user.ownerGroups, "id");

        if (a.successMessage) {
            this._view.getChildView("success_alert_message").text(a.successMessage);
            this._view.getChildView("success_alert").show();
        } else {
            this._view.getChildView("success_alert").hide();
        }

        this._view.getChildView("title").text(edsApp.model.getLocalizedString("loading"));
        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("save").hide();
        this._view.getChildView("remove_container").hide();
        this._view.getChildView("invite_members_container").hide();
        this._view.getChildView("view_member_invites_container").hide();
        this._view.getChildView("leave_group_container").hide();
        this._view.getChildView("delete_group_container").hide();
        this._view.getChildView("new_domain_container").hide();
        this._view.getChildView("payment_container").hide();
        this._view.getChildView("csv_container").hide();
        this._view.getChildView("dropdown_button").hide();

        this._view.getChildView("name").val("");
        this._view.getChildView("name").prop("readonly", true);
        this._view.getChildView("description").val("");
        this._view.getChildView("description").prop("readonly", true);

        //Reset the form
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("form").find("textarea").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("create").prop("disabled", !this.formValidator.getFormStatus());

        var groupDetailController = this;
        edsApp.model.getJSONDataForURL("groups", {group_id: this.groupId}, 100, function (success, group) {

            if (success) {
                groupDetailController._view.getChildView("title").text(group.name);
                groupDetailController._view.getChildView("name").val(group.name);
                groupDetailController._view.getChildView("description").val(group.description);
                groupDetailController._view.getChildView("dropdown_button").show();
                groupDetailController._view.getChildView("leave_group_container").show();
                
                if (a.new_group && !a.successMessage) {                    
                    var sam = groupDetailController._view.getChildView("success_alert_message");
                    sam.text(edsApp.model.getLocalizedString("new_group_success", {name: group.name}) + " ");
                    // add invite link to new group success alert
                    var $a = $("<a>").attr("href", "#").attr("class", "alert-link").text(edsApp.model.getLocalizedString("new_group_success_invites"));
                    sam.append($a);
                    $a.on("click", function (e) {
                        e.preventDefault();
                        edsApp.controllers.app.pushController(edsApp.controllers.new_member_invites, true, {group_id: groupDetailController.groupId});
                    });
                    groupDetailController._view.getChildView("success_alert").show();
                }

                if (_.contains(groupDetailController.adminGroupIds,groupDetailController.groupId) || _.contains(groupDetailController.ownerGroupIds,groupDetailController.groupId)) {
                    groupDetailController._view.getChildView("name").prop("readonly", false);
                    groupDetailController._view.getChildView("description").prop("readonly", false);
                    groupDetailController._view.getChildView("save").show();
                    groupDetailController._view.getChildView("new_domain_container").show();
                    groupDetailController._view.getChildView("invite_members_container").show();
                    groupDetailController._view.getChildView("view_member_invites_container").show();
                    groupDetailController._view.getChildView("csv_container").show();
                    groupDetailController._view.getChildView("delete_group_container").show();
                    groupDetailController._view.getChildView("new_domain").attr("href", edsApp.utilities.createRelativeURLForController(edsApp.controllers.domains.name, {group_id: groupDetailController.groupId}));
                    groupDetailController._view.getChildView("remove_container").show();
                }

                if (_.contains(groupDetailController.ownerGroupIds,groupDetailController.groupId)) {
                    groupDetailController._view.getChildView("payment_container").show();
                    groupDetailController._view.getChildView("payment").attr("href", edsApp.utilities.createRelativeURLForController(edsApp.controllers.payment.name, {group_id: groupDetailController.groupId}));
                }

            } else {
                groupDetailController._view.getChildView("title").html($('<span class="glyphicon glyphicon-question-sign"></span>'));
                groupDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(group));
                groupDetailController._view.getChildView("alert").slideDown();
            }
        });

        // first fetch enough rows for two pages and then fetch the rest in manageable chunks
        this.beginFetching(this.firstFetch, this.secondFetch);
    };

    //Table data methods as required by SingleTableViewController
    /*fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        // use pagination to load members because there could be 100s or even 1000s
        edsApp.model.getJSONDataForURL("members", {group_id: this.groupId, offset: offset, limit: numberOfRowsToFetch}, 100, function (success, data) {
            fetchCallbackFn(success, data);
        });
    }; */

    async fetchTableDataAsync(numberOfRowsToFetch, offset, continuationToken) {

        // use pagination to load members because there could be 100s or even 1000s
        var response = await edsApp.model.fetchAsync("members", {
            edsUrlParameters: {group_id: this.groupId, offset: offset, limit: numberOfRowsToFetch}, 
            edsDataCacheTime: 100 
        });

        if (!response.ok)
            throw new Error("Error loading members");

        var results = await response.json(); 

        return [results, results.length >= numberOfRowsToFetch];
    };

    async sortTableDataAsync(columnNumber, sortDescending, data) {
        var predicateFn = null;
        var sortedData = null;
        var sortComplexDataMap = {}; //Used for mapping columns with the data used for sorting them in complex sorting.

        if (columnNumber == 0) {

            predicateFn = function (aMember) {
                if (aMember.name)
                    return aMember.name.toLowerCase();
                else
                    return "";
            };

            return edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);

        } else if (columnNumber == 1) {

            predicateFn = function (aMember) {
                if (aMember.email)
                    return aMember.email.toLowerCase();
                else
                    return "";
            };

            return edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);

        } else if (columnNumber == 2) {

            predicateFn = function (aMember) {
                if (sortComplexDataMap[aMember.sub])
                    return sortComplexDataMap[aMember.sub];
                else
                    return -1;
            };

            //Prepare the data map.
            _.each(data, function (aMember) {

                if (aMember.role == "member") {
                    sortComplexDataMap[aMember.sub] = 0;
                } else if (aMember.role == "admin") {
                    sortComplexDataMap[aMember.sub] = 1;
                } else if (aMember.role == "owner") {
                    sortComplexDataMap[aMember.sub] = 2;
                }
            });

            return edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
        
        } else if (columnNumber == 3) {

            predicateFn = function (aMember) {
                if (aMember.joinDate)
                    return aMember.joinDate;
                else
                    return "";
            };

            return edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);

        } else {
            throw new Error("Invalid column");
        }
    };

    getTableViewMessage(data) {
        return edsApp.model.getLocalizedString("members_total", {number: data.length});
    };

    //Table delegate functions.
    async dataForCellAsync(tableView, row, column) {

        var data = this.tableData[row];

        if (column == 0) {

            return [data.name, "text", false];

        } else if (column == 1) {

            return [data.email, "text", false];

        } else if (column == 2) {

            if (data.role == "member") {
                return [$('<span class="label label-default">' + _.escape(edsApp.model.getLocalizedString('role_member')) + '</strong></span>'), "jQueryObject", false];
            } else if (data.role == "admin") {
                return [$('<span class="label label-info">' + _.escape(edsApp.model.getLocalizedString('role_admin')) + '</strong></span>'), "jQueryObject", false];
            } else if (data.role == "owner") {
                return [$('<span class="label label-primary">' + _.escape(edsApp.model.getLocalizedString('role_owner')) + '</strong></span>'), "jQueryObject", false];
            } else {
                return [null, "text", false];
            }

        } else if (column == 3) {

            if (data.joinDate === null) {
                return [null, "text", false];
            } else {
                return [new Date(data.joinDate).toLocaleString(), "text", false];
            }

        } else {
            return [null, "text", false];
        }
    };

    didSelectRow(tableView, row) {

        var data = this.tableData[row];
        edsApp.controllers.app.pushController(edsApp.controllers.member_detail, true, {member_id: data.sub, group_id: this.groupId});
    };

    didSelectColumnHeader(tableView, columnNumber) {
        //Determine which way to order.
        if (this.sortedColumn == columnNumber) {
            this.sortDescending = !this.sortDescending;
        } else {
            this.sortedColumn = columnNumber;
            this.sortDescending = true;
        }

        this.beginSorting(columnNumber, this.sortDescending);
    };

    // delegate methods for table checkboxes

    checkStateChangedForRow(tableView, row, checked) {
        // console.log(`checkStateChangedForRow: ${this.tableData[row].name}, ${checked}`);
        this.tableData[row]._checked = checked;
    }

    async checkStateForRowAsync(tableView, row) {
        // console.log(`checkStateForRowAsync: ${row}, ${this.tableData[row]._checked}`);
        return {
            "checked": this.tableData[row]._checked === true,
            "enabled": this.tableData[row].role !== "owner"
        }
    }

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }

    generateCSV() {
        // write table data (member info) to csv format string
        var lines = [];
        lines.push('"name","email","sub","role"'); // ignore picture
        for (let i = 0; i < this.tableData.length; i++) {
            var member = this.tableData[i];
            lines.push('"' + member.name + '","' + member.email + '","' + member.sub.toString() + '","' + member.role +'"');
        }
        return lines.join('\n');
    };
};
