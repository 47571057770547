edsApp.classes.views.ModalView = class {
    
	/*This class is a superclass. There is no inheritance. */

	constructor(container) {
		/* Do setup specific to this class. */

	    //Define Members
	    this._modalDomObject = null;
	    this._customDomObject = null;
	    this._buttonDomObject = $("<a class='btn btn-sm'></a>");
	    this._shouldHide = false;
		
		//Perform Initialization.
	    this._modalDomObject = this._createModalDomObject();
	    container.prepend(this._modalDomObject);
	
	    //Attach the necessary events to the table and its dom objects.
	
	    /* Prevent the modal from being closed by the user. */
	    this._modalDomObject.on('hide.bs.modal', {modalView : this}, function (e) {
	
	        if (!e.data.modalView._shouldHide)
	            e.preventDefault();
		});
	}
    
    //Define methods.

    /* Shows the modal */
    show() {
        this._modalDomObject.modal('show');
    }

    /* Hides the modal. */
    hide() {
        this._shouldHide = true;
        this._modalDomObject.modal('hide');
        this._shouldHide = false;
    }

    /* Sets the title of the modal. The 'animated' boolean specifies whether the change should be animated. */
    setTitle(title, animated) {

        if (animated)
            edsApp.utilities.fadeText(this._modalDomObject.find(".modal-header h4"), title);
        else
            this._modalDomObject.find(".modal-header h4").text(title);
    }

    /* Returns the title of the modal.  */
    title() {

        return this._modalDomObject.find(".modal-header h4").text();
    }

    /* Sets the message of the modal. The 'animated' boolean specifies whether the change should be animated. */
    setMessage(message, animated) {

        if (animated)
            edsApp.utilities.fadeText(this._modalDomObject.find(".modal-body h5"), message);
        else
            this._modalDomObject.find(".modal-body h5").text(message);
    };

    /* Returns the message of the modal */
    message() {

        return this._modalDomObject.find(".modal-body h5").text();
    }

    /* Sets the progress value for the receiver's progress bar. The value must be between 0 and 100, inclusive. */
    setProgressValue(value) {

        if (value < 0)
            value = 0;
        else if (value > 100)
            value = 100;

        value = Math.round(value);

        //Update the value.
        var progressBarDomObject = this._modalDomObject.find(".modal-body .progress .progress-bar");
        progressBarDomObject.attr("aria-valuenow", value);
        progressBarDomObject.css("width", value + "%");
    }

    /* Returns the progress value for the receiver's progress bar. The value will be between 0 and 100, inclusive. */
    progressValue() {

        var progressBarDomObject = this._modalDomObject.find(".modal-body .progress .progress-bar");
        return parseInt(progressBarDomObject.attr("aria-valuenow"));
    }

    /* Sets the primary button for the modal, along with a specified callback function to be called when that button
     is clicked. The function has one parameter: event, which is the jQuery event object generated when the button is
     clicked. You can pass null if you do not need to listen for clicks. You may also pass a dictionary containing
     content that will be downloaded to the user's device when the button is clicked. the dictionary has the following
     keys: data, filename. data should be a url. It may derive from a Blob.
     The content will be automatically encoded for you depending on the mimeType.
     If you do not need downloadContent, you can ignore this parameter.
     You may also specify an optional list of classes to apply to the button to customize its look. You
     can pass null if you do not wish to add any custom classes. The 'animated' boolean specifies whether the change
     should be animated.
     */
    setPrimaryButton(title, classes, animated, callbackFn, downloadContent) {

        classes = classes || ["btn-default"];

        var modalView = this;
        var modalFooter = modalView._modalDomObject.find(".modal-footer");
        var newButton = this._buttonDomObject.clone();
        newButton.text(title);
        newButton.attr("data-eds-index", "0");

        //We apply any classes that may have been passed.
        _.each(classes, function (aCSSClass) {
            newButton.addClass(aCSSClass);
        });

        var replaceButtonFn = function () {

            //Remove any previous button.
            modalFooter.find("a[data-eds-index=0]").remove();

            //Append the new button.
            modalFooter.append(newButton);
        };

        if (animated) {
            edsApp.utilities.fadeTransition(modalFooter, replaceButtonFn);
        } else {
            replaceButtonFn();
        }

        //Finally, listen to click events or set the download content.
        if (callbackFn) {
            newButton.on("click", callbackFn);
        } else if (downloadContent) {
            newButton.attr("href", downloadContent.data);
            newButton.attr("download", downloadContent.filename);
        }
    }

    /* Sets the alternate button for the modal, along with a specified callback function to be called when that button
     is clicked. The function has one parameter: event, which is the jQuery event object generated when the button is
     clicked. You can pass null if you do not need to listen for clicks. You may also pass a dictionary containing
     content that will be downloaded to the user's device when the button is clicked. the dictionary has the following
     keys: data, filename. data should be a url. It may derive from a Blob.
     The content will be automatically encoded for you depending on the mimeType.
     If you do not need downloadContent, you can ignore this parameter.
     You may also specify an optional list of classes to apply to the button to customize its look. You
     can pass null if you do not wish to add any custom classes. The 'animated' boolean specifies whether the change
     should be animated.
     */
    setAlternateButton(title, classes, animated, callbackFn, downloadContent) {

        classes = classes || ["btn-default"];

        var modalView = this;
        var modalFooter = modalView._modalDomObject.find(".modal-footer");
        var newButton = this._buttonDomObject.clone();
        newButton.text(title);
        newButton.attr("data-eds-index", "1");

        //We apply any classes that may have been passed.
        _.each(classes, function (aCSSClass) {
            newButton.addClass(aCSSClass);
        });

        var replaceButtonFn = function () {

            //Remove any previous button.
            modalFooter.find("a[data-eds-index=1]").remove();

            //Append the new button.
            modalFooter.prepend(newButton);
        };

        if (animated) {
            edsApp.utilities.fadeTransition(modalFooter, replaceButtonFn);
        } else {
            replaceButtonFn();
        }

        //Finally, listen to click events or set the download content.
        if (callbackFn) {
            newButton.on("click", callbackFn);
        } else if (downloadContent) {
            newButton.attr("href", downloadContent.data);
            newButton.attr("download", downloadContent.filename);
        }
    }

     /* Sets the other button for the modal, along with a specified callback function to be called when that button
     is clicked. The other button appears at the left of the modal.
     The function has one parameter: event, which is the jQuery event object generated when the button is
     clicked. You can pass null if you do not need to listen for clicks. You may also pass a dictionary containing
     content that will be downloaded to the user's device when the button is clicked. the dictionary has the following
     keys: data, filename. data should be a url. It may derive from a Blob.
     The content will be automatically encoded for you depending on the mimeType.
     If you do not need downloadContent, you can ignore this parameter.
     You may also specify an optional list of classes to apply to the button to customize its look. You
     can pass null if you do not wish to add any custom classes. The 'animated' boolean specifies whether the change
     should be animated.
     */
    setOtherButton(title, classes, animated, callbackFn, downloadContent) {

        classes = classes || ["btn-default"];

        var modalView = this;
        var modalFooter = modalView._modalDomObject.find(".modal-footer");
        var newButton = this._buttonDomObject.clone();
        newButton.text(title);
        newButton.attr("data-eds-index", "2");

        //We apply any classes that may have been passed.
        newButton.addClass("pull-left");

        _.each(classes, function (aCSSClass) {
            newButton.addClass(aCSSClass);
        });

        var replaceButtonFn = function () {

            //Remove any previous button.
            modalFooter.find("a[data-eds-index=2]").remove();

            //Append the new button.
            modalFooter.append(newButton);
        };

        if (animated) {
            edsApp.utilities.fadeTransition(modalFooter, replaceButtonFn);
        } else {
            replaceButtonFn();
        }

        //Finally, listen to click events or set the download content.
        if (callbackFn) {
            newButton.on("click", callbackFn);
        } else if (downloadContent) {
            newButton.attr("href", downloadContent.data);
            newButton.attr("download", downloadContent.filename);
        }
    }

    /* Removes all buttons present in the modal. The 'animated' boolean specifies whether the change should be animated. */
    removeAllButtons(animated) {

        var modalFooter = this._modalDomObject.find(".modal-footer");

        if (animated)
            edsApp.utilities.fadeTransition(modalFooter, function () {
                modalFooter.find("a").remove();
            });
        else
            modalFooter.find("a").remove();
    }


    /* Hides the progress bar. The 'animated' boolean specifies whether the change should be animated. */
    hideProgressBar(animated) {

        var progressBar = this._modalDomObject.find(".modal-body .progress");

        if (animated)
            progressBar.fadeOut();
        else
            progressBar.hide();
    }


    /* Shows the progress bar. The 'animated' boolean specifies whether the change should be animated. The classes
       array contains a list of CSS classes that are to be applied to the Bootstrap progress bar to customize its
       appearance. You can pass null in classes if you do not wish to add any classes to the standard progress bar.
     */
    showProgressBar(classes, animated) {

        classes = classes || [];

        var progressBar = this._modalDomObject.find(".modal-body .progress");
        var progressBarChild = progressBar.find(".progress-bar");

        //Remove all classes from progress bar.
        progressBarChild.removeClass();
        progressBarChild.addClass("progress-bar");

        //We apply any classes that may have been passed.
        _.each(classes, function (aCSSClass) {
            progressBarChild.addClass(aCSSClass);
        });

        if (animated)
            progressBar.fadeIn();
        else
            progressBar.show();
    }

    /* Adds a custom view underneath the progress bar and/or the modal's message */
    setCustomView(jQueryObject) {
        this._customDomObject = jQueryObject;
        this._modalDomObject.find(".modal-body").append(jQueryObject);
    }

    /* Removes a custom view previously added to the modal. */
    removeCustomView() {
        if (this._customDomObject)
            this._customDomObject.detach();
    }

    /* Returns a newly created dom object for the modal */
    _createModalDomObject() {

        var modalContainer = $("<div class='modal fade' tabindex='-1' role='dialog' aria-hidden='true'>" +
                                    "<div class='modal-dialog'>" +
                                        "<div class='modal-content'></div>" +
                                     "</div>" +
                                "</div>");

        var modalHeader = $("<div class='modal-header'><h4 class='modal-title text-center'></h4></div>");

        var modalBody = $("<div class='modal-body row'>" +
                            "<div class='col-xs-12'>" +
                                "<h5 class='text-center edsAppDarkGrayColor'></h5>" +
                             "</div>" +
                             "<div class='col-xs-1'></div>" +
                             "<div class='col-xs-10'>" +
                                "<div class='progress'>" +
                                    "<div class='progress-bar' role='progressbar' aria-valuenow='100' aria-valuemin='0' aria-valuemax='100' style='width: 100%'></div>" +
                                "</div>" +
                             "</div>" +
                             "<div class='col-xs-1'></div>" +
                          "</div>");

        var modalFooter = $("<div class='modal-footer'></div>");

        modalContainer.find('.modal-content').append(modalHeader).append(modalBody).append(modalFooter);

        return modalContainer;
    }
};