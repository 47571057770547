edsApp.classes.controllers.custom.PermissionsDetailController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "permissions_detail";
        this.requiresLogin = true;
        this.client_id = null;
        this.tokens = [];
        this.helpLink = "permissions";

        //Table related members.
        this.rowsPerPage = 10;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        var permissionsDetailController = this;
        this._view.getChildView("revoke_access").on("click", function (e) {

            e.preventDefault();

            permissionsDetailController._view.getChildView("revoke_access").hide();
            permissionsDetailController._view.getChildView("spinner").show();

            edsApp.model.deleteDataAtURL("user/tokens", {client_id: permissionsDetailController.client_id}, function (success, data) {

                if (success) {

                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("tokens_deleted_message")});

                } else {

                    permissionsDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    permissionsDetailController._view.getChildView("alert").slideDown();
                    permissionsDetailController._view.getChildView("revoke_access").show();
                    permissionsDetailController._view.getChildView("spinner").hide();
                }
            });
        });

        //Setup the tableView.
        var columnNames = [edsApp.model.getLocalizedString("this_app_can"), edsApp.model.getLocalizedString("until")];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveColumns(true);
    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        return (_.has(a, "client_id"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.client_id = a.client_id;
        this._view.getChildView("title").text("");

        var permissionsDetailController = this;
        edsApp.model.getJSONDataForURL("clients", {client_id: this.client_id}, 100, function (success, client) {

            if (success) {
                permissionsDetailController._view.getChildView("title").text(client.name);
            } else {
                permissionsDetailController._view.getChildView("title").append($('<span class="glyphicon glyphicon-question-sign"></span>'));
            }
        });

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("revoke_access").show();

        this.beginFetching();
    };

    //Table data methods as required by SingleTableViewController
    fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        var permissionsDetailController = this;

        //We ignore incremental loading and load all tokens at once.
        edsApp.model.getJSONDataForURL("user/tokens", {client_id: this.client_id}, 0, function (success, data) {

            var scope = [];

            if (success) {
                permissionsDetailController.tokens = data;
                _.each(data, function (aToken) {
                    scope = _.union(aToken.scope, scope);
                });
            }

            fetchCallbackFn(success, scope);
        });
    };

    sortTableData(columnNumber, sortDescending, data, sortCallbackFn) {

        var predicateFn = null;
        var sortedData = null;
        var sortComplexDataMap = {}; //Used for mapping columns with the data used for sorting them in complex sorting.

        if (columnNumber == 0) {

            predicateFn = function (aGrant) {
                if (sortComplexDataMap[aGrant])
                    return sortComplexDataMap[aGrant].toLowerCase();
                else
                    return "";
            };

            //Prepare the data map.
            _.each(data, function (aGrant) {
               sortComplexDataMap[aGrant] = edsApp.model.getLocalizedString("grant_" + aGrant);
            });

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);

        } else if (columnNumber == 1) {

            predicateFn = function (aGrant) {
                if (sortComplexDataMap[aGrant])
                    return sortComplexDataMap[aGrant].getTime();
                else
                    return "";
            };

            //Prepare the data map.
            _.each(data, function (aGrant) {

                var tokensForGrant = _.filter(permissionsDetailController.tokens, function (aToken) { return _.contains(aToken.scope, aGrant) });
                var futuremostToken = _.max(tokensForGrant, function(aToken){ return new Date(aToken.expirationDate).getTime() });
                sortComplexDataMap[aGrant] = new Date(futuremostToken.expirationDate);
            });

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);

        } else {
            sortCallbackFn(false, null);
        }
    };

    getTableViewMessage(data) {
        return "";
    };

    //Table delegate functions.
    dataForCell(tableView, row, column, callbackFunction) {

        var data = this.tableData[row];

        if (column == 0) {

            callbackFunction(edsApp.model.getLocalizedString("grant_" + data), "text", false);

        } else if (column == 1) {

            var tokensForGrant = _.filter(this.tokens, function (aToken) { return _.contains(aToken.scope, data) });
            var futuremostToken = _.max(tokensForGrant, function(aToken){ return new Date(aToken.expirationDate).getTime() });
            var expDate = new Date(futuremostToken.expirationDate);
            callbackFunction(expDate.toLocaleString(), "text", false);

        } else {
            callbackFunction(null, "text", false);
        }
    };

    popoverDataForRow(tableView, row, callbackFn) {

        var tableData = this.tableData[row];
        callbackFn(edsApp.model.getLocalizedString("grant_" + tableData + "_description"), "text");
    };

    didSelectColumnHeader(tableView, columnNumber) {
        //Determine which way to order.
        if (this.sortedColumn == columnNumber) {
            this.sortDescending = !this.sortDescending;
        } else {
            this.sortedColumn = columnNumber;
            this.sortDescending = true;
        }

        this.beginSorting(columnNumber, this.sortDescending);
    };
};
