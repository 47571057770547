/* First we load dependencies */
// @codekit-append "../eds/js/jquery.query-object.js"

/* Now we load the namespace for the application */
// @codekit-append "../eds/js/eds-namespace.js"

/* Now we load the utilities and model layer of the application */
// @codekit-append "../eds/js/eds-utilities.js"
// @codekit-append "../eds/js/eds-utilities-form_validator.js"
// @codekit-append "../eds/js/eds-model.js"

/* Now we load all the view classes. Note that we load the superclasses (if any) before we load any subclasses */
// @codekit-append "../eds/js/eds-view-table.js"
// @codekit-append "../eds/js/eds-view-modal.js"

/* Now we load all the controller classes. Note that we load the superclasses (if any) before we load any subclasses */
// @codekit-append "../eds/js/eds-controller-app.js"
// @codekit-append "../eds/js/eds-controller-view.js"
// @codekit-append "../eds/js/eds-controller-container_view.js"
// @codekit-append "../eds/js/eds-controller-single_table_view.js"

/* Now we load the init script */
// @codekit-append "../eds/js/eds-init.js"

/* Now we load the custom utilities and custom model layer of the application */
// @codekit-append "mca-utilities.js"

/* Now we load all the custom controller classes. Note that we load the superclasses (if any) before we load any subclasses */
// @codekit-append "mca-controller-app.js"
// @codekit-append "mca-controller-auth.js"
// @codekit-append "mca-controller-change_password.js"
// @codekit-append "mca-controller-email_detail.js"
// @codekit-append "mca-controller-emails.js"
// @codekit-append "mca-controller-home.js"
// @codekit-append "mca-controller-login.js"
// @codekit-append "mca-controller-login_settings.js"
// @codekit-append "mca-controller-magic_link.js"
// @codekit-append "mca-controller-new_email.js"
// @codekit-append "mca-controller-new_email_confirm.js"
// @codekit-append "mca-controller-new_user_create.js"
// @codekit-append "mca-controller-permissions.js"
// @codekit-append "mca-controller-permissions_detail.js"
// @codekit-append "mca-controller-user_detail.js"
// @codekit-append "mca-controller-two_factor_settings.js"
// @codekit-append "mca-controller-foreign_accounts.js"
// @codekit-append "mca-controller-foreign_account_detail.js"
// @codekit-append "mca-controller-new_foreign_account.js"
// @codekit-append "mca-controller-forgot_password.js"
// @codekit-append "mca-controller-reset_password.js"
// @codekit-append "mca-controller-delete_account.js"
// @codekit-append "mca-controller-groups.js"
// @codekit-append "mca-controller-new_group.js"
// @codekit-append "mca-controller-group_detail.js"
// @codekit-append "mca-controller-delete_group.js"
// @codekit-append "mca-controller-member_detail.js"
// @codekit-append "mca-controller-new_member_invites.js"
// @codekit-append "mca-controller-member_invite_confirm.js"
// @codekit-append "mca-controller-domain_decision.js"
// @codekit-append "mca-controller-new_domain.js"
// @codekit-append "mca-controller-verify_email.js"
// @codekit-append "mca-controller-member_invite_successful.js"
// @codekit-append "mca-controller-payment.js"
// @codekit-append "mca-controller-payment_detail.js"
// @codekit-append "mca-controller-member_invites.js"
// @codekit-append "mca-controller-domains.js"


/* Finally, we load the init script, which will initialize the application using all the previously appended classes */
// @codekit-append "mca-init.js"