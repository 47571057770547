edsApp.utilities.getURLParameterByName = function (name, url) {
    //This is using jquery-plugin-query-object for simplicity.
    if (_.isUndefined(url)) {
		url = window.location.search;
    }

	return $.query.parseNew(url).get(name);
};


edsApp.utilities.getAllURLParameters = function (url) {
    //This is using jquery-plugin-query-object for simplicity.
    //Notice we clone the result so we can safely add/edit parameters later.
    if (_.isUndefined(url)) {
		url = window.location.search;
    }

	return _.clone($.query.parseNew(url).keys);
};

edsApp.utilities.createRelativeURLForController = function(controllerName, a = {}) {

	var url = "?controller=" + controllerName;

	if (!_.isEmpty(a)) {
	    url = url + "&" + $.param(a);
	}

	return url;
}


edsApp.utilities.sortedArrayWithPredicate = function (predicateFn, unsortedArray, descending) {

    //Sort the array.
    var sortedArray = _.sortBy(unsortedArray, predicateFn);

    if (descending)
        sortedArray.reverse();

    return sortedArray;
};


edsApp.utilities.fadeTransition = function (jqueryObject, behindTheScenesFn) {

    //jQuery 3 and higher will automatically take advantage of HTML5 GPU-based animations.
    jqueryObject.fadeOut(125, "swing", function (){

        behindTheScenesFn();
        jqueryObject.fadeIn(125, "swing");
    });
};


edsApp.utilities.fadeText = function (jQueryObject, newText) {

    edsApp.utilities.fadeTransition(jQueryObject, function () {

        jQueryObject.text(newText);
    })
};


edsApp.utilities.numberToDecimalString = function (number, zeroPadding, decimalPlaces, plusSign) {

    plusSign = plusSign || false;

    var numberOfValuesLeftOfDecimalPoint = Math.floor(Math.abs(number)).toString().length;
    var numberAsString = Math.abs(number).toFixed(decimalPlaces); //We'll tack on the negative sign if needed later.

    for (var i = numberOfValuesLeftOfDecimalPoint; i < zeroPadding; i++) {
        numberAsString = "0" + numberAsString;
    }

    if (number < 0) {
        numberAsString = "-" + numberAsString
    } else if (plusSign) {
        numberAsString = "+" + numberAsString;
    }

    return numberAsString;
};


edsApp.utilities.localDateFromHTML5DateString = function (dateString, timeString) {

    //We return a Date object with the absolute time of the date passed at midnight in the local timezone. For example,
    // if the date passed is 2014-09-24 and we are in Miami in the summer, we return a date with an absolute value of
    // 2014-09-24T00:00:00 EST (which is the same as 2014-09-24T04:00:00 UTC).
    var ISODateString = dateString + (timeString ? ("T" + timeString + ":00.000Z") : "T00:00:00.000Z");
    var uncorrectedDate = new Date(ISODateString); //this returns the date passed at midnight in UTC.
    var offsetInMilliseconds = uncorrectedDate.getTimezoneOffset() * 60 * 1000; //secs * millis

    return new Date(uncorrectedDate.getTime() + offsetInMilliseconds);
};


edsApp.utilities.localHTML5DateStringFromDate = function (date) {

    //We return an HTML5 date string taking into account the local timezone. For example, if the date passed has an
    //absolute value of 2014-09-24T04:00:00 UTC and we are in Seattle in the summer (UTC -7), this method will return
    //'2014-09-23', because the local time in seattle is 2014-09-23T21:00:00 UTC-7.
    var offsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000; //secs * millis
    var correctedDate = new Date(date.getTime() - offsetInMilliseconds);

    return correctedDate.toISOString().substring(0, 10);
};

edsApp.utilities.localHTML5TimeStringFromDate = function (date) {

    //We return an HTML5 date string taking into account the local timezone. For example, if the date passed has an
    //absolute value of 2014-09-24T04:00:00 UTC and we are in Seattle in the summer (UTC -7), this method will return
    //'2014-09-23', because the local time in seattle is 2014-09-23T21:00:00 UTC-7.
    var offsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000; //secs * millis
    var correctedDate = new Date(date.getTime() - offsetInMilliseconds);

    return correctedDate.toISOString().substring(11, 16);
};


edsApp.utilities.localCSVDatetimeStringFromDate = function (date) {

    //We return a CSV date string (yyyy-MM-dd HH:mm:ss) taking into account the local timezone. For example,
    // if the date passed has an absolute value of 2014-09-24T04:00:00 UTC and we are in Seattle in the summer (UTC-7),
    // this method will return '2014-09-23 21:00:00', because the local time in seattle is 2014-09-23T21:00:00 UTC-7.
    var offsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000; //secs * millis
    var correctedDate = new Date(date.getTime() - offsetInMilliseconds);

    return correctedDate.toISOString().substring(0, 19).replace('T', ' ');
};


edsApp.utilities.timezoneAsStringForDate = function (date) {

    //For now, we always use UTC +/- offset.
    var offsetInMinutes = -1 * date.getTimezoneOffset();
    var offsetHours = Math.floor(offsetInMinutes / 60);
    var offsetMinutes = offsetInMinutes % 60;

    offsetHours = edsApp.utilities.numberToDecimalString(offsetHours, 2, 0, true);
    offsetMinutes = edsApp.utilities.numberToDecimalString(offsetMinutes, 2, 0, false);

    return "UTC" + offsetHours + offsetMinutes;
};


edsApp.utilities.arrayBufferToBase64String = function ( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;

    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[i] );
    }

    return window.btoa( binary );
};


edsApp.utilities.appendLanguageIdToSelectDomObject = function (selectDomObject, languageId) {

    if (!languageId)
        return;

    var optionDomObject = selectDomObject.find("option[value='" + languageId + "']");

    if (optionDomObject.length > 0) {
        //If it already exists, select it.
        optionDomObject.prop("selected", true)
    } else {
        //Create a new option for it. If the language id includes a locale, we find it.
        var languageOption = $("<option value='" + languageId +"'></option>");
        languageOption.text(edsApp.model.getLocalizedLanguage(languageId));
        selectDomObject.prepend(languageOption);
        languageOption.prop("selected", true)
    }
};


edsApp.utilities.appendSortedCountryIdsToSelectDomObject = function (selectDomObject) {

    if (_.isUndefined(edsApp.utilities._sortedLocalizedCountries)) {

        edsApp.utilities._sortedLocalizedCountries = [];

        _.each(edsApp.model.localizedCountries, function (aCountryName, aCountryId) {
            if (aCountryId != "zz")
                edsApp.utilities._sortedLocalizedCountries.push({countryId: aCountryId, countryName: aCountryName});
        });

        var predicateFn = function (aCountry) {
               return aCountry.countryName.toLowerCase();
        };

        edsApp.utilities._sortedLocalizedCountries = edsApp.utilities.sortedArrayWithPredicate(predicateFn, edsApp.utilities._sortedLocalizedCountries, false);
        edsApp.utilities._sortedLocalizedCountries.push({countryId: "zz", countryName: edsApp.model.localizedCountries["zz"]});
    }

    var countryOptionDomObject = $("<option value=''></option>");

    _.each(edsApp.utilities._sortedLocalizedCountries, function(aLocalizedCountry) {

        var countryOption = countryOptionDomObject.clone().val(aLocalizedCountry.countryId);
        countryOption.text(aLocalizedCountry.countryName);
        selectDomObject.append(countryOption);
    });
};


edsApp.utilities.appendSortedLanguageIdsToSelectDomObject = function (selectDomObject) {

    if (_.isUndefined(edsApp.utilities._sortedLocalizedLanguages)) {

        var priorityLanguageIds = ["en", "es", "de", "fr", "it", "ja", "ko", "zh-tw", "zh-cn"]; //These ones are always at the top. 
        var priorityLanguages = [];

        _.each(priorityLanguageIds, function (aLanguageId) {
            priorityLanguages.push({languageId: aLanguageId, languageName: edsApp.model.getLocalizedLanguage(aLanguageId)});
        });

        var otherLanguages = [];

        _.each(edsApp.model.allLanguageIds, function (aLanguageId) {
            if (!_.contains(priorityLanguageIds, aLanguageId))
                otherLanguages.push({languageId: aLanguageId, languageName: edsApp.model.getLocalizedLanguage(aLanguageId)});
        });

        var predicateFn = function (aLanguage) {
               return aLanguage.languageName.toLowerCase();
        };

        otherLanguages = edsApp.utilities.sortedArrayWithPredicate(predicateFn, otherLanguages, false);

        edsApp.utilities._sortedLocalizedLanguages = priorityLanguages.concat(otherLanguages);
    }

    var languageOptionDomObject = $("<option value=''></option>");

    _.each(edsApp.utilities._sortedLocalizedLanguages, function(aLocalizedLanguage) {

        var languageOption = languageOptionDomObject.clone().val(aLocalizedLanguage.languageId);
        languageOption.text(aLocalizedLanguage.languageName);
        selectDomObject.append(languageOption);
    });
};


edsApp.utilities.invokeFunctionWithDelay = function (delayInMilliseconds, fn) {

    window.setTimeout(fn, delayInMilliseconds);
};


/**
 * Creates a promise that will resovle after the specified delay. 
 * @param {int} delayInMilliseconds the number of milliseconds to delay.
 * @returns {Promise} A promise that will resolve after the delay specified.
 */
edsApp.utilities.delayAsync = function (delayInMilliseconds) {
    return new Promise(r => window.setTimeout(r, delayInMilliseconds));
}