edsApp.classes.controllers.custom.MemberDetailController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "member_detail";
        this.requiresLogin = true;
        this.groupId = null;
        this.memberId = null;
        this.helpLink = "groups";
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this._view.getChildView("phone").popover();

        //Hookup view events to their respective "listeners" or "targets".
        var memberDetailController = this;

        this._view.getChildView("save").on("click", function (e) {
            e.preventDefault();

            memberDetailController._view.getChildView("remove").hide();
            memberDetailController._view.getChildView("save").hide();
            memberDetailController._view.getChildView("spinner").show();

            edsApp.model.putDataToURL("members", {group_id: memberDetailController.groupId, member_id: memberDetailController.memberId, role: memberDetailController._view.getChildView("role").val()}, function (success, data) {

                if (success) {

                    edsApp.model.clearJSONDataCacheForURLBeginningWith("members");
                    edsApp.controllers.app.pushController(edsApp.controllers.group_detail, true, {successMessage: edsApp.model.getLocalizedString("save_successful"), group_id: memberDetailController.groupId});

                } else {

                    memberDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    memberDetailController._view.getChildView("alert").slideDown();
                    memberDetailController._view.getChildView("remove").show();
                    memberDetailController._view.getChildView("save").show();
                    memberDetailController._view.getChildView("spinner").hide();
                }
            });
        });

        this._view.getChildView("remove").on("click", function (e) {
            e.preventDefault();

            memberDetailController._view.getChildView("remove").hide();
            memberDetailController._view.getChildView("save").hide();
            memberDetailController._view.getChildView("spinner").show();

            edsApp.model.deleteDataAtURL("members", {group_id: memberDetailController.groupId, member_id: memberDetailController.memberId}, function (success, data) {

                if (success) {

                    if (memberDetailController.memberId == edsApp.model.custom.user.id) {
                        edsApp.model.custom.user.memberGroups = _.filter(edsApp.model.custom.user.memberGroups, function(x){ return x.id != memberDetailController.memberId; });
                        edsApp.model.custom.user.adminGroups = _.filter(edsApp.model.custom.user.adminGroups, function(x){ return x.id != memberDetailController.memberId; });
                        edsApp.model.custom.user.ownerGroups = _.filter(edsApp.model.custom.user.ownerGroups, function(x){ return x.id != memberDetailController.memberId; });
                        edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("left_group", {name:memberDetailController._view.getChildView("subtitle").text()})});

                    } else {

                        edsApp.model.clearJSONDataCacheForURLBeginningWith("members");
                        edsApp.controllers.app.pushController(edsApp.controllers.group_detail, true, {successMessage: edsApp.model.getLocalizedString("member_removed"), group_id: memberDetailController.groupId});
                    }

                } else {

                    memberDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    memberDetailController._view.getChildView("alert").slideDown();
                    memberDetailController._view.getChildView("remove").show();
                    memberDetailController._view.getChildView("save").show();
                    memberDetailController._view.getChildView("spinner").hide();
                }
            });
        });
    };

    canBecomeKeyController(a) {
        //Note we are overriding the superclass implementation.
        return (_.has(a, "group_id") && _.has(a, "member_id"));
    };


    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.groupId = a.group_id.toString();
        this.memberId = a.member_id.toString();

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("role").prop("disabled", true);
        this._view.getChildView("save").hide();
        this._view.getChildView("remove").hide();
        this._view.getChildView("title").text(edsApp.model.getLocalizedString("loading"));
        this._view.getChildView("subtitle").text("");
        this._view.getChildView("picture").attr("src", "/media/silhouette.png");
        this._view.getChildView("email").text(edsApp.model.getLocalizedString("loading"));
        this._view.getChildView("join_date").html("<span class='glyphicon glyphicon-question-sign'></span>");

        var memderDetailController = this;
        edsApp.model.getJSONDataForURL("members", {group_id: memderDetailController.groupId}, 100, function (success, members) {

            var member;

            if (success && (member = _.find(members, function (aMember) { return aMember.sub == memderDetailController.memberId; }))) {

                memderDetailController._view.getChildView("title").text(member.name);
                memderDetailController._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("loading"));
                memderDetailController._view.getChildView("email").text(member.email);
                memderDetailController._view.getChildView("role").val(member.role);

                if (member.joinDate) {
                    memderDetailController._view.getChildView("join_date").text(new Date(member.joinDate).toLocaleString());
                }

                if (member.picture)
                    memderDetailController._view.getChildView("picture").attr("src", member.picture);

                if (_.findWhere(edsApp.model.custom.user.adminGroups,{id: memderDetailController.groupId}) || _.findWhere(edsApp.model.custom.user.ownerGroups,{id: memderDetailController.groupId})) {
                    memderDetailController._view.getChildView("save").show();
                    memderDetailController._view.getChildView("remove").show();
                    memderDetailController._view.getChildView("role").prop("disabled", false);
                }

                var user = edsApp.model.custom.user;
                var allGroups = user.memberGroups.concat(user.adminGroups).concat(user.ownerGroups);
                var group = _.findWhere(allGroups, {id: memderDetailController.groupId});

                if (group)
                    memderDetailController._view.getChildView("subtitle").text(group.name);
                else
                    memderDetailController._view.getChildView("subtitle").html($('<span class="glyphicon glyphicon-question-sign"></span>'));

            } else {

                memderDetailController._view.getChildView("title").html($('<span class="glyphicon glyphicon-question-sign"></span>'));
                memderDetailController._view.getChildView("email").html($('<span class="glyphicon glyphicon-question-sign"></span>'));
                memderDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(members));
                memderDetailController._view.getChildView("alert").slideDown();
            }
        });
    };
};
