edsApp.classes.controllers.custom.ForeignAccountsController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        //Define members.
        this.name = "foreign_accounts";
        this.requiresLogin = true;
        this.helpLink = "foreign_accounts";

        //Table related members.
        this.rowsPerPage = 10;
    }

    //Define Functions.
    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        this._view.getChildView("new_foreign_account").on("click", function (e) {

            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.new_foreign_account, true);
        });

        //Setup the tableView.
        var columnNames = [""];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveRows(true);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);
        this.beginFetching();
    };

    //Table data methods as required by SingleTableViewController
    fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        fetchCallbackFn(true, edsApp.model.custom.user.foreignAccounts);
    };

    getTableViewMessage(data) {
        if (data.length == 0)
            return edsApp.model.getLocalizedString("no_foreign_accounts_table_message");
        else
             return edsApp.model.getLocalizedString("foreign_accounts_table_message");
    };

    //Table delegate functions.
    dataForCell(tableView, row, column, callbackFunction) {

        var account = this.tableData[row];
        var labelText = account.providerEmail ? account.providerEmail : account.providerId;

        if (column == 0) {
            callbackFunction($('<span class="edsAppHorizontalSpaced label ' + edsApp.utilities.custom.getLabelStyleForProvider(account.providerName) + '"><strong> ' + _.escape(edsApp.model.getLocalizedString(account.providerName)) + '</strong></span><span class="edsAppHorizontalSpaced label label-default">' + _.escape(labelText) + '</span>'), "jQueryObject", false, ["text-center"]);
        } else {
            callbackFunction(null, "text", false);
        }
    };

    didSelectRow(tableView, row) {
        var account = this.tableData[row];
        edsApp.controllers.app.pushController(edsApp.controllers.foreign_account_detail, true, {providerName: account.providerName, providerId: account.providerId});
    };
};
