edsApp.classes.controllers.custom.NewEmailConfirmController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "new_email_confirm";
        this.helpLink = "emails";
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);
        this._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("new_email_confirm_message", {"email": a.email}));
    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        return (_.has(a, "email"));
    };
};
