edsApp.classes.controllers.custom.DomainsController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        //Define members.
        this.name = "domains";
        this.requiresLogin = true;
        this.helpLink = "link_domain";
        this.groupId = null;

        //Table related members.
        this.rowsPerPage = 10;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        //None for this controller.

        //Setup the tableView.
        var columnNames = [edsApp.model.getLocalizedString("domain"), edsApp.model.getLocalizedString("login_provider")];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveColumns(true);
        this.tableView.setAllowsInteractiveRows(true);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.groupId = a.group_id.toString();

        var user = edsApp.model.custom.user;
        var allGroups = user.memberGroups.concat(user.adminGroups).concat(user.ownerGroups);
        var group = _.findWhere(allGroups, {id: this.groupId});
        this._view.getChildView("subtitle").text(group ? group.name : "");
        this._view.getChildView("new_domain").attr("href", edsApp.utilities.createRelativeURLForController(edsApp.controllers.new_domain.name, {group_id: this.groupId}));

        this.beginFetching();
    };

    //Table data methods as required by SingleTableViewController
    async fetchTableDataAsync(numberOfRowsToFetch, offset, continuationToken) {

        var response = await edsApp.model.fetchAsync("domain", {
            edsUrlParameters: {group_id: this.groupId}, 
            edsDataCacheTime: 100 
        });

        if (!response.ok)
            throw new Error("Error loading domains");

        var results = await response.json(); 

        return [results, false];
    };

    async sortTableDataAsync(columnNumber, sortDescending, data) {
        var predicateFn = null;
        var sortedData = null;
        var sortComplexDataMap = {}; //Used for mapping columns with the data used for sorting them in complex sorting.

        if (columnNumber == 0) {

            predicateFn = function (aDomain) {
                if (aDomain.domain)
                    return aDomain.domain.toLowerCase();
                else
                    return "";
            };

            return edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);

        } else if (columnNumber == 1) {
            predicateFn = function (aDomain) {
                if (aDomain.loginProviderName)
                    return edsApp.model.getLocalizedString(aDomain.loginProviderName);
                else
                    return "";
            };

            return edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
        } else {
            throw new Error("Invalid column");
        }
    };

    getTableViewMessage(data) {
        if (data.length > 0)
            return edsApp.model.getLocalizedString("domains_total", {number: data.length});
        else
            return edsApp.model.getLocalizedString("zero_domains");
    };

    //Table delegate functions.
    async dataForCellAsync(tableView, row, column) {

        var data = this.tableData[row];

        if (column == 0) {

            return [data.domain, "text", false];

        } else if (column == 1) {

            return [$('<span class="edsAppHorizontalSpaced label ' + edsApp.utilities.custom.getLabelStyleForProvider(data.loginProviderName) + '"><strong> ' + _.escape(edsApp.model.getLocalizedString(data.loginProviderName)) + '</strong></span>'), "jQueryObject", false];

        } else {
            return [null, "text", false];
        }
    };

    didSelectRow(tableView, row) {

        var data = this.tableData[row];
        edsApp.controllers.app.pushController(edsApp.controllers.new_domain, true, {group_id: data.group.id, domain: data.domain});
    };

    didSelectColumnHeader(tableView, columnNumber) {
        //Determine which way to order.
        if (this.sortedColumn == columnNumber) {
            this.sortDescending = !this.sortDescending;
        } else {
            this.sortedColumn = columnNumber;
            this.sortDescending = true;
        }

        this.beginSorting(columnNumber, this.sortDescending);
    };
};
