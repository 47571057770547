edsApp.classes.controllers.custom.ForeignAccountDetailController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "foreign_account_detail";
        this.requiresLogin = true;
        this.foreignAccount = null;
        this.helpLink = "foreign_accounts";
    }

    //Define Functions.
    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        var foreignAccountDetailController = this;
        this._view.getChildView("remove").on("click", function (e) {

            foreignAccountDetailController._view.getChildView("button_container").hide();
            foreignAccountDetailController._view.getChildView("spinner").show();

            //Remove the email address.
            var putArgs = {foreignAccounts: _.reject(edsApp.model.custom.user.foreignAccounts, function (anAccount) {return anAccount === foreignAccountDetailController.account})};
            edsApp.model.putDataToURL("user", putArgs, function (success, data) {

                if (success) {

                    edsApp.model.custom.user = data;
                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("foreign_account_deleted_message", {provider: foreignAccountDetailController.account.providerName})});

                } else {

                    foreignAccountDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    foreignAccountDetailController._view.getChildView("alert").slideDown();
                    foreignAccountDetailController._view.getChildView("button_container").show();
                    foreignAccountDetailController._view.getChildView("spinner").hide();
                }
            });
        });
    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        return (_.has(a, "providerId") && _.has(a, "providerName"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.account = _.find(edsApp.model.custom.user.foreignAccounts, function (anAccount) {
            return anAccount.providerId == a.providerId && anAccount.providerName == a.providerName;
        });

        var labelText;

        if (this.account)
            labelText = this.account.providerEmail ? this.account.providerEmail : this.account.providerId;
        else
            labelText = a.providerId;

        var title = $('<span class="edsAppHorizontalSpaced label ' + edsApp.utilities.custom.getLabelStyleForProvider(a.providerName) + '"><strong> ' + _.escape(edsApp.model.getLocalizedString(a.providerName)) + '</strong></span><span class="edsAppHorizontalSpaced label label-default">' + _.escape(labelText) + '</span>')

        this._view.getChildView("title").empty().append(title);

        this._view.getChildView("alert").hide();
        this._view.getChildView("info_alert").hide();
        this._view.getChildView("button_container").hide();
        this._view.getChildView("spinner").hide();

        if (!this.account) {
            this._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("foreign_account_deleted_message", {provider: edsApp.model.getLocalizedString(a.providerName)}));
            this._view.getChildView("alert").show();
        } else if (!edsApp.model.custom.user.hasPassword && edsApp.model.custom.user.foreignAccounts.length == 1) {
            // TODO: only prevent members of domain-linked teams from removing all foreign accounts
            // TODO: update string: cannot_edit_foreign_account
            this._view.getChildView("info_alert_message").text(edsApp.model.getLocalizedString("cannot_edit_foreign_account"));
            this._view.getChildView("info_alert").show();
        } else {
            this._view.getChildView("button_container").show();
        }

    };
};