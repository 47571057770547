edsApp.classes.utilities.FormValidator = class {
    /*This class is a superclass. There is no inheritance. */

	constructor(formDomObject, delegate, immediatelyReportsValidChanges, immediatelyReportsInvalidChanges) {
		/* Do setup specific to this class. */
		
		//Define Members.
	    this.formDomObject = formDomObject;
	    this.immediatelyReportsValidChanges = immediatelyReportsValidChanges;
	    this.immediatelyReportsInvalidChanges = immediatelyReportsInvalidChanges;
	    this.delegate = delegate;
		
		var formValidator = this;
		
		//Define event listeners.
	    this.formDomObject.on("change keyup focus input", "input, textarea", function (e) {
	        formValidator._evaluateInputField(this, false, true);
	    });
	
	    this.formDomObject.on("blur", "input, textarea", function (e) {
	        formValidator._evaluateInputField(this, true, true);
	    });
	
	    $(document).ready(function() {
	        //Perform initialization on custom validators to make sure getFormStatus returns the right answer.
	        formValidator.reloadFormStatus();
	    });
	}
	
    //Define private methods.
    _evaluateInputField(inputField, forceReporting, report) {
		
		var formValidator = this;
		
        if (formValidator.isValidationSupported()) {

            //Get the title attribute, if available.
            var title = $(inputField).attr("title");

            //Reset any custom errors set before.
            if (inputField.validity.customError)
                inputField.setCustomValidity("");

            //Get a validator, if available.
            var validator = $(inputField).attr("data-eds-validator");

            if (validator) {

                var r = false;

                try {
                    r = new Function("return " + validator)();
                } catch (err) {
                    var evalError = new Error();
                    evalError.name = edsApp.model.errors.error;
                    evalError.message = "data-eds-validator function is illegal: " + validator + " error:" + err;
                    throw evalError;
                }

                if (r) {
                    inputField.setCustomValidity("")
                } else {
                    inputField.setCustomValidity("VALIDATOR_ERROR");
                }
            }

            var errorMessage;

            if (!inputField.checkValidity()) {
                errorMessage = title ? title : inputField.validationMessage;
                $(inputField).attr("aria-invalid", "true"); //For screen readers.
            } else {
                errorMessage = "";
                $(inputField).removeAttr("aria-invalid"); //For screen readers.
            }

            if (report) {
                if (inputField.checkValidity() && formValidator.immediatelyReportsValidChanges) {
                    formValidator.delegate.contentDidChangeForInput(formValidator, $(inputField), errorMessage);
                } else if (!inputField.checkValidity() && formValidator.immediatelyReportsInvalidChanges) {
                    formValidator.delegate.contentDidChangeForInput(formValidator, $(inputField), errorMessage);
                } else if (forceReporting) {
                    formValidator.delegate.contentDidChangeForInput(formValidator, $(inputField), errorMessage);
                }
            }
        }
    };

    //Define Public Functions.
    isValidationSupported() {
        return this.formDomObject[0].checkValidity ? true : false;
    };

    getFormStatus() {
        if (this.isValidationSupported())
            return this.formDomObject[0].checkValidity();
        else
            return true;
    };

    reloadFormStatus() {
		var formValidator = this;
		
        this.formDomObject.find("input[data-eds-validator], textarea[data-eds-validator]").each(function () {
            formValidator._evaluateInputField(this, false, false);
        });
    };
};