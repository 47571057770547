edsApp.classes.controllers.custom.GroupsController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        //Define members.
        this.name = "groups";
        this.requiresLogin = true;
        this.helpLink = "groups";

        //Table related members.
        this.rowsPerPage = 10;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        var permissionsDetailController = this;
        this._view.getChildView("new_group").on("click", function (e) {

            e.preventDefault();
            edsApp.controllers.app.pushController(edsApp.controllers.new_group, true, {});

        });

        //Setup the tableView.
        var columnNames = [edsApp.model.getLocalizedString("name"), edsApp.model.getLocalizedString("role")];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveColumns(true);
        this.tableView.setAllowsInteractiveRows(true);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);
        this.beginFetching();
    };

    //Table data methods as required by SingleTableViewController
    fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        //We load all the groups belonging to the current user at once.
        var currentUser = edsApp.model.custom.user;
        var allGroups = currentUser.memberGroups.concat(currentUser.adminGroups).concat(currentUser.ownerGroups);
        fetchCallbackFn(true, allGroups);
    };

    sortTableData(columnNumber, sortDescending, data, sortCallbackFn) {
        var predicateFn = null;
        var sortedData = null;
        var sortComplexDataMap = {}; //Used for mapping columns with the data used for sorting them in complex sorting.

        if (columnNumber == 0) {

            predicateFn = function (aGroup) {
                if (aGroup.name)
                    return aGroup.name.toLowerCase();
                else
                    return "";
            };

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);

        } else if (columnNumber == 1) {

            predicateFn = function (aGroup) {
                if (sortComplexDataMap[aGroup.id])
                    return sortComplexDataMap[aGroup.id];
                else
                    return -1;
            };

            //Prepare the data map.
            _.each(data, function (aGroup) {

                if (_.contains(edsApp.model.custom.user.memberGroups, aGroup)) {
                    sortComplexDataMap[aGroup.id] = 0;
                } else if (_.contains(edsApp.model.custom.user.adminGroups, aGroup)) {
                    sortComplexDataMap[aGroup.id] = 1;
                } else if (_.contains(edsApp.model.custom.user.ownerGroups, aGroup)) {
                    sortComplexDataMap[aGroup.id] = 2;
                }
            });

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);

        } else {
            sortCallbackFn(false, null);
        }
    };

    getTableViewMessage(data) {
        if (data.length > 0)
            return edsApp.model.getLocalizedString("groups_total", {number: data.length});
        else
            return edsApp.model.getLocalizedString("zero_groups");
    };

    //Table delegate functions.
    dataForCell(tableView, row, column, callbackFunction) {

        var data = this.tableData[row];

        if (column == 0) {

            callbackFunction(data.name, "text", false);
 
        } else if (column == 1) {

            if (_.contains(edsApp.model.custom.user.memberGroups, data)) {
                callbackFunction($('<span class="label label-default">' + _.escape(edsApp.model.getLocalizedString('role_member')) + '</strong></span>'), "jQueryObject", false);
            } else if (_.contains(edsApp.model.custom.user.adminGroups, data)) {
                callbackFunction($('<span class="label label-info">' + _.escape(edsApp.model.getLocalizedString('role_admin')) + '</strong></span>'), "jQueryObject", false);
            } else if (_.contains(edsApp.model.custom.user.ownerGroups, data)) {
                callbackFunction($('<span class="label label-primary">' + _.escape(edsApp.model.getLocalizedString('role_owner')) + '</strong></span>'), "jQueryObject", false);
            } else {
                callbackFunction(null, "text", false);
            }

        } else {
            callbackFunction(null, "text", false);
        }
    };

    didSelectRow(tableView, row) {

        var data = this.tableData[row];
        edsApp.controllers.app.pushController(edsApp.controllers.group_detail, true, {group_id: data.id});
    };

    didSelectColumnHeader(tableView, columnNumber) {
        //Determine which way to order.
        if (this.sortedColumn == columnNumber) {
            this.sortDescending = !this.sortDescending;
        } else {
            this.sortedColumn = columnNumber;
            this.sortDescending = true;
        }

        this.beginSorting(columnNumber, this.sortDescending);
    };
};
