/** @private */
edsApp.model._handleResponsePromiseAsync = async function(responsePromise) {
    var response = await responsePromise;
                
    if (_.has(edsApp.model.ajaxHandlers, response.status)) {

        var responseClone = response.clone();
        var responseText = "";
        try {
            responseText = await responseClone.text();
        } catch {
            //Ignore the error.
        }

        edsApp.model.ajaxHandlers[response.status](responseText, response.url);
    }

    return response.clone();
}

/**
 * Performs an AJAX request asynchronously using the Fetch API with the most modern protocol available in the client.
 * This function replaces classic edsApp AJAX methods, but uses the same underlying in-memory cache so cached requests made with the classic 
 * functions will be recognized by this function and vice-versa. This allows a seamless migration to this API over time.
 * Unless overridden, this function will append the default ajax path and request headers to every request.
 * @param {string} url The url of the request.
 * @param {object} options The same options object sent with a Fetch API request. In addition to the standard options,
 * there are eds-specific options that may optionally be specified to mimic classic behavior. (See below)
 * @param {boolean} options.edsOmitAjaxPath Same behavior as classic eds methods. Set to true to omit the default ajax path.
 * @param {object} options.edsUrlParameters A dictionary of values that will be url encoded and added to the url as query string args.
 * @param {int} options.edsDataCacheTime Same behavior as classic eds methods. Number of seconds to cache the Response object. 
 * If not specified, the request will not be cached in memory.
 * @returns {Promise<Response>} A promise containing a Fetch API Response object. 
 * 
 */
edsApp.model.fetchAsync = async function (url, options) {

    options = _.clone(options); //We clone the options in case they are reused at a higher level by the callers.

    if (options.edsOmitAjaxPath) {
        delete options.edsOmitAjaxPath;
    } else {
        //Make the url absolute.
        url = edsApp.model.ajaxURL + url;
    }

    if (options.edsUrlParameters) {
        //Edit the url by serializing all the urlParameters and appending them.
        if (!_.isEmpty(options.edsUrlParameters))
            url = url + "?" + $.param(options.edsUrlParameters);

        delete options.edsUrlParameters;
    } 

    var dataCacheTime = 0;

    if (options.edsDataCacheTime) {
        dataCacheTime = options.edsDataCacheTime;
        delete options.edsDataCacheTime;
    }

    if (options.headers) {

        if (!(options.headers instanceof Headers)) {
            //Convert any functions into values.
            options.headers = _.mapObject(options.headers, function(val) {
                return _.isFunction(val) ? val() : val;
            });

            options.headers = new Headers(options.headers);
        }

    } else {
        //Add default headers.
        options.headers = _.mapObject(edsApp.model.ajaxHeaders, function(val) {
            return _.isFunction(val) ? val() : val;
        });

        options.headers = new Headers(options.headers);
    }

    //If no content-type is specified, we assume it's JSON.
    if (options.method && options.method.toUpperCase() != "GET" && !options.headers.has("Content-Type"))
        options.headers.append("Content-Type", "application/json");

    //If cache behavior is not specified, we assume no-store to prevent stale data.
    if (!options.cache)
        options.cache = "no-store";
    
    if ((!options.method || options.method.toUpperCase() == "GET") && dataCacheTime > 0) {
        //This request is eligible for caching.
        if (_.has(edsApp.model._cachedData, url)) {

            var cachedEntry = edsApp.model._cachedData[url];

            //Check if the cached data is expired.
            var currentTime = new Date();

            if (cachedEntry.loaded && currentTime.getTime() > cachedEntry.expirationDate.getTime()) {
                //Since it's expired, get rid of the entry. We'll create a new one below.
                delete edsApp.model._cachedData[url];
                delete options.edsDataCacheTime;
            } else {
                //We met all the criteria, so we return a clone of the response.
                return await edsApp.model._handleResponsePromiseAsync(cachedEntry.responsePromise);
            }
        } 
        
        //Initiate a fetch request, and store the data in the cache.
        var newCachedEntry = { 
            loaded: false,
            responsePromise : fetch(url, options),
            expirationDate: null
        };

        edsApp.model._cachedData[url] = newCachedEntry;
        var response = null;

        try {
            response = await edsApp.model._handleResponsePromiseAsync(newCachedEntry.responsePromise);
        } catch (e) {
            //Get rid of the entry. We don't cache requests that could not be completed.
            delete edsApp.model._cachedData[url];
            throw e;
        }

        if (response.ok) {
            newCachedEntry.expirationDate = new Date(new Date().getTime() + dataCacheTime*1000);
            newCachedEntry.loaded = true;
        } else {
            //Get rid of the entry. We don't cache requests that status codes greater than 299.
            delete edsApp.model._cachedData[url];
        }

        return response;

    } else {
        //This request is not eligible for caching, so we simply forward the response.
        return await edsApp.model._handleResponsePromiseAsync(fetch(url, options));
    }
}


/**
 * Asynchronously loads a Javascript file from the given URL. 
 * @param {string} url The URL of the script.
 * @param {object} urlParameters A ditionary of values that will be url encoded and added to the url as query string args.
 * @param {string} crossOrigin The script's crossorigin attribute, if any.
 * @param {string} integrity The script's integrity attribute, if any.
 * @returns {Promise} A Promise that will resolve when the script has been successfuly loaded.
 */
edsApp.model.fetchScriptAsync = function (url, urlParameters, crossOrigin, integrity) {

    if (urlParameters) {
        //Edit the url by serializing all the urlParameters and appending them.
        if (!_.isEmpty(urlParameters))
            url = url + "?" + $.param(urlParameters);
    } 

    //See if there's already a cached entry.
    if (_.has(edsApp.model._cachedScripts, url)) {
        //Return the existing promise.
        return edsApp.model._cachedScripts[url];
    }

    var onErrorCalled = false;

    //Create a new promise.
    var scriptPromise = new Promise((resolve, reject) => {
        //Create a new script tag.
        var script = document.createElement('script');

        //Attach event listeners.
        script.onload = resolve;
        
        script.onerror = (error) => {
            onErrorCalled = true;

            console.log(`Error loading script with url: ${url}. Error: ${error}`);

            //We might get here before fetchScriptAsync can add the promise to _cachedScripts, so we must check first.
            if (_.has(edsApp.model._cachedScripts, url)) {
                delete edsApp.model._cachedScripts[url];
            }

            if (document.body)
                document.body.remove(script);

            reject();
        };

        script.async = true;

        if (crossOrigin)
            script.crossOrigin = crossOrigin;

        if (integrity)
            script.integrity = integrity;

        script.src = url;
        document.body.appendChild(script);
    });

    //Don't re-insert the promise if onerror has already been invoked.
    if (!onErrorCalled) {
        edsApp.model._cachedScripts[url] = scriptPromise;
    }

    return scriptPromise;
};

/**
 * 
 * @deprecated use fetchAsync instead.
 */
edsApp.model.getJSONDataForURL = function (url, urlParameters, dataCacheTime, callbackFn, omitAjaxPath, customHeaders, customTimeout) {

    options = {
        method: "GET",
        edsUrlParameters: urlParameters,
        edsDataCacheTime: dataCacheTime,
        edsOmitAjaxPath: omitAjaxPath,
        headers: customHeaders
    }

    if (customTimeout && AbortSignal.timeout)
        options.signal = AbortSignal.timeout(customTimeout * 1000);

    edsApp.model.fetchAsync(url, options).then((response) => {
        
        response.json().then((result) => {

            if (response.ok) {
                callbackFn(true, result);
            } else {
                callbackFn(false, result);
            }

        }).catch((error) => {

            if (response.ok) {
                callbackFn(true, undefined); //Mimic classic behavior for empty responses.
            } else {
                console.log(`Error in getJSONDataForURL. URL: ${url}. Error: ${error}`);
                callbackFn(false, undefined);
            }
        });
            
    }).catch((error) => {
        console.log(`Error in getJSONDataForURL. URL: ${url}. Error: ${error}`);
        callbackFn(false, undefined);
    });
};


/**
 * 
 * @deprecated use fetchScriptAsync instead.
 */
edsApp.model.getScriptForURL = function (absoluteURL, urlParameters, callbackFn) {

    edsApp.model.fetchScriptAsync(absoluteURL, urlParameters).then(() => {
        callbackFn(true);
    }).catch(() => {
        callbackFn(false);
    });
};


/**
 * 
 * @deprecated use fetchAsync instead.
 */
edsApp.model._postOrPutOrDeleteDataToURL = function (requestType, url, headers, data, timeoutInSeconds, callbackFn, omitAjaxPath) {

    if (!_.isUndefined(data)) {
        data = data || {};
    }

    options = {
        method: requestType,
        headers: headers,
        body: JSON.stringify(data),
        edsOmitAjaxPath: omitAjaxPath
    }

    if (timeoutInSeconds && AbortSignal.timeout)
        options.signal = AbortSignal.timeout(timeoutInSeconds * 1000);

    edsApp.model.fetchAsync(url, options).then((response) => {
        
        response.json().then((result) => {

            if (response.ok) {
                callbackFn(true, result);
            } else {
                callbackFn(false, result);
            }

        }).catch((error) => {

            if (response.ok) {
                callbackFn(true, undefined); //Mimic classic behavior for empty responses.
            } else {
                console.log(`Error in _postOrPutOrDeleteDataToURL. URL: ${url}. Error: ${error}`);
                callbackFn(false, undefined);
            }
        });
            
    }).catch((error) => {
        console.log(`Error in _postOrPutOrDeleteDataToURL. URL: ${url}. Error: ${error}`);
        callbackFn(false, undefined);
    });
};

/**
 * 
 * @deprecated use fetchAsync instead.
 */
edsApp.model.postDataToURL = function (url, data, callbackFn, omitAjaxPath, customHeaders, customTimeout) {

    edsApp.model._postOrPutOrDeleteDataToURL("POST", url, customHeaders, data, customTimeout, callbackFn, omitAjaxPath);
};


/**
 * 
 * @deprecated use fetchAsync instead.
 */
edsApp.model.putDataToURL = function (url, data, callbackFn, omitAjaxPath, customHeaders, customTimeout) {

    edsApp.model._postOrPutOrDeleteDataToURL("PUT", url, customHeaders, data, customTimeout, callbackFn, omitAjaxPath);
};


/**
 * 
 * @deprecated use fetchAsync instead.
 */
edsApp.model.deleteDataAtURL = function (url, urlParameters, callbackFn, omitAjaxPath, customHeaders, customTimeout) {

    //Edit the url by serializing all the urlParameters and appending them.
    if (urlParameters)
        url = url + "?" + $.param(urlParameters);

    edsApp.model._postOrPutOrDeleteDataToURL("DELETE", url, customHeaders, undefined, customTimeout, callbackFn, omitAjaxPath);
};

/** @private */
edsApp.model._getGoogleMapsScriptCallback = function () {

    _.each(edsApp.model._getGoogleMapsCallbackFnQueue, function (aCallbackFn) {
        aCallbackFn(true);
    });

    //Delete the callbackFns and set the queue to null
    edsApp.model._getGoogleMapsCallbackFnQueue = null;
};


edsApp.model.getGoogleMapsScript = function (callbackFn) {


    //The first step is to download the setup code from google.
    var urlParameters = {key: edsApp.model.googleMapsApiKey,
                         callback: "edsApp.model._getGoogleMapsScriptCallback"};

    edsApp.model.getScriptForURL("//maps.googleapis.com/maps/api/js", urlParameters, function (success) {

        //Once we've got the setup code, we need to ensure that it is setup before we invoke the callbackFn.
        if (success) {

            if (_.isNull(edsApp.model._getGoogleMapsCallbackFnQueue)) {
                //If the google maps api has already been loaded, we invoke the callback fn directly.
                callbackFn(true);
            } else {
                //We put the callbackFn in a queue that _getGoogleMapsScriptCallback will invoke.
                edsApp.model._getGoogleMapsCallbackFnQueue.push(callbackFn);
            }

        } else {
            callbackFn(false);
        }
    })
};


edsApp.model.clearJSONDataCache = function () {

    var cachedDataClone = _.clone(edsApp.model._cachedData);

    _.each(cachedDataClone, function(cachedData, key) {
            delete edsApp.model._cachedData[key];
    });
};


edsApp.model.clearJSONDataCacheForURLBeginningWith = function (beginningOfURL, omitAjaxPath) {

    //Make a clone of the cached data since we are mutating it.
    if (!omitAjaxPath)
        beginningOfURL = edsApp.model.ajaxURL + beginningOfURL;

    var cachedDataClone = _.clone(edsApp.model._cachedData);

    _.each(cachedDataClone, function(cachedData, key) {

        //See if the key begins with the string passed and it is a JSON object.
        if (key.indexOf(beginningOfURL) == 0)
            delete edsApp.model._cachedData[key];
    });
};


edsApp.model.getLocalStorageValueForKey = function (key) {

    //We account for the possibility that local storage is not available (i.e. private browsing mode is enabled).
    //This method returns undefined for keys that have not been set or if localStorage is not available.
    // Otherwise it returns the value for the key.
    try {
        var rawValue = window.localStorage.getItem(key);

        if (rawValue === null) {
            return undefined;
        } else {
            return JSON.parse(rawValue);
        }
    } catch (err) {
        return undefined;
    }
};


edsApp.model.setLocalStorageValueForKey = function (value, key) {

    //WARNING: You cannot store any sensitive values in local storage. They will be persisted in the client in plain
    // text by most browsers.
    //NOTE: The key must be a string. The value can be any object that does not have callable (function) properties.
    if (_.isUndefined(value))
        return;

    window.localStorage.setItem(key, JSON.stringify(value));
};


edsApp.model.removeLocalStorageValueForKey = function (key) {
    window.localStorage.removeItem(key);
};


edsApp.model.clearLocalStorage = function () {

    window.localStorage.clear();
};

edsApp.model.getLocalStorageKeysStartingWith = function(prefix) {

    var result = [];

    for ( var i = 0, len = localStorage.length; i < len; ++i ) {

        var key = window.localStorage.key( i );

        if (key.indexOf(prefix) == 0) {
            result.push(key);
        }
    }

    return result;
};

edsApp.model.getLocalizedString = function (name, stringArguments) {

    if (_.has(edsApp.model.localizedStrings, name)) {

        var localizedString = edsApp.model.localizedStrings[name];

        if (!_.isUndefined(stringArguments)) {
            try {
                //If we have inline variables, let's try to render them.
                var template = _.template(localizedString);
                localizedString = template(stringArguments);
            } catch (err) {
                //If there is an error (probably because the string changed), print the keys and the arguments.
                localizedString += " {";

                _.each(stringArguments, function(value, key) {
                    localizedString += key + ": " + value + ",";
                });

                localizedString += " }";
            }
        }

        return localizedString;
    } else {

        if (!_.isUndefined(stringArguments)) {
            //If there are inline variables, print the keys and the arguments.
            name += " {";

            _.each(stringArguments, function(value, key) {
                name += key + ": " + value + ",";
            });

            name += " }";
        }

        return name;
    }
};


edsApp.model.getLocalizedLanguage = function (name) {

    if (_.has(edsApp.model.localizedLanguages, name)) {
        return edsApp.model.localizedLanguages[name]
    } else if (name.length >= 5) {
        return edsApp.model.getLocalizedLanguage(name.substring(0,2)) + " (" + edsApp.model.getLocalizedCountry(name.substring(3,5)) + ")";
    } else {
        return name;
    }
};


edsApp.model.getLocalizedError = function(error, includeDetails) {

    if (error && _.has(edsApp.model.localizedStrings, error.error)) {

        var result =  edsApp.model.getLocalizedString(error.error);

        if (includeDetails && _.has(error, "details"))
            return result + " (" + error.details + ")";
        else
            return result;

    } else {

        return edsApp.model.getLocalizedString("request_failed");
    }
};


edsApp.model.getLocalizedCountry = function (name) {

    if (_.has(edsApp.model.localizedCountries, name))
        return edsApp.model.localizedCountries[name];
    else
        return name;
};


edsApp.model.errors = {};
edsApp.model.errors.error = "eds_error";
edsApp.model.errors.IO = "eds_error_io";
edsApp.model.errors.cannotBecomeKeyController = "eds_error_cannot_become_key_controller";


edsApp.model._cachedData = {}; //Used by fetchAsync


edsApp.model._cachedScripts = {}; //Used by fetchScriptAsync


edsApp.model._getGoogleMapsCallbackFnQueue = [];


edsApp.model.allLanguageIds = ["en", "es", "de", "fr", "it", "ja", "ko", "zh-tw", "zh-cn", "gu", "gd", "ga", "gn", "gl",
                               "ty", "ln", "lo", "tr", "ts", "li", "lv", "lt", "tk", "th", "ti", "te", "ta", "yi", "yo",
                               "da", "kn", "el", "eo", "gv", "ee", "ru", "rw", "kl", "rm", "rn", "ro", "bn", "be", "bg",
                               "ba", "wa", "wo", "bm", "jv", "bo", "bh", "bi", "br", "bs", "om", "oc", "tw", "os", "or",
                               "xh", "ch", "co", "ca", "ce", "cy", "cs", "cr", "cv", "cu", "ps", "pt", "pa", "pi", "ak",
                               "pl", "hz", "hy", "hr", "ht", "hu", "hi", "ho", "ha", "he", "mg", "ml", "mn", "mi", "mh",
                               "mk", "ur", "mt", "uk", "mr", "my", "sq", "aa", "ab", "ae", "ve", "af", "tn", "vi", "is",
                               "am", "an", "ay", "ik", "ar", "km", "ia", "az", "id", "as", "ks", "nl", "nn", "no", "na",
                               "nb", "nd", "ne", "ng", "vo", "ig", "kv", "ku", "fy", "fa", "kk", "ff", "fj", "ky", "fo",
                               "ka", "kg", "ss", "sr", "ki", "sw", "sv", "su", "st", "sk", "kr", "si", "kj", "kw", "sn",
                               "sm", "sl", "sc", "sa", "sg", "se", "lg", "lb"];


edsApp.model.viewsURL = null;


edsApp.model.ajaxURL = null;


edsApp.model.ajaxHeaders = {};


edsApp.model.googleMapsApiKey = null;


edsApp.model.emptyImageData = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOYXQ8AAbgBG6qripMAAAAASUVORK5CYII=";