edsApp.classes.controllers.custom.VerifyEmailController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();
        /* Do setup specific to this class. */

        //Define members.
        this.name = "verify_email";
        this.redirectControllerArgs = null;
        this.redirectControllerName = null;
        this.token = null;
        this.email = null;
        this.helpLink = "recover_account";
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        var verifyEmailController = this;

        this._view.getChildView("copy_to").on("click", function (e) {
            e.preventDefault();
            verifyEmailController.copyStringToClipboard(verifyEmailController._view.getChildView("to").val());
        });

        this._view.getChildView("copy_subject").on("click", function (e) {
            e.preventDefault();
            verifyEmailController.copyStringToClipboard(verifyEmailController._view.getChildView("subject").val());
        });

        this._view.getChildView("copy_message").on("click", function (e) {
            e.preventDefault();
            verifyEmailController.copyStringToClipboard(verifyEmailController._view.getChildView("message").val());
        });

        this._view.getChildView("check_status").on("click", function (e) {

            e.preventDefault();
            verifyEmailController._view.getChildView("list_container").hide();
            verifyEmailController._view.getChildView("subtitle").text("");
            verifyEmailController._view.getChildView("spinner").show();
            verifyEmailController._view.getChildView("status").text(edsApp.model.getLocalizedString("checking_status"));

            //Check the status of the email verifciation.
            var maxCheckTimeInSeconds = 300; //After this time, we give up polling.
            var startTime = new Date();

            var checkStatus = function checkStatusRecursiveFn() {

                edsApp.model.getJSONDataForURL("reverse-confirm-user", {token: verifyEmailController.token}, 1, function (success, verified) {

                    if (success) {

                        //Redirect user to login controller w/ redirect args, if present.
                        if (verified) {

                            var loginControllerArgs = {
                                redirectControllerName: verifyEmailController.redirectControllerName,
                                redirectControllerArgs: verifyEmailController.redirectControllerArgs,
                                successMessage: edsApp.model.getLocalizedString("email_confirmation_message", {email: verifyEmailController.email})
                            };

                            edsApp.controllers.app.pushController(edsApp.controllers.login, true, loginControllerArgs);
                        }
                        else if (new Date().getTime() - startTime.getTime() < (maxCheckTimeInSeconds * 1000)) {
                            edsApp.utilities.invokeFunctionWithDelay(10000, checkStatus);
                        } else {
                            verifyEmailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("verify_email_error", {email: verifyEmailController.email}));
                            verifyEmailController._view.getChildView("alert").slideDown();
                            verifyEmailController._view.getChildView("spinner").hide();
                            verifyEmailController._view.getChildView("status").text("");
                        }

                    } else {
                        verifyEmailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(status));
                        verifyEmailController._view.getChildView("alert").slideDown();
                        verifyEmailController._view.getChildView("spinner").hide();
                        verifyEmailController._view.getChildView("status").text("");
                    }
                });
            };

            checkStatus();
        });
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.redirectControllerName = a.redirectControllerName ? a.redirectControllerName : "home";
        this.redirectControllerArgs = a.redirectControllerArgs ? a.redirectControllerArgs : "{}";
        this.email = a.email;

        this._view.getChildView("spinner").show();
        this._view.getChildView("status").show().text(edsApp.model.getLocalizedString("loading"));
        this._view.getChildView("list_container").hide();
        this._view.getChildView("alert").hide();
        this._view.getChildView("ghost_input").hide();

        var verifyEmailController = this;

        //Create a verification token with the email given.
        edsApp.model.postDataToURL("reverse-confirm-user", {email: a.email}, function (success, token) {

            if (success) {

                verifyEmailController.token = token;
                verifyEmailController._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("verify_email_subtitle", {email: a.email}));
                verifyEmailController._view.getChildView("step_1").text(edsApp.model.getLocalizedString("verify_email_step_1", {email: a.email}));
                verifyEmailController._view.getChildView("message").val(edsApp.model.getLocalizedString("email_message_body", {token: token}));

                verifyEmailController._view.getChildView("open_email_client").attr("href", verifyEmailController.prepareMailToLink({
                    to: verifyEmailController._view.getChildView("to").val(),
                    subject: verifyEmailController._view.getChildView("subject").val(),
                    textBody: edsApp.model.getLocalizedString("email_message_body", {token: token})
                }));

                verifyEmailController._view.getChildView("list_container").show();
            } else {
                verifyEmailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(token));
                verifyEmailController._view.getChildView("alert").slideDown();
            }

             verifyEmailController._view.getChildView("spinner").hide();
             verifyEmailController._view.getChildView("status").text("");
        });
    };

    copyStringToClipboard(string) {
        var $ghost_input = this._view.getChildView("ghost_input");
        $ghost_input.val(string);
        $ghost_input.show().select();
        document.execCommand('copy');
        $ghost_input.hide();
    };

    prepareMailToLink(renderedMessage) {
        return "mailto:" + renderedMessage.to + "?subject=" + encodeURIComponent(renderedMessage.subject) + "&body=" + encodeURIComponent(renderedMessage.textBody);
    };
};
