edsApp.classes.controllers.custom.EmailDetailController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "email_detail";
        this.requiresLogin = true;
        this.email = null;
        this.helpLink = "emails";
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this._view.getChildView("make_primary").popover();

        var emailDetailController = this;
        this._view.getChildView("remove").on("click", function (e) {

            emailDetailController._view.getChildView("button_container").hide();
            emailDetailController._view.getChildView("spinner").show();

            //Remove the email address.
            var putArgs = {emails: _.reject(edsApp.model.custom.user.emails, function (email) {return email == emailDetailController.email})};

            edsApp.model.putDataToURL("user", putArgs, function (success, data) {

                if (success) {

                    edsApp.model.custom.user = data;
                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("email_deleted_message")});

                } else {

                    emailDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    emailDetailController._view.getChildView("alert").slideDown();
                    emailDetailController._view.getChildView("button_container").show();
                    emailDetailController._view.getChildView("spinner").hide();
                }
            });
        });

        this._view.getChildView("make_primary").on("click", function (e) {

            emailDetailController._view.getChildView("button_container").hide();
            emailDetailController._view.getChildView("spinner").show();

            //Make this email address the first one in the emails array.
            var putArgs = {emails: _.union([emailDetailController.email], edsApp.model.custom.user.emails)};

            edsApp.model.putDataToURL("user", putArgs, function (success, data) {

                if (success) {

                    edsApp.model.custom.user.emails = data.emails;
                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("email_made_primary_message", {email: emailDetailController.email})});

                } else {

                    emailDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    emailDetailController._view.getChildView("alert").slideDown();
                    emailDetailController._view.getChildView("button_container").show();
                    emailDetailController._view.getChildView("spinner").hide();
                }
            });
        });
    };

    canBecomeKeyController(a) {
        //Note we are overriding the superclass implementation.
        return (_.has(a, "email"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this._view.getChildView("title").text(a.email);
        this.email = a.email;
        var numberOfEmails = edsApp.model.custom.user.emails.length;

        this._view.getChildView("alert").hide();
        this._view.getChildView("info_alert").hide();
        this._view.getChildView("button_container").hide();
        this._view.getChildView("make_primary").show();
        this._view.getChildView("spinner").hide();

        if (!_.contains(edsApp.model.custom.user.emails, a.email)) {
            this._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("email_deleted_message"));
            this._view.getChildView("alert").show();
        } else if (numberOfEmails == 1) {
            this._view.getChildView("info_alert_message").text(edsApp.model.getLocalizedString("cannot_edit_email_message"));
            this._view.getChildView("info_alert").show();
        } else {
            this._view.getChildView("button_container").show();

            if (_.indexOf(edsApp.model.custom.user.emails, this.email) == 0)
                this._view.getChildView("make_primary").hide();
        }

    };
};
