edsApp.classes.controllers.custom.MagicLinkController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "magic_link";
        this.requiresLogin = false;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        var magicLinkController = this;

        magicLinkController._view.getChildView("success_alert").hide();
        magicLinkController._view.getChildView("warning_alert").hide();

        if (a.token) {
            edsApp.model.putDataToURL("magic-link", {urlToken: a.token}, function (success, data) {
                if (success) {
                    magicLinkController._view.getChildView("success_alert_message").text(edsApp.model.getLocalizedString("magic_link_success"));
                    magicLinkController._view.getChildView("success_alert").show();
                } else {
                    magicLinkController._view.getChildView("warning_alert_message").text(edsApp.model.getLocalizedError(data));
                    magicLinkController._view.getChildView("warning_alert").show();
                }
                magicLinkController._view.getChildView("spinner").hide();
            })
        } else {
            magicLinkController._view.getChildView("warning_alert_message").text(dsApp.model.getLocalizedString("magic_link_token_missing"));
            magicLinkController._view.getChildView("warning_alert").show();
        }
    };
};
