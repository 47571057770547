edsApp.classes.controllers.custom.AuthController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "auth";
        this.requiresLogin = true;
        this.justLoggedIn = false;
        this.tokenExp = 7776000; //3 months in seconds.
        this.tokenNoExpireExp = 1576800000; //Half a century in seconds.
        this.client_id = null;
        this.state = null;
        this.redirect_uri = null;
        this.response_type = null;
        this.scope = null;
        this.prompt = null;
        this.nonce = null;

        //Table related members.
        this.rowsPerPage = 10;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        var authController = this;

        this._view.getChildView("allow").on("click", function (e) {

            e.preventDefault();

            //The user allowed.
            authController.performOAuthFlow(authController.tokenExp);
        });

        this._view.getChildView("deny").on("click", function (e) {

            e.preventDefault();

            //The user declined.
            var errorData = {error : "access_denied", state: authController.state};
            edsApp.utilities.custom.redirect(authController.redirect_uri, errorData, authController.response_type != "code")
        });

        //Setup the tableView.
        var columnNames = [""];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setAllowsHovering(true);
    };

    canBecomeKeyController(a) {

        //Note we are overriding the superclass implementation.
        return (_.has(a, "client_id") &&  _.has(a, "state") &&
                _.has(a, "redirect_uri") && _.has(a, "response_type") && _.has(a, "scope") &&
                _.has(a, "prompt") && _.has(a, "nonce"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.client_id = a.client_id.toString();
        this.state = a.state;
        this.redirect_uri = a.redirect_uri;
        this.response_type = a.response_type;
        this.scope = a.scope;
        this.prompt = a.prompt;
        this.nonce = a.nonce;

        this._view.getChildView("title").text(edsApp.model.getLocalizedString("please_wait"));
        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("contents").hide();
        this._view.getChildView("button_container").show();

        this.beginFetching();


        var authController = this;

        //Get information about the client requesting authorization.
        edsApp.model.getJSONDataForURL("clients", {client_id: this.client_id}, 100, function (success, client) {

            if (success && client) {

                if (/^http:\/\/(localhost|127.0.0.1)(:|\/|$)/.test(authController.redirect_uri) && !(authController.justLoggedIn && client.firstParty)) {
                    //Force consent from the user if the redirect is at localhost unless the app is first party and we are coming from the login controller.
                    authController.prompt.push("consent");
                }

                //We check whether or not we should attempt to bypass the consent screen.
                if (!_.contains(authController.prompt, "consent") || _.contains(authController.prompt, "none")) {

                    if (client.firstParty) {
                        //First party clients get to skip the consent screen unless "consent" is present in prompt.
                        authController.performOAuthFlow(authController.tokenExp);
                    } else {

                        var tokensArgs = {scope: authController.scope, client_id: authController.client_id};

                        edsApp.model.getJSONDataForURL("user/tokens", tokensArgs, 0, function (success, data) {

                            if (success && data.length > 0) {
                                //There's already an identical token issued, so we'll silently issue another one.
                                authController.performOAuthFlow(authController.tokenExp);
                            } else if (_.contains(authController.prompt, "none")) {
                                //We return an error, because we need the consent of the user but the client forced us not to.
                                var errorData = {error : "interaction_required", state: authController.state};
                                edsApp.utilities.custom.redirect(authController.redirect_uri, errorData, authController.response_type != "code")

                            } else {
                                //The user must consent before we can continue.
                                authController._view.getChildView("contents").fadeIn();
                                authController._view.getChildView("title").text(edsApp.model.getLocalizedString("auth_request_message", {name: client.name}));
                            }
                        });
                    }
                } else {
                    //The user must consent before we can continue.
                    authController._view.getChildView("contents").fadeIn();
                    authController._view.getChildView("title").text(edsApp.model.getLocalizedString("auth_request_message", {name: client.name}));
                }

            } else {
                authController._view.getChildView("title").text("");
                authController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(client));
                authController._view.getChildView("alert").slideDown();
            }
        });
    };

    willResignKeyController() {

        //For security reasons, we clear these fields.
        this.justLoggedIn = false;

        //Call the superclass.
        super.willResignKeyController();
    };

    performOAuthFlow() {

        var authController = this;
        var exp = _.contains(authController.scope, "noexpire") ? authController.tokenNoExpireExp : authController.tokenExp;

        if (authController.response_type == "token" || authController.response_type == "id_token token" || authController.response_type == "id_token") {

            //lets get a new token. We create a new token even in the id_token flow to record the fact that an id_token was issued.
            authController._view.getChildView("button_container").hide();
            authController._view.getChildView("spinner").show();

            var newTokenArgs = {client_id: authController.client_id,
                                token_expiration: exp,
                                scope: authController.scope,
                                nonce: authController.nonce,
                                include_at_hash: authController.response_type == "id_token token"};

            edsApp.model.postDataToURL("user/tokens", newTokenArgs, function (success, data) {

                if (success) {

                    var redirectData = {state: authController.state};

                    if (authController.response_type == "id_token") {
                        redirectData.id_token = data.id_token;
                    } else {
                        redirectData.token = data.token;
                        redirectData.token_type = "bearer";
                        redirectData.expires_in = exp;
                        redirectData.scope = authController.scope.join(" ");

                        if (authController.response_type == "id_token token")
                            redirectData.id_token = data.id_token;
                    }

                   edsApp.utilities.custom.redirect(authController.redirect_uri, redirectData, true);

                } else {
                    //Show the alert with a localized error.
                    authController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    authController._view.getChildView("alert").slideDown();
                    authController._view.getChildView("button_container").show();
                    authController._view.getChildView("spinner").hide();
                }
            });
        } else if (authController.response_type == "code") {

            //lets get a new code.
            authController._view.getChildView("button_container").hide();
            authController._view.getChildView("spinner").show();

            var newCodeArgs = {client_id: authController.client_id,
                               redirect_uri: authController.redirect_uri,
                               token_expiration: exp,
                               scope: authController.scope,
                               nonce: authController.nonce};

            edsApp.model.postDataToURL("user/codes", newCodeArgs, function (success, data) {

                if (success) {

                   var redirectData = {code: data,
                                       state: authController.state};

                    edsApp.utilities.custom.redirect(authController.redirect_uri, redirectData, false);

                } else {
                    //Show the alert with a localized error.
                    authController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    authController._view.getChildView("alert").slideDown();
                    authController._view.getChildView("button_container").show();
                    authController._view.getChildView("spinner").hide();
                }
            });
        }
    };

    //Table data methods as required by SingleTableViewController
    fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        fetchCallbackFn(true, this.scope);
    };

    getTableViewMessage(data) {
        return "";
    };

    //Table delegate functions.
    dataForCell(tableView, row, column, callbackFunction) {

        var data = this.tableData[row];

        if (column == 0) {

            callbackFunction(edsApp.model.getLocalizedString("grant_" + data), "text", false, ["text-center", "mcaTallCell"]);

        } else {
            callbackFunction(null, "text", false);
        }
    };

    popoverDataForRow(tableView, row, callbackFn) {

        var tableData = this.tableData[row];
        callbackFn(edsApp.model.getLocalizedString("grant_" + tableData + "_description"), "text");
    }
};