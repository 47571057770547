edsApp.classes.controllers.custom.PaymentController = class extends edsApp.classes.controllers.SingleTableViewController
{
    constructor() {
        super();

        //Define members.
        this.name = "payment";
        this.requiresLogin = true;
        this.helpLink = "payment";
        this.groupId = null;
        //Table related members.
        this.rowsPerPage = 10;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".

        //No actions for this coontroller :)

        //Setup the tableView.
        var columnNames = ["", ""];
        this.tableView = new edsApp.classes.views.TableView(this._view.getChildView("table"), this.rowsPerPage, columnNames, this);
        this.tableView.setClassesForColumnHeader(0, ["edsBorderless"]);
        this.tableView.setClassesForColumnHeader(1, ["edsBorderless"]);
        this.tableView.setAllowsHovering(true);
        this.tableView.setAllowsInteractiveColumns(true);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this.groupId = a.group_id;
        var user = edsApp.model.custom.user;

        //Set the subtitle and payment button link
        if (a.group_id) {
            var eligibleGroups = user.ownerGroups;
            var group = _.findWhere(eligibleGroups, {id: a.group_id.toString()});

            this._view.getChildView("new_payment").attr("href", edsApp.utilities.createRelativeURLForController(edsApp.controllers.payment_detail.name, {group_id: a.group_id}));
            this._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("payment_subtitle_group", {group: group ? group.name : a.group_id}));
        } else {
            this._view.getChildView("new_payment").attr("href", edsApp.utilities.createRelativeURLForController(edsApp.controllers.payment_detail.name, {}));
            this._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("payment_subtitle_user"));
        }

        this._view.getChildView("alert").hide();
        this._view.getChildView("new_payment").removeClass("disabled");

        this.beginFetching();
    };

    //Table data methods as required by SingleTableViewController
    fetchTableData(numberOfRowsToFetch, offset, fetchCallbackFn) {

        //Get the payment token summary.
        var requestArgs = {};

        if (this.groupId)
            requestArgs.entity = this.groupId + "-|-Group";
        else
            requestArgs.entity = edsApp.model.custom.user.id + "-|-User";
        
        var paymentController = this;

        edsApp.model.getJSONDataForURL("payment", requestArgs, 100, function (success, data) {

            var subscriptions = [];

            if (success) {
                if (_.isObject(data) && _.isArray(data.data.subscriptions))
                    subscriptions = data.data.subscriptions;
            } else {
                paymentController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                paymentController._view.getChildView("alert").slideDown()
                paymentController._view.getChildView("table").hide();
                paymentController._view.getChildView("new_payment").addClass("disabled");
            }

            fetchCallbackFn(success, subscriptions);
        });
    };

    sortTableData(columnNumber, sortDescending, data, sortCallbackFn) {
        var predicateFn = null;
        var sortedData = null;
        var sortComplexDataMap = {}; //Used for mapping columns with the data used for sorting them in complex sorting.

        if (columnNumber == 0 || columnNumber == 1) {

            predicateFn = function (aSubscription) {
                return aSubscription.cardFirstTwo + aSubscription.cardLastFour;
            };

            sortedData = edsApp.utilities.sortedArrayWithPredicate(predicateFn, data, sortDescending);
            sortCallbackFn(true, sortedData);

        } else {
            sortCallbackFn(false, null);
        }
    };

    getTableViewMessage(data) {
        if (data.length > 0) {
            return edsApp.model.getLocalizedString("payment_table_message");
        } else {
            if (this.groupId) {
                return edsApp.model.getLocalizedString("zero_payments_group");
            } else {
                return edsApp.model.getLocalizedString("zero_payments_user");
            }
        }
    };

    //Table delegate functions.
    dataForCell(tableView, row, column, callbackFunction) {

        var data = this.tableData[row];

        if (column == 0) {

            //Load the ajcc.js script for displaying the credit card.
            edsApp.model.getScriptForURL("/js/ajcc.js", {}, function (ajccSuccess, script) {

                if (!ajccSuccess) {
                    callbackFunction(null, "text", false);
                } else {
                    var $icon = $('<img style="width: 25px; height: 25px;"/>');

                    var visaVendor = ajcc.getVisaCardVendor("/media/credit_cards/visa.png");
                    var masterCardVendor = ajcc.getMasterCardCardVendor("/media/credit_cards/master_card.png");
                    var amexVendor = ajcc.getAmexCardVendor("/media/credit_cards/amex.png");

                    if (visaVendor.validator(data.cardFirstTwo)) {
                        $icon.attr("src", visaVendor.iconURL).attr("alt", "Visa");
                    } else if (masterCardVendor.validator(data.cardFirstTwo)) {
                        $icon.attr("src", masterCardVendor.iconURL).attr("alt", "Master Card");
                    } else if (amexVendor.validator(data.cardFirstTwo)) {
                        $icon.attr("src", amexVendor.iconURL).attr("alt", "American Express");
                    } else {
                        $icon.attr("src", "/media/credit_cards/generic_card.png").attr("alt", "");
                    }

                     callbackFunction($icon, "jQueryObject", false, ["edsBorderless"]);
                }
            });

        } else if (column == 1) {

            var ccNumber = data.cardFirstTwo + " ";

            for (var i=0; i < 10; i++) {
                ccNumber += "\u00B7 "; //Middle dot.
            }

            ccNumber += data.cardLastFour;

            callbackFunction(ccNumber, "text", true); //AJ TODO!!!
        } else {
            callbackFunction(null, "text", false);
        }
    };

    didSelectCell(tableView, row, column) {

        var data = this.tableData[row];
        edsApp.controllers.app.pushController(edsApp.controllers.payment_detail, true, {group_id: this.groupId, subscription_id: data.subscriptionId });
    };

    didSelectColumnHeader(tableView, columnNumber) {
        //Determine which way to order.
        if (this.sortedColumn == columnNumber) {
            this.sortDescending = !this.sortDescending;
        } else {
            this.sortedColumn = columnNumber;
            this.sortDescending = true;
        }

        this.beginSorting(columnNumber, this.sortDescending);
    };
};
