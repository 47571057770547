/**
 * This abstract class allows nested view controllers. It acts as a localized AppController limited to its view. You
 * can call pushChildController(newController, argumentsForNewController) to change the key child controller. Child
 * controllers can be any other controllers inheriting from ViewController, including another ContainerViewController
 * The child controllers will host their views in the "child_container" data-eds-view of the ContainerViewController.
 * You must make sure that the view of a ContainerViewController contains one and only one "child_container"
 * data-eds-view, or an error will be thrown.
 * NOTE: The pushChildController method differs from an AppController's pushController method in two important ways:
 * Firstly, note that because views are loaded asynchronously, a child controller's view may not have loaded by the
 * time the child controller is asked to become key. Therefore, pushChildController waits until the view has loaded
 * before making the newController the key controller. Secondly, pushChildController does not record history
 * changes in the browser, so the user cannot click on the back button of their browser to 'pop' to the previous
 * child controller.
 */

edsApp.classes.controllers.ContainerViewController = class extends edsApp.classes.controllers.ViewController {

    constructor() {
		/* Do setup specific to this class. */
		super();
		
	    //Define members.
	    this.keyChildController = null;
	    this.childViewContainer = null;
    }

    
    setViewHTML(html) {

        //Call the superclass.
        super.setViewHTML(html);

        //Check that we have a child view container.
        if (this._view.getChildView("child_container").length != 1) {
            var containerViewError = new Error();
            containerViewError.name = edsApp.model.errors.error;
            containerViewError.message = "A ContainerViewController's view must contain 1 data-eds-view named 'child_container'";
            throw containerViewError;
        }

        this.childViewContainer = this._view.getChildView("child_container");
    };


    didPressKey(keyCode) {

        //By default, we relay the event to the child controller if there is one.
        if (this.keyChildController)
            this.keyChildController.didPressKey(keyCode);

        //Call the superclass.
        super.didPressKey(keyCode);
    };

    //PRIVATE METHODS (DO NOT USE IN SUBCLASS OR OVERRIDE!):

    _viewDidLoadForController(aController, callbackFn) {

	    var containerViewController = this;
        //This function checks whether the view for aController has loaded or not. If not, it checks every second or so
        //And invokes callbackFn once aController has a view. WARNING: This method relies on the fact that the init code
        //will throw an error if it can't load aController's view within a finite amount of time. If this is no longer
        //true, this method will continue on for infinity and never invoke callbackFn.
        //If the view for the controller has not loaded yet, we try again in one second.
        if (!aController.getView())
            edsApp.utilities.invokeFunctionWithDelay(1000, function () {
                containerViewController._viewDidLoadForController(aController, callbackFn);
            });
        else
            callbackFn();
    };


    //PUBLIC METHODS

    pushChildController(newController, argumentsForNewController, callbackFn) {

		//The optional callbackFn will be called right before the newController becomes the key child controller.
    	var containerViewController = this;
		
        //Here we push a child controller's view into the child view container and tell the controller it will become key.
        argumentsForNewController = argumentsForNewController || {};

        //If the new controller cannot become the key controller with the given arguments, we redirect to the error page.
        if (!newController.canBecomeKeyController(argumentsForNewController)) {
            var error = new Error();
            error.name = edsApp.model.errors.cannotBecomeKeyController;
            error.message = "Controller '" + newController.name + "' cannot become key in '" + containerViewController.name + "'. Arguments given: " + JSON.stringify(argumentsForNewController);
            throw error;
        }

        //We wait for the newController's view to load.
        this._viewDidLoadForController(newController, function () {

            edsApp.utilities.fadeTransition(containerViewController.childViewContainer, function () {

                if (containerViewController.keyChildController) {
                    containerViewController.keyChildController.getView().detach();
                    containerViewController.keyChildController.willResignKeyController();
                } else {
                    //Clear the default content.
                    containerViewController.childViewContainer.empty();
                }

				if (_.isFunction(callbackFn)) 
					callbackFn();
				
                newController.willBecomeKeyController(argumentsForNewController);
                newController.getView().appendTo(containerViewController.childViewContainer);
                containerViewController.keyChildController = newController;
            });
        });
    };
};
