edsApp.classes.controllers.custom.NewGroupController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "new_group";
        this.requiresLogin = true;
        this.formValidator = null;
        this.helpLink = "create_group";
        this.secureCallback = null;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Hookup view events to their respective "listeners" or "targets".
        var newGroupController = this;
        this._view.getChildView("form").on("submit", function (e) {

            if (!newGroupController.formValidator.getFormStatus())
                return;

            newGroupController._view.getChildView("create").hide();
            newGroupController._view.getChildView("spinner").show();

            var postArgs = {name: newGroupController._view.getChildView("name").val(),
                            description: newGroupController._view.getChildView("description").val()};

            edsApp.model.postDataToURL("groups", postArgs, function (success, data) {

                if (success) {

                    var onSuccessFn = function () {
                        edsApp.model.custom.user.ownerGroups.push(data);
                        edsApp.controllers.app.pushController(edsApp.controllers.group_detail, true, {group_id: data.id, new_group: true});
                    };

                    if (newGroupController.secureCallback) {
                        newGroupController.secureCallback(["groups"], data, function () {
                            onSuccessFn();
                        })
                    } else {
                        onSuccessFn();
                    }

                } else {

                    newGroupController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    newGroupController._view.getChildView("alert").slideDown();
                    newGroupController._view.getChildView("create").show();
                    newGroupController._view.getChildView("spinner").hide();
                }
            });
        });
    };

    willBecomeKeyController (a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("create").show();

        //Reset the form.
        this._view.getChildView("name").val("");
        this._view.getChildView("description").val("");
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("form").find("textarea").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("create").prop("disabled", !this.formValidator.getFormStatus());

        //Setup secure callbacks.
        if (a.secureCallback)
            this.secureCallback = edsApp.utilities.custom.setupSecureCallback();
        else
            this.secureCallback = null;
    };

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("create").prop("disabled", !formValidator.getFormStatus());
    }
};
