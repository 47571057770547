edsApp.classes.controllers.ViewController = class {
    /*This class is a superclass. There is no inheritance. */

	constructor() {
	    /* Do setup specific to this class. */
		
		//Define Members.
    	this._view = null;
    	this.name = null;
	}
	
    //Define Functions.
    getView () {
        return this._view;
    }

    setViewHTML(html) {
        this._view = $(html);

        //Disable form events for subclasses.
        this._view.find("form").on("submit", function(e) {

            e.preventDefault();
        })
    }

    getViewURL() {
        return edsApp.model.viewsURL + this.name + ".html";
    }

    canBecomeKeyController(a) {
        return true;
    }

    willResignKeyController() {
        //console.log("Will resign key: " + this.name);
    }

    willBecomeKeyController(a) {
        //console.log("Will become key: " + this.name);
    }

    didPressKey(keyCode) {
        //console.log(keyCode)
    }
};