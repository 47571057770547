edsApp.classes.views.TableView = class {
    /*This class is a superclass. There is no inheritance. */

    /* Do setup specific to this class. */
	constructor(container, rowsPerPage, columnNames, tableDelegate) {
		//Define Members
	    this._rowsPerPage = rowsPerPage;
	    this._currentPage = 0;
	    this._columnNames = columnNames;
	    this._numberOfRows = 0;
	    this._reloadCounter = 0;
	    this._rowHovering = false;
	    this._allowsInteractiveColumns = false;
	    this._allowsInteractiveRows = false;
	    this._condensedRows = false;
	    this._message = null;
	    this._popoverTitle = null;
	    this._popoverContent = null;
	    this._popoverPlacement = null;
	    this._currentPopoverRow = -1;
	    this._currentPopoverColumn = -1;
	    this._tableDomObject = null;
	    this._pagerDomObject = null;
	    this._messageDomObject = null;
	    this._linkObject = $("<a href='#'></a>");
	    this._spinnerObject = $("<span class='edsAppSpinner'></span>");
	    this.tableDelegate = tableDelegate;
		
		//Perform Initialization.
	    this._tableDomObject = this._createTableDomObject(columnNames);
	    this._pagerDomObject = this._createPagerDomObject();
	    this._messageDomObject = $("<p class='text-center edsAppGrayColor'></p>").append(this._spinnerObject.clone());
	    container.append(this._tableDomObject);
	    container.append(this._pagerDomObject);
	    container.append(this._messageDomObject);
	    this._adjustPager();
	
	    //Attach the necessary events to the table and its dom objects.
	
	    /* Here we listen for events on the column headers and inform the delegate if allowsInteractiveColumns() is true
	     * and the delegate implements disSelectColumnHeader(tableView, column). */
	    this._tableDomObject.find("thead").on("click", {tableView : this}, function (e) {
	
	        e.preventDefault();
	
	        if (e.data.tableView.allowsInteractiveColumns() && _.isFunction(e.data.tableView.tableDelegate.didSelectColumnHeader)) {
	
	            var columnNumber = parseInt($(e.target).closest("th").attr("data-eds-column"));
	            e.data.tableView.tableDelegate.didSelectColumnHeader(e.data.tableView, columnNumber);
	        }
	    });
	
	    /* Here we listen for clicks on the table body. If the cell clicked on is clickable and the delegate implements
	     didSelectCell(tableView, row, column, cellDomObject), that method will be invoked. If allowsInteractiveRows() is true and the
	     delegate implements didSelectRow(tableView, row), that method will be invoked. Note that if a clickable cell is
	     clicked and allowInteractiveRows() is true, only the more specific didSelectCell(tableView, row, column) method
	     will be invoked on the delegate. If a popover is being shown, it will be hidden. */
	    this._tableDomObject.find("tbody").on("click", {tableView : this}, function (e) {
	
	        e.preventDefault();
	
	        //Retrieve the cell that was clicked.
	        var selectedCell = $(e.target).closest("td");
	        var rowNumber = parseInt(selectedCell.attr("data-eds-row")) + e.data.tableView._currentPage * e.data.tableView._rowsPerPage;
	
	        e.data.tableView._destroyPopovers();

            // console.log(selectedCell.attr("data-eds-checkbox"));

            if (selectedCell.attr("data-eds-checkbox") !== undefined && _.isFunction(e.data.tableView.tableDelegate.checkStateForRowAsync)) {

                // check the row's checkbox!
                var checkbox = selectedCell.find("input");
                if (!checkbox.prop("disabled")) {
                    // if we clicked on the checkbox itself then .prop('checked') isn't truthful, so go to the source!
                    const newState = e.target.checked !== undefined ? e.target.checked : !checkbox.prop('checked');

                    // tell controller to update checked state
                    if (_.isFunction(e.data.tableView.tableDelegate.checkStateChangedForRow)) {
                        e.data.tableView.tableDelegate.checkStateChangedForRow(e.data.tableView, rowNumber, newState);
                    }
                    // toggle checkbox html element
                    edsApp.utilities.invokeFunctionWithDelay(0, function() { checkbox.prop('checked', !checkbox.prop('checked')); });
                }

            } else if (selectedCell.attr("data-eds-clickable") == "true" && _.isFunction(e.data.tableView.tableDelegate.didSelectCell)) {
	
	            var columnNumber = parseInt(selectedCell.attr("data-eds-column"));
	            e.data.tableView.tableDelegate.didSelectCell(e.data.tableView, rowNumber, columnNumber, selectedCell);
	
	        } else if (e.data.tableView.allowsInteractiveRows() && _.isFunction(e.data.tableView.tableDelegate.didSelectRow)) {
	
	            e.data.tableView.tableDelegate.didSelectRow(e.data.tableView, rowNumber);
	        }
	    });
	
	    /* Here we listen for a mouse enter event for devices equipped with a pointer to display a popover. This method is
	     * only called once when the pointer enters a table cell (a td element). When this event is triggered, the table view
	     * will check to see if the delegate responds to popoverDataForCellAsync(tableView, row, column) or
	     * popoverDataForRowAsync(tableView, row). If so, the table view will invoke the most specific method
	     * available. For either method, the returned Promise should have the following results when resolved: data, dataType, and an optional title
	     * parameter. 'data' is the data in string form. Note that this data will automatically be escaped by this method if
	     * 'dataType' is text. Return null if the data for the specific cell could not be retrieved (if there is actually no
	     * data for that cell, the delegate can return an appropriate message as the data for the table to display in the
	     * corresponding cell. 'dataType' refers to the type of data that this cell has. Currently 'text' and 'jQueryObject'
	     * are supported. Any other value will yield undefined behavior. The optional 'title' parameter must be a text string
	     * and will be automatically escaped. You may pass null or an empty string if you do not have a title to display. For either
	     * method, the delegate can optionally define 'shouldShowPopover(tableView, column, row)' which returns a bool stating
	     * whether a specific cell or row should have a popover. This is useful in cases where only certain rows or cells should
	     * display a popup.
	     */
	    this._tableDomObject.find("tbody").on("mouseenter", "td", {tableView : this}, function (e) {
	        //Retrieve the cell that was clicked and calculate its attributes.
	        var selectedCell = $(e.target).closest("td");
	        var rowNumber = parseInt(selectedCell.attr("data-eds-row")) + e.data.tableView._currentPage * e.data.tableView._rowsPerPage;
	        var columnNumber = parseInt(selectedCell.attr("data-eds-column"));
	        var rowsInCurrentPage = e.data.tableView._numberOfRows - e.data.tableView._currentPage * e.data.tableView._rowsPerPage;

	        if (rowsInCurrentPage > e.data.tableView._rowsPerPage)
	            rowsInCurrentPage = e.data.tableView._rowsPerPage;

	        //Let's make sure the delegate supports popovers before we do any work.
	        if (!_.isFunction(e.data.tableView.tableDelegate.popoverDataForRowAsync) && !_.isFunction(e.data.tableView.tableDelegate.popoverDataForCellAsync) &&
                !_.isFunction(e.data.tableView.tableDelegate.popoverDataForRow) && !_.isFunction(e.data.tableView.tableDelegate.popoverDataForCell))
	            return;

	        //Let's make sure that the delegate supports popovers for this row/column.
            if (_.isFunction(e.data.tableView.tableDelegate.shouldShowPopover) && !e.data.tableView.tableDelegate.shouldShowPopover(e.data.tableView, rowNumber, columnNumber))
                return;
	
	        //Update the popover's position.
	        e.data.tableView._currentPopoverRow = rowNumber;
	        e.data.tableView._currentPopoverColumn = columnNumber;
	
	        //Reset the popover's content and placement.
	        e.data.tableView._popoverTitle = " ";
	        e.data.tableView._popoverContent = $('<div class="text-center"></div>').append(e.data.tableView._spinnerObject.clone());
	        e.data._popoverPlacement = parseInt(selectedCell.attr("data-eds-row")) > (rowsInCurrentPage - 1) / 2 ? "top" : "bottom";
	
	        //Instantiate and show the popover.
	        selectedCell.popover({content: function () {return e.data.tableView._popoverContent},
	                              title: function () {return e.data.tableView._popoverTitle},
	                              placement: function () {return e.data._popoverPlacement},
	                              html: "true",
	                              trigger: "manual",
	                              container: "body"});
	
	        selectedCell.popover("show");
	
	        //We wrap everything in a self executing function to capture the current state of the variables.
	        (function () {
	
	            var currentRow = rowNumber;
	            var currentColumn = columnNumber;
	            var currentReload = e.data.tableView._reloadCounter;
	            var currentCell = selectedCell;
	
	            var callbackFn = function (data, dataType, title) {
	
	                title = title || " ";
	
	                //First we make sure that we still need the data we asked for.
	                if (currentReload != e.data.tableView._reloadCounter)
	                    return;
	
	                //We make sure that the popover is still being shown and is valid.
	                if (e.data.tableView._currentPopoverRow != currentRow ||
	                    e.data.tableView._currentPopoverColumn != currentColumn)
	                    return;
	
	                //Since we are still good to go, we update the popover contents.
	                e.data.tableView._popoverTitle = title;
	
	                if (data !== null) {
	
	                    if (dataType === "jQueryObject") {
	
	                        e.data.tableView._popoverContent = data;
	                    }
	                    else if (dataType === "text") {
	
	                        e.data.tableView._popoverContent = $("<span></span>").text(data);
	                    }
	
	                } else {
	                    //Display the error icon.
	                    e.data.tableView._popoverContent = $("<div class='text-center'><span class='glyphicon glyphicon-question-sign'></span></div>");
	                }
	
	                //Finally we refresh the popover.
	                currentCell.popover('show');
	            };
	
                if (_.isFunction(e.data.tableView.tableDelegate.popoverDataForCellAsync)) {
                    var promise = e.data.tableView.tableDelegate.popoverDataForCellAsync(e.data.tableView, rowNumber, columnNumber);
            
                    promise.then(([data, dataType, title]) => {
                        callbackFn(data, dataType, title);
                    }).catch((error) => {
                        console.log(`Error in popoverDataForCellAsync in delegate '${e.data.tableView.tableDelegate}': ${error} ${error.stack}`);
                        callbackFn(null);
                    });
                    
                } else if (_.isFunction(e.data.tableView.tableDelegate.popoverDataForCell)) {
                    //Fallback to deprecated method for legacy code.
	                e.data.tableView.tableDelegate.popoverDataForCell(e.data.tableView, rowNumber, columnNumber, callbackFn);
                } else if (_.isFunction(e.data.tableView.tableDelegate.popoverDataForRowAsync)) {
	                var promise = e.data.tableView.tableDelegate.popoverDataForRowAsync(e.data.tableView, rowNumber, columnNumber);
            
                    promise.then(([data, dataType, title]) => {
                        callbackFn(data, dataType, title);
                    }).catch((error) => {
                        console.log(`Error in popoverDataForRowAsync in delegate '${e.data.tableView.tableDelegate}': ${error} ${error.stack}`);
                        callbackFn(null);
                    });

                } else if (_.isFunction(e.data.tableView.tableDelegate.popoverDataForRow)) {
                    //Fallback to deprecated method for legacy code.
	                e.data.tableView.tableDelegate.popoverDataForRow(e.data.tableView, rowNumber, callbackFn);
                }
	        }());
	
	    });
	
	    /* Here we listen for a mouse enter event for devices equipped with a pointer to display a popover. This method is
	     * only called once when the pointer exits a table cell (a td element). When this event is triggered, the table view
	     * will hide a visible popover if it exists.
	     */
	    this._tableDomObject.find("tbody").on("mouseleave", "td", {tableView : this}, function (e) {
	
	        e.data.tableView._destroyPopovers();
	    });
	
	    /* Here we listen for clicks on the previous button. We adjust the pager accordingly */
	    this._pagerDomObject.find("li.previous").on("click", {tableView : this}, function (e) {
	
	        e.preventDefault();
	
	        //Load the previous page.
	        e.data.tableView.loadPage(e.data.tableView._currentPage - 1);
	    });
	
	    /* Here we listen for clicks on the next button. We adjust the pager accordingly */
	    this._pagerDomObject.find("li.next").on("click", {tableView : this}, function (e) {
	
	        e.preventDefault();
	
	        //Load the next page.
	        e.data.tableView.loadPage(e.data.tableView._currentPage + 1);
		});
	}

    //Define methods.

    /** Enables or disables highlighting table rows when a mouseover event occurs. By default hovering is not enabled. */
    setAllowsHovering(booleanValue) {

        this._rowHovering = booleanValue;

        if (booleanValue) {
            this._tableDomObject.addClass("table-hover");
        } else {
            this._tableDomObject.removeClass("table-hover");
        }
    }

    /** Returns a boolean specifying whether row hovering is enabled or disabled. */
    allowsRowHovering() {
        return this._rowHovering;
    }

    /** Enables or disables condensed rows. By default condensed rows are not enabled. */
    setCondensedRows(booleanValue) {

        this._condensedRows = booleanValue;

        if (booleanValue) {
            this._tableDomObject.addClass("table-condensed");
        } else {
            this._tableDomObject.removeClass("table-condensed");
        }
    }

    /** Returns a boolean specifying whether condensed rows are enabled or disabled. */
    condensedRows() {
        return this._condensedRows;
    }

    /** Enables or disables column header events. The default is false. If true, the delegate will receive a
     * didSelectColumnHeader() method call when the user clicks on a column header. .*/
    setAllowsInteractiveColumns(booleanValue) {

        this._allowsInteractiveColumns = booleanValue;

        if (booleanValue) {
            this._tableDomObject.find("thead th").addClass("edsClickableRow");
        } else {
            this._tableDomObject.find("thead th").removeClass("edsClickableRow");
        }
    }

    /** Returns a boolean specifying whether row hovering is enabled or disabled. */
    allowsInteractiveColumns() {
        return this._allowsInteractiveColumns;
    }

    /** Enables or disables clicking row events. The default is false. If true, the delegate will receive a
     * didSelectRow() method call when the user clicks on a table row. */
    setAllowsInteractiveRows(booleanValue) {

        this._allowsInteractiveRows = booleanValue;

        if (booleanValue) {
            this._tableDomObject.find("tbody tr").addClass("edsClickableRow");
        } else {
            this._tableDomObject.find("tbody tr").removeClass("edsClickableRow");
        }
    }

    /** Returns a boolean specifying whether interactive rows are enabled or disabled. */
    allowsInteractiveRows() {
        return this._allowsInteractiveRows;
    }


    /* Sets the table message, displayed at the bottom of the table. A table's message can be any arbitrary string.
     * If you pass null, the spinner will be shown in place of a message. Because the message is null by default, the
     * spinner is shown when a new TableView object is created. */
    setMessage(message) {

        this._message = message;
        this._messageDomObject.empty();

        if (message === null) {
            this._messageDomObject.append(this._spinnerObject.clone());
        }
        else {
            this._messageDomObject.text(message);
        }
    }

    /*Returns the current message being displayed, or null if the spinner is being shown. */
    message() {
        return this._message;
    }

    /* Sets the caret displayed on the right side of a table column's header. You can specify "up" for an upward facing
     caret, "down" for a downward facing caret, or null to hide the caret. Note that changing a column header will
     cause any other column header displaying a caret to lose its caret.
     */
    setCaretForColumnHeader(columnNumber, state) {

        //Hide all carets.
        this._tableDomObject.find("th .caret").remove();
        this._tableDomObject.find("th .dropup").remove();

        var columnHeader = this._tableDomObject.find("th[data-eds-column=" + columnNumber + "]");

        //Remove the dropup and caret spans.
        columnHeader.find(".dropup").remove();
        columnHeader.find(".caret").remove();

        if (state === "down") {
            columnHeader.append($("<span class='caret'></span>"));
        } else if (state === "up") {
            columnHeader.append($("<span class='dropup'><span class='caret'></span></span>"));
        }
    }

    /* Sets the passed classes array containing CSS classes to the specified column header. This method can be used to
     * customize the appearance of a specific column header belonging to the receiver. Calling this method a second time
     * on the same column header will delete all the previously set classes. As such, you can pass an empty classes
     * array to remove all CSS classes for the column header belonging to columnNumber. */
    setClassesForColumnHeader(columnNumber, classes) {

        //Remove all CSS classes.
        var columnHeader = this._tableDomObject.find("th[data-eds-column=" + columnNumber + "]");
        columnHeader.removeClass();

        //Reapply the clickable class if needed.
        this.setAllowsInteractiveColumns(this.allowsInteractiveColumns());

        //We apply any classes that may have been passed.
        _.each(classes, function (aCSSClass) {
            columnHeader.addClass(aCSSClass);
        });
    }


    /* Returns the current page number of the table view */
    currentPageNumber() {
        return this._currentPage;
    }

    /* Returns a new pager dom object that can be used by the receiver */
    _createPagerDomObject() {

        return  $("<ul class='pager text-center'>" +
            "<li class='previous'><a href='#' >" + edsApp.model.getLocalizedString("previous") + "</a></li>" +
            "<li class='next'><a href='#'>" + edsApp.model.getLocalizedString("next_page") + "</a></li>" +
            "</ul>");
    }

    /* Returns a new table dom object that can be used by the receiver to display table data. */
    _createTableDomObject(columnNames) {

        var tableDomObject =  $("<table class='table'>" +
            "<thead>" +
            "<tr></tr>" +
            "</thead>" +
            "<tbody></tbody>" +
            "</table>");

        //Now add all the columns
        var headerRowDomObject = tableDomObject.find("tr");

        // add header for checkbox column, if enabled
        if (_.isFunction(this.tableDelegate.checkStateForRowAsync)) {
            headerRowDomObject.append($("<th></th>"));
        }

        _.each(columnNames, function(columnName, columnIndex) {

            var newColumnDomObject = $("<th></th>").attr('data-eds-column', columnIndex).text(columnName + " ");
            headerRowDomObject.append(newColumnDomObject);
        });

        return tableDomObject;
    }

    /** Asks the delegate for cell data for all the cells contained in the range of rows. Note that the actual data is
     * received through as callback functions, allowing the delegate to take its time retrieving the requested data.
     * This method will invoke dataForCellAsync(tableView, row, column) on the delegate for every cell that it is
     * requesting data for. 'tableView' refers to the table view instance asking for the data. 'row' and 'column' refers
     * to the row and the column number of the cell whose data is being asked. The method should return a Promise that when resolved
     * returns a typle containing data, dataType, clickable and an optional styles array. 'data' is the data in string form. Note that this data
     * will automatically be escaped by this method if 'dataType' is text. Return null if the data for the specific cell
     * could not be retrieved (if there is actually no data for that cell, the delegate can return an appropriate
     * message as the data for the table to display in the corresponding cell. 'dataType' refers to the type of data
     * that this cell has. Currently 'text' and 'jQueryObject' are supported. Any other value will yield undefined
     * behavior. jQueryObjects will be appended to the cell whose data is being requested.
     * 'clickable' is a boolean that determines whether that specific cell should trigger a didSelectCell call to the
     * delegate. Clickable cells with dataType 'text' will be wrapped around an html <a> tag to make them stand out in
     * the rendered document. The optional styles array is an array of strings containing class names that will be
     * applied to the cell whose data is being requested. It can be used to further specify the appearance of that
     * specific cell. Furthermore, this method also invokes colorForRowAsync(tableView, row) on the delegate if
     * the delegate has implemented it. The method should return a Promise that when resolved returns a color. You may currently assign any
     * bootstrap supported contextual class name for the color parameter, or specify null if you do not want a row to be
     * highlighted. If the delegate does not implement this method, no row will be highlighted. */
    _requestDataForRowRange(minRow, maxRow) {

        var numberOfColumns = this._columnNames.length;
        var tableView = this;

        //We ask the delegate for data for each cell between the minRow and the maxRow.
        for (var i = minRow; i < maxRow; i++) {

            //See if the delegate implements colorForRow
            if (_.isFunction(tableView.tableDelegate.colorForRowAsync) || _.isFunction(tableView.tableDelegate.colorForRow)) {

                //We wrap everything in a self executing function to capture the current state of the variables.
                (function () {

                    var currentRow = i;
                    var currentReload = tableView._reloadCounter;

                    var callbackFn = function (color) {

                        //First we make sure that we still need the data we asked for or that color is not null.
                        if (currentReload != tableView._reloadCounter || color === null)
                            return;

                        //We make sure that the data fits in the current page.
                        if (currentRow < (tableView._rowsPerPage * tableView._currentPage) ||
                            currentRow > (tableView._rowsPerPage * (tableView._currentPage + 1) - 1))
                            return;

                        //Since we are still good to go, we retrieve the correct row from the DOM.
                        var rowToRetrieve = currentRow % tableView._rowsPerPage;
                        var coloredRow = tableView._tableDomObject.find("tr[data-eds-row=" + rowToRetrieve + "]");
                        coloredRow.addClass(color);
                    };

                    if (_.isUndefined(tableView.tableDelegate.colorForRowAsync)) {
                        //Fallback to deprecated method for legacy code.
                        tableView.tableDelegate.colorForRow(tableView, i, callbackFn);
                    } else {
                        var promise = tableView.tableDelegate.colorForRowAsync(tableView, i);
            
                        promise.then((color) => {
                            callbackFn(color);
                        }).catch((error) => {
                            console.log(`Error in colorForRowAsync in delegate '${tableView.tableDelegate}': ${error} ${error.stack}`);
                            callbackFn(null);
                        });
                    }

                }());
            }

            // draw the checkboxes
            if (_.isFunction(tableView.tableDelegate.checkStateForRowAsync)) {
                (function () {

                    var currentRow = i;

                    var callbackFn = function (state) {

                        // find checkbox
                        var rowToRetrieve = currentRow % tableView._rowsPerPage;
                        var checkbox = tableView._tableDomObject.find("td[data-eds-row=" + rowToRetrieve + "][data-eds-checkbox] > input");

                        // set checkbox state
                        checkbox.prop('disabled', !state['enabled']);
                        checkbox.prop('checked', state['checked']);
                    };

                    var promise = tableView.tableDelegate.checkStateForRowAsync(tableView, i);

                    promise.then((state) => {
                        callbackFn(state);
                    }).catch((error) => {
                        console.log(`Error in checkStateForRowAsync in delegate '${tableView.tableDelegate}': ${error} ${error.stack}`);
                        callbackFn(null);
                    });
                }());
            }


            for (var ii = 0; ii < numberOfColumns; ii++) {

                //We wrap everything in a self executing function to capture the current state of the variables.
                (function () {

                    var currentRow = i;
                    var currentColumn = ii;
                    var currentReload = tableView._reloadCounter;

                    var callbackFn = function (data, dataType, clickable, styles) {

                        styles = styles || [];

                        //First we make sure that we still need the data we asked for.
                        if (currentReload != tableView._reloadCounter)
                            return;

                        //We make sure that the data fits in the current page.
                        if (currentRow < (tableView._rowsPerPage * tableView._currentPage) ||
                            currentRow > (tableView._rowsPerPage * (tableView._currentPage + 1) - 1))
                            return;

                        //Since we are still good to go, we retrieve the correct cell from the DOM.
                        var rowToRetrieve = currentRow % tableView._rowsPerPage;
                        var currentCell = tableView._tableDomObject.find("td[data-eds-row=" + rowToRetrieve + "][data-eds-column=" + currentColumn + "]");

                        //Remove any classes applied to the cell.
                        currentCell.removeClass();

                        //We apply any classes that may have been passed.
                        _.each(styles, function (aCSSClass) {
                            currentCell.addClass(aCSSClass);
                        });

                        //Now we modify the DOM with the data passed according to the delegate's preference.
                        if (data !== null) {

                            if (clickable) {

                                //Mark the cell as clickable.
                                currentCell.attr("data-eds-clickable", "true");

                                if (dataType === "jQueryObject") {

                                    currentCell.empty();
                                    currentCell.append(data);
                                }
                                else if (dataType === "text") {

                                    var linkableTextContent = tableView._linkObject.clone().text(data);
                                    currentCell.empty();
                                    currentCell.append(linkableTextContent);
                                }
                            }
                            else {

                                if (dataType === "jQueryObject") {

                                    currentCell.empty();
                                    currentCell.append(data);
                                }
                                else if (dataType === "text") {

                                    currentCell.text(data);
                                }
                            }
                        } else {
                            //Display the error icon.
                            var errorDomObject = $("<span class='glyphicon glyphicon-question-sign'></span>");
                            currentCell.empty();
                            currentCell.addClass("text-center").append(errorDomObject);
                        }
                    };

                    if (_.isUndefined(tableView.tableDelegate.dataForCellAsync)) {
                        //Fallback to deprecated method for legacy code.
                        tableView.tableDelegate.dataForCell(tableView, i, ii, callbackFn);
                    } else {
                        var promise = tableView.tableDelegate.dataForCellAsync(tableView, i, ii);
            
                        promise.then(([data, dataType, clickable, styles]) => {
                            callbackFn(data, dataType, clickable, styles);
                        }).catch((error) => {
                            console.log(`Error in dataForCellAsync in delegate '${tableView.tableDelegate}': ${error} ${error.stack}`);
                            callbackFn(null);
                        });
                    }
                }());
            }
        }
    }

    /* Clears all the rows inserted in the dom, and inserts numberOfRows new rows with a placeholder to be replaced by
     * the actual data. It also applies any styling to the rows and their cells that may be necessary given the
     * receiver's settings. Furthermore, each cell inserted into the table dom object by this method has two html 5
     * attributes: data-eds-row and data-eds-column, which can be useful for looking up cells quickly when inserting
     * or when a click event takes place. */
    _resetTableBody(numberOfRows) {

        const checkable = _.isFunction(this.tableDelegate.checkStateForRowAsync);

        var tableBodyDomObject = this._tableDomObject.find("tbody");

        //First, empty the table body.
        tableBodyDomObject.empty();

        //Now add all the rows, with no content.
        var emptyRowDomObject = $("<tr></tr>");

        //Add the required classes if necessary.
        if (this.allowsInteractiveRows())
            emptyRowDomObject.addClass("edsClickableRow");

        var emptyCellDomObject = $("<td class='text-center edsAppGrayColor'><span class='glyphicon glyphicon-time'></span></td>");
        var emptyCheckbox = $("<td class='text-center edsAppGrayColor' data-eds-checkbox><input type='checkbox' /></td>");
        // emptyCheckbox = emptyCheckbox.prop('data-eds-checkbox', true);

        for (var ii = 0; ii < numberOfRows; ii++) {

            var emptyRowDomObjectClone = emptyRowDomObject.clone().attr('data-eds-row', ii);

            if (checkable) {
                emptyRowDomObjectClone.append(emptyCheckbox.clone().attr('data-eds-row', ii));
            }

            for (var i = 0; i < this._columnNames.length; i++) {
                emptyRowDomObjectClone.append(emptyCellDomObject.clone().attr('data-eds-row', ii).attr('data-eds-column', i));
            }

            tableBodyDomObject.append(emptyRowDomObjectClone);
        }
    }

    /* Returns the last page available to display given the receiver's current _numberOfRows and its _rowsPerPage
     property.
     */
    _lastPage() {

        return this._numberOfRows === 0 ? 0 : Math.floor((this._numberOfRows - 1) / this._rowsPerPage);
    }

    /* Adjusts the enabled or disabled state of the previous and next buttons of the pager depending on the state
     of the table. If the table view's _numberOfRows fits in a single page, this method hides the pager.
     */
    _adjustPager() {

        this._pagerDomObject.find("li").removeClass("disabled");

        if (this._lastPage() === 0) {

            this._pagerDomObject.hide();
        }
        else {

            this._pagerDomObject.show();

            if (this._currentPage === 0) {
                this._pagerDomObject.find("li.previous").addClass("disabled");
            } else if (this._currentPage === this._lastPage()) {
                this._pagerDomObject.find("li.next").addClass("disabled");
            }
        }
    }

    /* Hides any open popovers attached to table cells (td elements) and resets the popover coordinates */
    _destroyPopovers() {

        if (_.isFunction(this.tableDelegate.popoverDataForCell) || _.isFunction(this.tableDelegate.popoverDataForRow) || _.isFunction(this.tableDelegate.popoverDataForCellAsync) || _.isFunction(this.tableDelegate.popoverDataForRowAsync)) {
            this._currentPopoverRow = -1;
            this._currentPopoverColumn = -1;
            this._tableDomObject.find('td').popover("destroy");
        }
    }


    /* This method asks the delegate for all the data for the given page number by calling _requestDataForRowRange.
     *  It first resets the rows on the table by calling _resetTableBody. */
    loadPage(pageNumber) {

        //First we make sure the pageNumber is valid.
        if (pageNumber < 0 || pageNumber > this._lastPage())
            return;

        this._currentPage = pageNumber;

        //We reset the contents of the dom.
        var numberOfRowsInCurrentPage = this._numberOfRows - (this._currentPage * this._rowsPerPage);
        if (numberOfRowsInCurrentPage > this._rowsPerPage)
            numberOfRowsInCurrentPage = this._rowsPerPage;

        this._resetTableBody(numberOfRowsInCurrentPage);

        //We request the delegate the data for the currentPage.
        var minRow = this._currentPage * this._rowsPerPage;
        this._requestDataForRowRange(minRow, minRow + numberOfRowsInCurrentPage);

        //Adjust the pager accordingly.
        this._adjustPager();
    }

    /* Reloads the entire table. This method causes the table to repeatedly query the delegate for the required table
     data. The table will try to preserve the page the user was on, unless the page is invalid (the table will then
     default to showing the first page). Internally, this method calls loadPage. If any popovers are visible, they will
     be hidden.
     */
    reload(newNumberOfRows) {

        this._reloadCounter++;
        this._numberOfRows = newNumberOfRows;

        //We see if we can still load the current page.
        if (this._currentPage > this._lastPage())
            this._currentPage = 0;

        //Hide any popovers.
        this._destroyPopovers();

        //Update the pager accordingly.
        this._adjustPager();

        //We load the page.
        this.loadPage(this._currentPage);
    }

    /* Check all rows on the current page and call delegate method to update state.
    */
    checkAllRowsOnPage() {
        if (!_.isFunction(this.tableDelegate.checkStateForRowAsync)) {
            return;
        }

        const tableView = this;

        _.each(this._tableDomObject.find("td[data-eds-checkbox]"), function(cell) {

            var checkbox = cell.querySelector("input:first-of-type");

            if (checkbox.disabled) return;

            if (_.isFunction(tableView.tableDelegate.checkStateChangedForRow)) {
                var rowNumber = parseInt(cell.getAttribute("data-eds-row")) + tableView._currentPage * tableView._rowsPerPage;
                tableView.tableDelegate.checkStateChangedForRow(tableView, rowNumber, true);
            }

            checkbox.checked = true;
        });
    }

    /* Uncheck all rows on the current page and call delegate method to update state.
    */
    uncheckAllRowsOnPage() {
        if (!_.isFunction(this.tableDelegate.checkStateForRowAsync)) {
            return;
        }

        const tableView = this;

        this._tableDomObject.find("td[data-eds-checkbox]").each(function(i, cell) {

            if (_.isFunction(tableView.tableDelegate.checkStateChangedForRow)) {
                var rowNumber = parseInt(cell.getAttribute("data-eds-row")) + tableView._currentPage * tableView._rowsPerPage;
                tableView.tableDelegate.checkStateChangedForRow(tableView, rowNumber, false);
            }

            var checkbox = cell.querySelector("input:first-of-type");
            checkbox.checked = false;
        });
    }
};