edsApp.classes.controllers.custom.UserDetailController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "user_detail";
        this.requiresLogin = true;
        this.formValidator = null;
        this.media = null;
        this.helpLink = "personal_info";
        this.iti = null; // intl-tel-input
    }

    //Define Functions.
    setViewHTML(html) {

        var userDetailController = this;

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        this._view.getChildView("phone").popover();
        this.formValidator = new edsApp.classes.utilities.FormValidator(this._view.getChildView("form"), this, true, false);

        //Load all languages and countries to the select form control
        var selectDomObject = this._view.getChildView("language");
        edsApp.utilities.appendSortedLanguageIdsToSelectDomObject(selectDomObject);
        var countrySelectDomObject = this._view.getChildView("country");
        edsApp.utilities.appendSortedCountryIdsToSelectDomObject(countrySelectDomObject);

        //Hookup view events to their respective "listeners" or "targets".
        this._view.on("click", "input[name='user_detail_picture_type']", function (e) {

            var clickedRadio = $(this).val();
            var newURL;

            if (clickedRadio == "gravatar") {
                userDetailController._view.getChildView("upload_container").slideUp();
                newURL = "https://www.gravatar.com/avatar/" + $.md5(edsApp.model.custom.user.emails[0]) + "?d=retro";
                userDetailController._view.getChildView("picture").attr("src", newURL);
                userDetailController.media = {gravatar: true};
            } else if (clickedRadio == "upload") {
                userDetailController._view.getChildView("upload_container").slideDown();
                userDetailController._view.getChildView("upload").replaceWith($(userDetailController._view.getChildView("upload")).clone(true));
                userDetailController.media = {};
            } else if (_.contains(edsApp.model.custom.foreignProviderNames, clickedRadio)) {
                userDetailController._view.getChildView("upload_container").slideUp();
                var clickedDomain = $(this).data("mca-domain");

                edsApp.utilities.custom.loginWithForeignProvider(clickedRadio, undefined, clickedDomain, function (success, providerName, providerToken, providerId, providerEmail, userName, userImageURL) {

                    if (success && userImageURL) {
                        userDetailController.media = {providerName: providerName, providerId: providerId, providerDomain: clickedDomain ? clickedDomain.domain : null, providerToken: providerToken};
                    } 

                    newURL = "/media/silhouette.png"; //We can't show the actual image yet because it is blocked by CSP.
                    userDetailController._view.getChildView("picture").attr("src", newURL);
                });

            } else {
                userDetailController._view.getChildView("upload_container").slideUp();
                newURL = "/media/silhouette.png";
                userDetailController._view.getChildView("picture").attr("src", newURL);
                userDetailController.media = null;
            }
        });

        this._view.getChildView("upload").on("change", function (e) {

            var file = this.files[0];

            if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|bmp|tif|tiff)$/)) {
                
                userDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("image_format_not_supported"));
                $(this).replaceWith($(this).clone(true));
                userDetailController._view.getChildView("alert").slideDown();
                userDetailController._view.getChildView("save").show();
                userDetailController.media = {};

            } else {

                userDetailController._view.getChildView("alert").slideUp();
                var imgElement = new Image();
                imgElement.src = window.URL.createObjectURL(file);

                imgElement.onload = function () {

                    var data = edsApp.utilities.custom.compressImageToDataURL(imgElement, imgElement.width, imgElement.height, 128, 128, "image/jpeg");
                    userDetailController.media = {data: data.substr(data.indexOf(",") + 1), //Take out the image/jpeg;base64, part of the data uri.
                                                  mimeType: "image/jpeg"};

                    userDetailController._view.getChildView("picture").attr("alt", file.name);
                    userDetailController._view.getChildView("picture").attr("src", data);
                };
            }
        });


        this._view.getChildView("form").on("submit", function (e) {

            if (!userDetailController.formValidator.getFormStatus())
                return;

            userDetailController._view.getChildView("save").hide();
            userDetailController._view.getChildView("spinner").show();

            var putArgs = {name: userDetailController._view.getChildView("name").val(),
                           phone: userDetailController.iti.getNumber(),
                           phoneCountryId: userDetailController.iti.getSelectedCountryData().iso2,
                           languageId: userDetailController._view.getChildView("language").val(),
                           countryId: userDetailController._view.getChildView("country").val(),
                           media: userDetailController.media};

            edsApp.model.putDataToURL("user", putArgs, function (success, data) {

                if (success) {

                    edsApp.model.custom.user = data;

                    //We delay the refresh so that the media can be cached in the datastore before it's queried.
                    edsApp.utilities.invokeFunctionWithDelay(1500, function () {
                        edsApp.controllers.app.refreshNavigationBar();
                    });

                    edsApp.model.clearJSONDataCacheForURLBeginningWith("members");
                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("user_detail_success")});

                } else {

                    userDetailController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    userDetailController._view.getChildView("alert").slideDown();
                    userDetailController._view.getChildView("save").show();
                    userDetailController._view.getChildView("spinner").hide();
                }
            });
        });
    };

    willBecomeKeyController(a) {

        var userDetailController = this;

        //Call the superclass.
        super.willBecomeKeyController(a);

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("save").show();

        // phone
        this._view.getChildView("phone").val(edsApp.model.custom.user.phoneNumber);
        var tel = this._view.getChildView("phone").get(0);
        this.iti = window.intlTelInput(tel, {
            utilsScript: "/js/iti-utils.js",
            initialCountry: this.getValidCountryIdForTelInput(edsApp.model.custom.user.countryId)
        });

        //Set the default language and country.
        this._view.getChildView("language").find("option").prop("selected", false);
        this._view.getChildView("country").find("option").prop("selected", false);
        edsApp.utilities.appendLanguageIdToSelectDomObject(this._view.getChildView("language"), edsApp.model.custom.user.languageId);
        this._view.getChildView("country").find("option[value='" + edsApp.model.custom.user.countryId + "']").prop("selected", true);

        //Reset the profile picture section.
        this.media = {};
        this._view.getChildView("upload").replaceWith($(this._view.getChildView("upload")).clone(true));
        this._view.getChildView("gravatar_label").text(edsApp.model.getLocalizedString("use_gravatar", {email: edsApp.model.custom.user.emails[0]}));
        this._view.getChildView("upload_container").hide();
        this._view.getChildView("gravatar_radio").prop("disabled", true);

        //Pre-load the md5 hashing library 'cause we'll need it for the gravatar section.
        edsApp.model.getScriptForURL("/js/jquery.md5.js", {}, function (success) {

            if (success)
                userDetailController._view.getChildView("gravatar_radio").prop("disabled", false);
        });

        var providerDomainsDict = {};

        _.each(edsApp.model.custom.user.foreignAccounts, function (foreignAcct) {

            providerDomainsDict[foreignAcct.providerName] = foreignAcct.domain;
        });

        //Remove all radio buttons for foreign providers.
        userDetailController._view.getChildView("foreign_accounts_container").empty();

        //Add an option for each foreign provider.
        _.each(providerDomainsDict, function(domain, providerName) {

            var radioDomObject = $('<div class="radio">' +
                '<label>' +
                    '<input data-eds-view="' + providerName + '_radio" type="radio" name="user_detail_picture_type" value="' + providerName + '">' +
                    _.escape(edsApp.model.getLocalizedString("foreign_account_picture", {provider: edsApp.model.getLocalizedString(providerName)})) +
                '</label>' +
            '</div>');

            radioDomObject.data("mca-domain", domain);
            userDetailController._view.getChildView("foreign_accounts_container").append(radioDomObject);
        });

        this._view.find("input[name='user_detail_picture_type']").prop("checked", false);

        var pictureURL = edsApp.model.custom.user.pictureURL;

        if (!pictureURL) {
            pictureURL = "/media/silhouette.png";

            this._view.getChildView("none_radio").prop("checked", true);
        } else if (pictureURL.indexOf("://www.gravatar.com/") !== -1) {
            this._view.getChildView("gravatar_radio").prop("checked", true);
        } else if (pictureURL.indexOf(window.location.origin) == 0) {
            this._view.getChildView("upload_radio").prop("checked", true);
            this._view.getChildView("upload_container").show();
        }

        this._view.getChildView("picture").attr("src", pictureURL);

        //Reset the rest of the form.
        this._view.getChildView("name").val(edsApp.model.custom.user.name);
        this._view.getChildView("form").find(".help-block").hide();
        this._view.getChildView("form").find("input").removeClass("mcaRedStripesBackgroundColor");
        this._view.getChildView("save").prop("disabled", !this.formValidator.getFormStatus());
    };

    willResignKeyController() {

        this.iti.destroy();
        this.iti = null;

        super.willResignKeyController();
    };

    getValidCountryIdForTelInput(countryId) {
        var countries = window.intlTelInputGlobals.getCountryData().map(x => x.iso2);
        return countries.includes(countryId) ? countryId : "us";
    }

    //Form validator delegate methods.
    contentDidChangeForInput(formValidator, input, errorMessage) {

        var helpBlock = this._view.getChildView("form").find("[data-eds-target='" + input.attr("data-eds-view") + "']");

        input.removeClass("mcaRedStripesBackgroundColor");

        if (errorMessage) {
            helpBlock.text(errorMessage).slideDown();
            input.addClass("mcaRedStripesBackgroundColor");
        } else if (input.is(":visible")) {
            helpBlock.slideUp();
        }

        this._view.getChildView("save").prop("disabled", !formValidator.getFormStatus());
    }
};