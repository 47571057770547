edsApp.classes.controllers.custom.LoginSettingsController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "login_settings";
        this.requiresLogin = true;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        if (a.successMessage) {
            this._view.getChildView("success_alert_message").text(a.successMessage);
            this._view.getChildView("success_alert").show();
        } else {
            this._view.getChildView("success_alert").hide();
        }
    };
};
