edsApp.classes.controllers.custom.DeleteGroupController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */
        //Define members.
        this.name = "delete_group";
        this.performingRequest = false;
        this.requiresLogin = true;
        this.group = null;
        this.helpLink = "groups";
        this.consentList = [];
    }

    //Define Functions.
    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        var deleteGroupController = this;
        this._view.getChildView("form").on("submit", function (e) {
            if (deleteGroupController.performingRequest)
                return;

            deleteGroupController.performingRequest = true;
            deleteGroupController._view.getChildView("delete").hide();
            deleteGroupController._view.getChildView("spinner").show();

            edsApp.model.deleteDataAtURL("groups", {group_id: deleteGroupController.group.id}, function (success, data) {

                if (success) {

                    edsApp.model.custom.user.ownerGroups = _.reject(edsApp.model.custom.user.ownerGroups, function (aGroup) { return aGroup.id == deleteGroupController.group.id; });
                    edsApp.controllers.app.pushController(edsApp.controllers.home, true, {successMessage: edsApp.model.getLocalizedString("delete_group_success", {name: deleteGroupController.group.name})});

                } else {

                    deleteGroupController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(data));
                    deleteGroupController._view.getChildView("delete").show();
                    deleteGroupController._view.getChildView("spinner").hide();
                }

                deleteGroupController.performingRequest = false;
            });
        });

        this._view.getChildView("next").on("click", function (e) {

            e.preventDefault();

            deleteGroupController.consentList.shift();

            if (deleteGroupController.consentList.length <= 1) {
                deleteGroupController._view.getChildView("delete").show();
                deleteGroupController._view.getChildView("next").hide();
            }

            deleteGroupController.loadConsentItem(deleteGroupController.consentList[0])
        });

        this._view.getChildView("checkbox").on("click", function (e) {

            if (deleteGroupController.consentList.length > 1) {
                deleteGroupController._view.getChildView("next").prop("disabled", !$(this).is(":checked"));
            } else {
                deleteGroupController._view.getChildView("delete").prop("disabled", !$(this).is(":checked"));
            }
        });
    }

    loadConsentItem(item) {

        this._view.getChildView("checkbox").prop("checked", false);
        this._view.getChildView("message").text(item.text);
        this._view.getChildView("next").prop("disabled", true);

        if (item.button && item.link) {

            this._view.getChildView("action").attr("href", item.link).text(item.button).attr("data-eds-link", item.local_link);
        } else {
            this._view.getChildView("action").hide();
        }
    };

    canBecomeKeyController(a) {
        //Note we are overriding the superclass implementation.
        return (_.has(a, "group_id"));
    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);

        this._view.getChildView("spinner").hide();

        var deleteGroupController = this;

        //Find the group with the given group id.
        var user = edsApp.model.custom.user;
        var allGroups = user.memberGroups.concat(user.adminGroups).concat(user.ownerGroups);
        var group = _.findWhere(allGroups, {id: a.group_id});

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("delete").prop("disabled", true).hide();
        this._view.getChildView("next").prop("disabled", true).show();

        if (group) {
            deleteGroupController.group = group;

            //Reset the consent list.
            this.consentList = [];

            this.consentList.push({
                text: edsApp.model.getLocalizedString("delete_group_licenses_warning", {name: group.name}),
                button: edsApp.model.getLocalizedString("view_licenses"),
                link: "https://www.rhino3d.com/licenses?_forceEmpty=true",
                local_link: false});

            this.consentList.push({
                text: edsApp.model.getLocalizedString("delete_group_services_warning", {name: group.name})});

            this.consentList.push({
                text: edsApp.model.getLocalizedString("delete_group_permanent_warning", {name: group.name})});

            this.loadConsentItem(this.consentList[0]);

        } else {
            deleteGroupController._view.getChildView("alert_message").text(edsApp.model.getLocalizedString("error_security"));
            deleteGroupController._view.getChildView("alert").slideDown();
        }
    };
};
