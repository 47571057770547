edsApp.classes.controllers.custom.MemberInviteConfirmController = class extends edsApp.classes.controllers.ViewController
{
    constructor() {
        super();

        /* Do setup specific to this class. */

        //Define members.
        this.name = "member_invite_confirm";
        this.requiresLogin = true;
        this.token = null;
    }

    setViewHTML(html) {

        //Provide a localized version for all static elements of the view.
        var values = edsApp.model.localizedStrings;

        var template = _.template(html);
        var resultingHTML = template(values);

        //Call the superclass.
        super.setViewHTML(resultingHTML);

        //Hookup view events to their respective "listeners" or "targets".
        this._view.getChildView("switch_accounts").on("click", function (e) {

            e.preventDefault();
            edsApp.controllers.app.switchAccounts();
        });

        var memberInviteConfirmController = this;

        this._view.getChildView("join").on("click", function (e) {

            e.preventDefault();
            memberInviteConfirmController._view.getChildView("spinner").show();
            memberInviteConfirmController._view.getChildView("alert").slideUp();
            memberInviteConfirmController._view.getChildView("join_decline_container").hide();

            edsApp.model.postDataToURL("invite", {token: memberInviteConfirmController.token}, function (success, group) {

                //We need to find out what the new group we joined is.
                if (success) {

                    if (!_.findWhere(edsApp.model.custom.user.memberGroups, {id: group.id}))
                        edsApp.model.custom.user.memberGroups.push(group);

                    edsApp.controllers.app.pushController(edsApp.controllers.member_invite_successful, true, {name: group.name, group_id: group.id});

                } else {

                    memberInviteConfirmController._view.getChildView("alert_message").text(edsApp.model.getLocalizedError(group));
                    memberInviteConfirmController._view.getChildView("alert").slideDown();
                }

                memberInviteConfirmController._view.getChildView("spinner").hide();
                memberInviteConfirmController._view.getChildView("join_decline_container").show();
            });
        });

    };

    willBecomeKeyController(a) {

        //Call the superclass.
        super.willBecomeKeyController(a);
        this.token = a.token;
        var user = edsApp.model.custom.user;

        this._view.getChildView("alert").hide();
        this._view.getChildView("spinner").hide();
        this._view.getChildView("switch_accounts").text(edsApp.model.getLocalizedString("switch_accounts_suggestion", {
            name: user.name
        }));


        this._view.getChildView("subtitle").text(edsApp.model.getLocalizedString("member_invite_confirm_message", {
            name: user.name,
            email: user.emails[0],
            group: a.name
        }));
    };
};