edsApp.classes.controllers.custom.AppController = class extends edsApp.classes.controllers.AppController
{
    constructor(containerView) {
        /* Do setup specific to this class. */
        super(containerView);

        /* Do setup specific to this class. */
        var appController = this;

        //Declare instance variables.
        this._navigationBar = $("#navigation_bar");
        this._navigationBarRight = this._navigationBar.getChildView("right");
        this._navigationBarLeft = this._navigationBar.getChildView("left");

        this._userDropDown = $('<li data-eds-view="user_dropdown" class="dropdown edsAppHorizontalSpaced">' +
                                    '<a href="#" class="dropdown-toggle mcaDropdown" data-toggle="dropdown"><span data-eds-view="user_name"></span><img data-eds-view="user_picture" class="pull-right hidden-xs"></a>' +
                                    '<ul class="dropdown-menu" role="menu">' +
                                        '<li><a data-eds-view="home" data-mca-target="home" href="#">' + _.escape(edsApp.model.getLocalizedString("home")) + '</a></li>' +
                                        '<li><a data-eds-view="logout" href="#">' + _.escape(edsApp.model.getLocalizedString("logout")) + '</a></li>' +
                                        '<li class="divider"></li>' +
                                        '<li><a data-eds-view="switch_accounts" href="#">' + _.escape(edsApp.model.getLocalizedString("switch_accounts")) + '</a></li>' +
                                    '</ul>' +
                                '</li>');

        this._loginButton = $('<li data-eds-view="login" data-mca-target="home">' +
                                '<button type="button" class="btn btn-link navbar-btn edsAppHorizontalSpaced">' + _.escape(edsApp.model.getLocalizedString("home")) + '</button>' +
                              '</li>');

        this._helpButton = $('<li><a href="/help" target="_blank" id="help"><span class="label small label-mcaLightGray">' + _.escape(edsApp.model.getLocalizedString("help")) + ' <span class="glyphicon glyphicon-plus text-danger"></span></span></a> </li>');
        $("#bottom_help").text(edsApp.model.getLocalizedString("help"));
        this._help = this._helpButton.find("#help").add($("#bottom_help")[0]);

        //Display the privacy policy.
        $("#privacy_policy").text(edsApp.model.getLocalizedString("privacy_policy"));

        // reCAPTCHA
        //Pre-load the script. Note that we do not await.
        edsApp.model.fetchScriptAsync("https://www.recaptcha.net/recaptcha/enterprise.js", {render: edsApp.model.custom.recaptchaSiteKey});

        //Perform Initialization.
        this.refreshNavigationBar();

        /* Here we listen for any clicks on the navigation bar. If the click happens to be a controller, we push it
         on to the stack.
         */
        this._navigationBar.on("click", function(e) {

            var targetController = $(e.target).closest("[data-mca-target]").attr("data-mca-target"); //Find the closest element with an mca target.

            if (_.has(edsApp.controllers, targetController)) {
                e.preventDefault();
                $(".navbar-collapse.in").collapse("hide"); //Collapse the nav bar on mobile devices.
                appController.pushController(edsApp.controllers[targetController], true);
            }
        });

        /* Here we logout the user when the logout button is clicked. */
        this._userDropDown.getChildView("logout").on("click", function(e) {

            e.preventDefault();
            edsApp.model.postDataToURL("logout", {}, function (success, data) {
                window.location.reload(true);
            });
        });

        /* Here we logout the user when the logout button is clicked. */
        this._userDropDown.getChildView("switch_accounts").on("click", function(e) {

            e.preventDefault();

            appController.switchAccounts();
        });
    }

    //Declare methods.

    /* Override the title method from the superclass */
    getTitleForController(controller) {

        var titlePrefix = "Rhino Accounts – ";
        return titlePrefix + edsApp.model.getLocalizedString(controller.name);
    };

    /* Override the pushController method. */
    pushController(newController, recordHistory, argumentsForNewController) {

        argumentsForNewController = argumentsForNewController || {};

        //We see whether a controller requiresLogin or not.
        if (newController.requiresLogin && !edsApp.model.custom.user) {

            //Redirect to login controller.
            var loginArguments = {redirectControllerName: newController.name,
                                  redirectControllerArgs: JSON.stringify(argumentsForNewController)
                                 };

            if (recordHistory)
                loginArguments.redirectRecordHistory = true;

            //Call the superclass. We make sure the login controller's view is loaded first.
            var pushLoginController = () => {
                if (edsApp.controllers.login._view)
                    super.pushController(edsApp.controllers.login, false, loginArguments);
                else
                    edsApp.utilities.invokeFunctionWithDelay(1000, pushLoginController);
            };

            pushLoginController();

        } else {
            //Call the superclass.
            super.pushController(newController, recordHistory, argumentsForNewController);

            if (newController.helpLink) {
                this._help.attr("href", "/help#" + newController.helpLink);
            } else {
                this._help.attr("href", "/help");
            }
        }
    };

    /* Allows user to switch accounts on the fly */
    switchAccounts() {
        if (this._keyController && this._keyController.name != edsApp.controllers.login.name && edsApp.controllers.login._view) {

                var allURLParameters = edsApp.utilities.getAllURLParameters();

                //Push the controller, with the arguments specified in the query string (minus the controller argument).
                if (_.has(allURLParameters, "controller"))
                    delete allURLParameters["controller"];

                var loginArguments = {
                    redirectControllerName: this._keyController.name,
                    redirectControllerArgs: JSON.stringify(allURLParameters)
                };

                this.pushController(edsApp.controllers.login, false, loginArguments);
            }
    }

    /* Refreshes the Navigation Bar with the latest content. */
    refreshNavigationBar() {

        var appController = this;

        /* We either display the sign in button or the user dropdown depending on the login status. */
        edsApp.utilities.fadeTransition(this._navigationBarRight, function () {

            if (appController._navigationBarLeft.find("#help").length == 0)
                    appController._navigationBarLeft.append(appController._helpButton);

            if (edsApp.model.custom.user) {

                appController._userDropDown.getChildView("user_name").text(edsApp.model.custom.user.name);

                if (appController._navigationBarRight.getChildView("user_dropdown").length == 0)
                    appController._navigationBarRight.append(appController._userDropDown);

                if (edsApp.model.custom.user.pictureURL) {
                    appController._navigationBar.getChildView("user_picture").attr("src", edsApp.model.custom.user.pictureURL);
                    appController._navigationBar.getChildView("user_picture").show();
                } else {
                    appController._navigationBar.getChildView("user_picture").hide();
                }

                appController._userDropDown.show();
                appController._loginButton.hide();

            } else {

                if (appController._navigationBarRight.getChildView("login").length == 0)
                    appController._navigationBarRight.append(appController._loginButton);

                appController._userDropDown.hide();
                appController._loginButton.show();
                appController._navigationBar.getChildView("user_picture").hide();
            }
        });
    };
};